import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { GtmData, GtmEvent } from '@common/util-base';

@Component({
  selector: 'randc-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss'],
})
export class FaqItemComponent {
  @Input() public question!: string;
  @Input() public answer?: string = '';
  @Input() public useTemplateRef?: boolean = false;
  @Input() public anchorScroll?: boolean = false;
  @Input() public openAnswer?: boolean = false;
  @Input() templateRef!: TemplateRef<any>;
  @Input() public refreshTrigger?: string = '';
  @Output() handleAnalytics: EventEmitter<GtmData> = new EventEmitter();

  showAnswer(question: string, isOpen: boolean) {
    if (isOpen) this._triggerInitGA(question);
  }

  private _triggerInitGA(question: any): void {
    this.handleAnalytics.emit({
      event: GtmEvent.GENERIC,
      eventCategory: 'FAQs',
      eventAction: question,
      eventLabel: 'home',
    });
  }

  public triggerExternalLinkGA(externalLink: any) {
    this.handleAnalytics.emit({
      event: GtmEvent.GENERIC,
      eventCategory: 'external-link-click',
      eventAction: 'help centre',
      eventLabel: externalLink,
    });
  }
}
