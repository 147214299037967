<div
  *ngIf="group"
  [formGroup]="group"
  class="form-input input-autocomplete"
  [ngClass]="{
    'is-focused': isFocused,
    'is-invalid':
      group &&
      !group.controls[item.control]?.valid &&
      !isFocused &&
      group.controls[item.control]?.touched,
    'is-valid':
      group && group.controls[item.control]?.valid && group.controls[item.control]?.touched
  }"
>
  <randc-label
    *ngIf="label && !tooltip"
    [label]="label"
    [class]="label.classes"
    [forElement]="labelFor"
    >{{ label.text }}
  </randc-label>

  <label *ngIf="label && tooltip" [class]="label.classes" [attr.for]="labelFor"
    ><span class="dg-hint">{{ label.text }}</span>
    <div class="tooltip-main" (click)="toggleTooltip()">
      <div class="tooltip tooltip-autocomplete"></div>
    </div>
    <div *ngIf="showTooltip" [class]="tooltip.classes" [innerHtml]="tooltip.text"></div>
  </label>

  <randc-hint *ngIf="hint" [hint]="hint"></randc-hint>
  <!-- documentation for p-atuComplete is here - https://primefaces.org/primeng/#/autocomplete -->
  <div class="form-control brands-autocomplete-wrapper" [ngClass]="{ isloading: isloading }">
    <p-autoComplete
      [id]="item.control"
      [class]="item.classes"
      formControlName="{{ item.control }}"
      placeholder="{{ item.placeholder }}"
      field="{{ filterField }}"
      emptyMessage="No match found"
      (completeMethod)="filterItems($event)"
      [suggestions]="filteredItems"
      [dropdown]="false"
      [minLength]="minLength"
      [size]="30"
      (onKeyUp)="keyUp($event)"
      (onBlur)="handleOnBlur()"
      (onFocus)="handleOnFocus()"
      (onSelect)="selectItem($event)"
      [showTransitionOptions]="'10ms'"
      [hideTransitionOptions]="'10ms'"
      [forceSelection]="true"
      ngDefaultControl
    >
      <ng-template let-brand pTemplate="item">
        <div class="ui-helper-clearfix">
          <div class="autocomplete-option">
            <span class="pre-match">{{ getPreMatch(brand) }}</span>
            <strong>{{ getMatch(brand) }}</strong>
            <span class="post-match">{{ getPostMatch(brand) }}</span>
          </div>
        </div>
      </ng-template>
    </p-autoComplete>

    <i class="icon icon-tick-circle"></i>
    <i class="icon icon-error-circle"></i>
    <div class="loader__wrapper">
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle class="spinner-bg" cx="50" cy="50" r="45" />
        <circle class="spinner" cx="50" cy="50" r="45" />
      </svg>
    </div>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <randc-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="item.control"
    >
    </randc-validation-error-formcontrol>
  </ng-container>
</div>
