import { Injectable } from '@angular/core';
import { CalendarDay } from '@common/util-base';
import {
  isSameDay,
  isAfter,
  startOfWeek,
  isMonday,
  getMonth,
  eachDayOfInterval,
  subDays,
  differenceInCalendarMonths,
} from 'date-fns';

import { camelCase } from 'lodash';

@Injectable()
export class CalendarHelperService {
  public monthsInYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  /**
   * convertToCamelCase
   * @param obj
   * @returns obj
   * converts properties to camelCase
   */
  public convertToCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.convertToCamelCase(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.convertToCamelCase(obj[key]),
        }),
        {}
      );
    }
    return obj;
  }

  /**
   *
   * @param appointments {CalendarDay}
   * @param newDate {Date}
   * @return UICalendarDays || APICalendarDays
   * This is a temp fix and can be removed once the CR is complete
   * Since the API only returns available days we need to fill in the unavaible ones
   * The below returns a new calendarDays object placing a state of 'slotsAvaibale' : true on matches dates returned in the API
   * This boolean value is then used for the UI to show selectable days
   *
   * 1. Get a reference to the last item in the API responses. (x)
   * 2. Set up array and dateObject - reset hrs to midnight (we need to get yesterdays date for this to work)
   * 3. Build an array day objects with (x) number of items from todays date to the last date (referenceDate). Setting a date object on each item and slotsAvailable: false
   * 4. Loop through the API response and compare the date with the current indexed date. If they match set slotsAvailable to true
   * 5. Now we've built our UI array of dates and can use slotsAvailable to display to the user which days are available
   */
  public buildCalendarUI(
    appointments: CalendarDay[],
    newDate: Date = new Date(),
    endDate: Date | null = null
  ): CalendarDay[] {
    const dateEnd = this.getLastDay(appointments, endDate);
    let _newDate: Date;
    const UICalendarDays: Array<CalendarDay> = [];
    _newDate = new Date(newDate.setDate(newDate.getDate() - 1));
    _newDate.setHours(0, 0, 0, 0);

    while (!isSameDay(_newDate, dateEnd)) {
      UICalendarDays.push({
        date: _newDate,
        slotsAvailable: false,
        selected: false,
      });

      _newDate = new Date(_newDate.setDate(_newDate.getDate() + 1));
    }

    for (let i = 0; i < UICalendarDays.length; i++) {
      appointments.forEach((day) => {
        if (isSameDay(new Date(day.date), UICalendarDays[i].date)) {
          UICalendarDays[i].slotsAvailable = true;
          UICalendarDays[i].slots = day.slots;
          UICalendarDays[i].selected = false;
        }
      });
    }

    const daysGoneBy: CalendarDay[] = this.getCalendarDaysGoneBy(appointments, new Date());

    const days = [...daysGoneBy, ...UICalendarDays];

    let daysLeftInWeek = 0;

    if (days.length < 7) {
      daysLeftInWeek = 7 - days.length;
    }

    for (let i = 0; i < daysLeftInWeek; i++) {
      days.push({} as CalendarDay);
    }

    return days;
  }

  /**
   *
   * @param APICalendarDays {CalendarDay}
   * @param endDate {Date}
   * @return Date
   * Returns latest date either from the calendar array or the endDate defined from the last api response
   */
  getLastDay(APICalendarDays: CalendarDay[], endDate: Date | null): Date {
    let lastDay = new Date();
    if (endDate != null) {
      lastDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    }

    if (
      APICalendarDays?.length > 0 &&
      isAfter(new Date(APICalendarDays[APICalendarDays.length - 1].date), lastDay)
    ) {
      lastDay = new Date(APICalendarDays[APICalendarDays.length - 1].date);
    }

    return lastDay;
  }

  public updateSelectedDay(
    days: CalendarDay[],
    value?: number | undefined
  ): { calendarDays: CalendarDay[]; selectedDateId: number } {
    const nextAvailableDay = days.find((day) => day.slotsAvailable) as CalendarDay;
    const newIndex = days.indexOf(nextAvailableDay);
    const _index = value ? value : newIndex;

    const calendarDays = days.map((day: CalendarDay, _id: number) => ({
      ...day,
      selected: _id === _index ? true : null,
    }));

    return {
      calendarDays,
      selectedDateId: _index,
    };
  }

  public setCalendarMonthInView(firstDay: Date, lastDay: Date): string {
    const currentMonthIndex = getMonth(firstDay);
    const nextMonthIndex = getMonth(lastDay);
    const difference = differenceInCalendarMonths(lastDay, firstDay);

    if (difference > 0) {
      return `${this.monthsInYear[currentMonthIndex]}/${this.monthsInYear[nextMonthIndex]}`;
    }

    return this.monthsInYear[currentMonthIndex];
  }

  public getCalendarDaysGoneBy(APICalendarDays: CalendarDay[], date: Date): CalendarDay[] {
    if (APICalendarDays && APICalendarDays.length && !isMonday(date)) {
      const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
      const daysGoneBy = eachDayOfInterval({
        start: firstDayOfWeek,
        end: subDays(date, 1),
      });

      return daysGoneBy.map((date) => ({
        date: date,
        slotsAvailable: false,
        selected: false,
        previousDay: true,
      }));
    } else {
      return [];
    }
  }
}
