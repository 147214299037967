<ui-panel class="order-confirmation__your-details" [variant]="'normal'">
  <ui-panel-header>
    <ui-panel-title [variant]="'normal'">Your details</ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <!-- Use customerDetails$ and loop values-->
    <ui-panel-display-info-row
      [direction]="'row'"
      [data]="{ key: 'Name', value: customerDetails?.firstName + ' ' + customerDetails?.surname }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'row'"
      [data]="{ key: 'Email', value: customerDetails?.email?.main }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'row'"
      [data]="{ key: 'Phone', value: customerDetails?.telephone?.mobile }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'row'"
      [data]="{ key: 'Address', value: address }"
    ></ui-panel-display-info-row>
  </ui-panel-body>
</ui-panel>
