import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PcaService {
  AppConfig = {
    postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
    postcodeServiceFind: 'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
    postcodeServiceRetrieve: 'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  };

  constructor(private http: HttpClient) {}

  /**
   * find - multiple address lookup requires id to be passed
   * @param input
   * @param id
   * @returns {Observable<any>}
   */
  public pcaFind(input: any, id: any = '') {
    // let event = {
    //   Id: 'GB|RM|ENG|4HP-RG21',
    //   Type: 'Postcode',
    //   Text: 'RG21 4HP',
    //   Highlight: '0-4,5-8',
    //   Description: 'Norn Hill, Basingstoke - 89 Addresses'
    // };
    return this.http
      .get<Response>(
        `${this.AppConfig.postcodeServiceFind}?key=${this.AppConfig.postcodeServiceKey}&Text=${input}&Container=${id}&Origin=&IsMiddleware=false&Countries=GB&Limit=&Language=`
      )
      .pipe(
        retry(2),
        tap((response: Response) => response),
        catchError((error: any) => throwError(error))
      );
  }

  /**
   * retrieve address
   * @param id
   * @returns {Observable<any>}
   */
  public pcaRetrieve(id: any): Observable<any> {
    return this.http
      .get<Response>(
        `${this.AppConfig.postcodeServiceRetrieve}?key=${this.AppConfig.postcodeServiceKey}&Id=${id}`
      )
      .pipe(
        retry(2),
        tap((response: Response) => response),
        catchError((error: any) => throwError(error))
      );
  }
}
