import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

@Pipe({ name: 'keepHtml' })
export class KeepHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: any) {
    const sanitizedContent = DOMPurify.sanitize(content);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
