<div class="hero-banner spacing-max-sm">
  <div class="hero-banner__image-container">
    <div class="hero-banner__image-wrap">
      <ui-picture
        [fill]="true"
        position="center right"
        [src]="data.heroImageDesktop['@link']"
      ></ui-picture>
    </div>
  </div>
  <div class="container">
    <div class="xs-row">
      <div class="hero-banner__content xs-12 lg-6 py-4">
        <div class="hero-banner__sub-title mb-2">
          <strong>{{ data.heroTitle }}</strong>
        </div>
        <h1 class="hero-banner__title mb-4">{{ data.heroDescription }}</h1>

        <div class="hero-banner__text mb-2" [innerHtml]="data.heroSubDescription">
          When the things that keep your home running smoothly break down, we’re here. Book
          <strong>online in minutes</strong>, and we’ll send over a
          <strong>certified local engineer</strong> to look after your repair
          <strong>as soon as tomorrow</strong>.
        </div>
        <ui-button
          class="hero-banner__cta mt-4"
          variant="primary-inverted"
          (click)="bookARepairClick.emit()"
          >{{ buttonText }}
        </ui-button>
      </div>
    </div>
  </div>
</div>
