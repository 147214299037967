import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AoFeatureShellModule } from '@ao/feature-shell';
import { CommonModule } from '@angular/common';
import { CommonFeatureAppModule } from '@common/feature-app';
import { ScullyLibModule } from '@scullyio/ng-lib';

@NgModule({
  declarations: [AppComponent],
  imports: [CommonModule, CommonFeatureAppModule, AoFeatureShellModule, ScullyLibModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
