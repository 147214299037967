<div class="container">
  <h2 class="xs-12 mb-2">{{ title }}</h2>
  <div class="xs-row number-row">
    <ng-container *ngFor="let ceItem of customerEngagementItems; index as i">
      <div class="number-separator number__index{{ i }}" *ngIf="i > 0"></div>
      <div class="number-container xs-6 lg-3 number__index{{ i }}">
        <h2 class="subtitle typog-d2 mb-1">{{ ceItem.title }}</h2>
        <div class="description" [innerHtml]="ceItem.description"></div>
      </div>
    </ng-container>
  </div>
</div>
