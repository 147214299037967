import { createReducer, on, Action } from '@ngrx/store';

import * as QuoteActions from './quote.actions';
import { QuoteDetails, QuoteState } from '../../model/quote-store.model';
import { RemoteDataState } from '@common/util-base';

export const QUOTE_FEATURE_KEY = 'quote';

export interface QuotePartialState {
  readonly [QUOTE_FEATURE_KEY]: QuoteState;
}

// Initial state
export const initialState: QuoteState = {
  details: {
    dynamicLandingPageDetails: {
      siteVersion: '',
      brandName: '',
      applianceName: '',
      locationName: '',
    },
    applianceDetails: {
      applianceTypeCode: '',
      applianceTypeName: '',
      applianceBrandCode: '',
      applianceBrandName: '',
    },
    postcode: '',
    outwardPostcode: '',
    repairDate: '',
    repairTime: '',
    selectedSlotId: '',
    calendarAvailability: {
      availabilityDates: [],
      availabilityStartDate: '',
      availabilityEndDate: '',
      scrollToken: '',
      blockAvailabilityDates: [],
    },
    sessionId: '',
    faultArrayDetails: {
      faultCategories: [],
      modelNumberRequired: false,
      status: '',
    },
    customer: {
      title: '',
      firstName: '',
      surname: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        postalCode: '',
        countryCode: '',
      },
      email: {
        main: '',
      },
      telephone: {
        mobile: '',
      },
      sendMethod: 'EMAIL',
      preferredContactMethod: 'EMAIL',
      marketingPreferences: { email: false, telephone: false, post: false },
    },
    preferredPaymentDay: 0,
    contract: {
      status: '',
      result: {
        completedItems: [
          {
            confirmation: {
              planNumber: '',
              renewalDate: '',
              breakdownStartDate: '',
            },
          },
        ],
        basket: {
          basketId: '',
          created: '',
          expiry: '',
          items: [],
          requestAction: '',
          requestSource: '',
          status: '',
          updated: '',
        },
      },
    },
  },
  basket: null,
  remoteState: {
    quote: RemoteDataState.NotFetched,
    faultArray: RemoteDataState.NotFetched,
    availabilityData: RemoteDataState.NotFetched,
  },

  error: {
    errorStatus: '',
    errorMessage: '',
  },
};

const resetRemoteStateIfNeeded = (state: RemoteDataState) => {
  if (state === RemoteDataState.OK) {
    return RemoteDataState.OK;
  }
  return RemoteDataState.NotFetched;
};

// Create Reducer
const quoteReducer = createReducer(
  initialState,
  on(QuoteActions.Init, (state) => {
    return {
      ...state,
      remoteState: {
        quote: resetRemoteStateIfNeeded(state.remoteState.quote),
        faultArray: resetRemoteStateIfNeeded(state.remoteState.faultArray),
        availabilityData: resetRemoteStateIfNeeded(state.remoteState.availabilityData),
      },
    };
  }),
  on(QuoteActions.AddDynamicLandingPageDetails, (state, { payload }) => {
    const { siteVersion, applianceName, brandName, locationName } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        dynamicLandingPageDetails: {
          ...state.details.dynamicLandingPageDetails,
          siteVersion,
          applianceName,
          brandName,
          locationName,
        },
      },
    };
  }),
  on(QuoteActions.GetEngineerAvailabilityDatesSuccess, (state, { payload }) => {
    const sessionId = payload && payload.sessionId ? payload.sessionId : '';

    return {
      ...state,
      details: {
        ...state.details,
        calendarAvailability: {
          ...payload,
          blockAvailabilityDates: payload.availabilityDates,
        },
        sessionId: sessionId,
      },
      remoteState: {
        ...state.remoteState,
        availabilityData: RemoteDataState.OK,
      },
    };
  }),
  on(QuoteActions.GetEngineerMoreAvailabilityDatesSuccess, (state, { payload }) => {
    const scrollToken = payload && payload.scrollToken ? payload.scrollToken : '';

    return {
      ...state,
      details: {
        ...state.details,
        calendarAvailability: {
          ...state.details.calendarAvailability,
          scrollToken: scrollToken,
          availabilityStartDate: payload.availabilityStartDate,
          availabilityEndDate: payload.availabilityEndDate,
          availabilityDates: [
            ...state.details.calendarAvailability.availabilityDates,
            ...payload.availabilityDates,
          ],
          blockAvailabilityDates: payload.availabilityDates,
        },
      },
      remoteState: {
        ...state.remoteState,
        availabilityData: RemoteDataState.OK,
      },
    };
  }),
  on(QuoteActions.AddAppointmentDate, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        repairDate: payload,
      },
    };
  }),
  on(QuoteActions.AddAppointmentDateTime, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        repairDate: payload.selectedDate,
        selectedSlotId: payload.selectedSlotId,
        repairTime: payload.selectedTime,
      },
    };
  }),
  on(QuoteActions.SaveLandingFormValues, (state, { payload }) => {
    const { applianceTypeCode, applianceBrandCode, postcode, outwardPostcode } = payload;
    const details = state.details;

    return {
      ...state,
      details: {
        ...state.details,
        postcode,
        outwardPostcode,
        applianceDetails: {
          ...state.details.applianceDetails,
          applianceTypeCode,
          applianceBrandCode,
        },
      },
      remoteState: {
        ...state.remoteState,
        quote: RemoteDataState.NotFetched,
      },
    };
  }),
  on(QuoteActions.GetQuoteSuccess, QuoteActions.GetIndicativeQuoteSuccess, (state, { payload }) => {
    return {
      ...state,

      basket: payload,
      remoteState: {
        ...state.remoteState,
        quote: RemoteDataState.OK,
      },
    };
  }),
  on(QuoteActions.GetFaultSuccess, (state, { payload }) => {
    return {
      ...state,

      details: {
        ...state.details,
        faultArrayDetails: payload,
      },
      remoteState: {
        ...state.remoteState,
        faultArray: RemoteDataState.OK,
      },
    };
  }),
  on(QuoteActions.CreateContract, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        repair: {
          fault: payload.createContractPayload.repair.fault,
          model: payload.createContractPayload.repair.model,
          access: payload.createContractPayload.repair.access,
        },
        customer: payload.createContractPayload.customer,
        payment: payload.createContractPayload.payment,
        preferredPaymentDay:
          payload.createContractPayload.payment.paymentType == 'D'
            ? payload.createContractPayload.payment.preferredPaymentDay
            : 0,
        contract: {
          ...state.details.contract,
          status: 'loading',
        },
      } as QuoteDetails,
    } as QuoteState;
  }),
  on(QuoteActions.CustomerContract, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        customer: payload.customerContractPayload,
      },
    };
  }),
  on(QuoteActions.CreateContractSuccess, (state, { payload }) => {
    return {
      ...state,

      details: {
        ...state.details,
        contract: { ...payload, status: 'success' },
      },
    };
  }),
  on(QuoteActions.CreateContractFail, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        contract: {
          ...state.details.contract,
          status: 'error',
        },
      },

      error: {
        errorStatus: payload?.status,
        errorMessage: payload?.message,
      },
    };
  }),
  on(QuoteActions.ClearErrorMessageSuccess, (state) => {
    return {
      ...state,

      error: {
        errorStatus: '',
        errorMessage: '',
      },
    };
  }),
  on(QuoteActions.ClearQuoteStateSuccess, (state) => {
    return initialState;
  }),
  on(QuoteActions.GetEngineerAvailabilityDates, (state) => {
    return {
      ...state,
      remoteState: {
        ...state.remoteState,
        availabilityData: RemoteDataState.Loading,
      },
    };
  }),
  on(
    QuoteActions.GetEngineerMoreAvailabilityDatesFail,
    QuoteActions.GetEngineerAvailabilityDatesFail,
    (state) => {
      return {
        ...state,
        remoteState: {
          ...state.remoteState,
          availabilityData: RemoteDataState.Error,
        },
      };
    }
  ),
  on(QuoteActions.GetFault, (state) => {
    return {
      ...state,
      remoteState: {
        ...state.remoteState,
        faultArray: RemoteDataState.Loading,
      },
    };
  }),
  on(QuoteActions.GetQuote, (state) => {
    return {
      ...state,
      remoteState: {
        ...state.remoteState,
        quote: RemoteDataState.Loading,
      },
    };
  }),
  on(QuoteActions.InvalidateQuote, (state) => {
    return {
      ...state,
      remoteState: {
        ...state.remoteState,
        quote: RemoteDataState.NotFetched,
      },
    };
  }),
  on(QuoteActions.InvalidateFaultArray, (state) => {
    return {
      ...state,
      details: {
        ...state.details,
        faultArrayDetails: {
          ...initialState.details.faultArrayDetails,
        },
      },
      remoteState: {
        ...state.remoteState,
        faultArray: RemoteDataState.NotFetched,
      },
    };
  }),
  on(QuoteActions.InvalidateAvailabilityDates, (state) => {
    return {
      ...state,
      details: {
        ...state.details,
        sessionId: initialState.details.sessionId,
        calendarAvailability: {
          ...initialState.details.calendarAvailability,
        },
      },
      remoteState: {
        ...state.remoteState,
        availabilityDates: RemoteDataState.NotFetched,
      },
    };
  }),
  on(QuoteActions.GetQuoteFail, QuoteActions.GetFaultFail, (state, { payload }) => {
    return {
      ...state,

      error: {
        errorStatus: payload,
        errorMessage: payload,
      },
    };
  }),
  on(QuoteActions.GetQuoteFail, (state, { payload }) => {
    return {
      ...state,
      remoteState: {
        ...state.remoteState,
        quote: RemoteDataState.Error,
      },
    };
  }),
  on(QuoteActions.GetFaultFail, (state, { payload }) => {
    return {
      ...state,
      remoteState: {
        ...state.remoteState,
        faultArray: RemoteDataState.Error,
      },
    };
  })
);

export function reducer(state: QuoteState | undefined, action: Action) {
  return quoteReducer(state, action);
}
