import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader.component';
import { LoaderOverlayService } from './loader.service';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// Note we need a separate function to display loading animation as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    OverlayModule,
    CommonModule,
    LottieModule.forRoot({ player: playerFactory, useCache: true }),
  ],
  declarations: [LoaderComponent],
  providers: [LoaderOverlayService],
  exports: [LoaderComponent],
})
export class LoaderModule {}
