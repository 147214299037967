import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'randc-user-idle-timeout',
  templateUrl: './user-idle-timeout.component.html',
  styleUrls: ['./user-idle-timeout.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIdleTimeoutComponent {
  constructor(public dialogRef: MatDialogRef<UserIdleTimeoutComponent>) {}

  continueBooking() {
    this.dialogRef.close();
  }
}
