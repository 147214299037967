<div
  [formGroup]="group"
  class="form-input app-textarea"
  [ngClass]="{
    'is-invalid': !group.controls[control].valid && group.controls[control]?.touched,
    'is-valid': group.controls[control]?.valid && group.controls[control]?.touched
  }"
>
  <randc-label *ngIf="label" [label]="label" [class]="label.classes" [forElement]="labelFor"
    >{{ label.text }}
  </randc-label>
  <randc-hint *ngIf="hint" [hint]="hint"></randc-hint>
  <div class="form-control">
    <textarea
      [class]="classes"
      [rows]="rows"
      [id]="labelFor"
      [attr.aria-label]="labelFor"
      [formControlName]="control"
      [placeholder]="placeholder"
      (blur)="showValidation = true"
      [attr.maxlength]="chrLimit"
    ></textarea>
    <i class="icon icon-tick-circle"></i>
    <i class="icon icon-error-circle"></i>
    <p *ngIf="chrLimit" class="character-counter">{{ theLength }} characters left</p>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <randc-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="control"
    >
    </randc-validation-error-formcontrol>
  </ng-container>
</div>
