import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DATE_FORMAT_DAY_MONTH_LONG } from '@common/util-base';

@Component({
  selector: 'randc-repair-care-plan-card',
  templateUrl: './repair-care-plan-card.component.html',
  styleUrls: ['./repair-care-plan-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepairCarePlanCardComponent {
  @Input() public quote: any;
  @Input() public applianceDetails: any;
  @Input() public repairDate: any;
  @Input() public showTotalPrice = false;

  public dateFormat: string = DATE_FORMAT_DAY_MONTH_LONG;
}

// Doest affect the quote component
