import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlagsService } from './feature-flags.service';

export enum JourneyStep {
  applianceDetails = 'appliance-details',
  bookAppointment = 'book-appointment',
  bookingDetails = 'booking-details',
  productSelection = 'product-selection',
  quote = 'get-quote',
}

@Injectable({ providedIn: 'root' })
export class JourneyNavigationService {
  constructor(private router: Router, private featureFlagsService: FeatureFlagsService) {}

  private order: JourneyStep[] = this.calculateOrder();

  private calculateOrder() {
    const bookingDetailsBeforeCalendar = this.featureFlagsService.isFlagEnabled(
      'bookingDetailsBeforeCalendar'
    );
    const oneOffRepairEnabled = this.featureFlagsService.isFlagEnabled('oneOffRepairEnabled');

    const bookingSteps = bookingDetailsBeforeCalendar
      ? [JourneyStep.bookingDetails, JourneyStep.bookAppointment]
      : [JourneyStep.bookAppointment, JourneyStep.bookingDetails];
    const oneOffSteps = oneOffRepairEnabled ? [JourneyStep.productSelection] : [];

    return [JourneyStep.applianceDetails, ...bookingSteps, ...oneOffSteps, JourneyStep.quote];
  }

  public next(current: JourneyStep) {
    const currentIndex = this.order.indexOf(current);
    if (currentIndex === -1) {
      throw new Error('Unknown step');
    }
    const nextIndex = currentIndex + 1;
    const nextStep = this.order[nextIndex];
    if (!nextStep) {
      throw new Error('On last step');
    }
    this.router.navigate([nextStep]);
  }
}
