<div class="sm-stack md-row lg-row my-account-banner">
  <div class="xs-12 md-7 my-account-wrapper">
    <h3 class="main-title">My Account</h3>
    <h2 class="sub-title">Manage your plans, book repairs, and access help and advice</h2>
    <a
      [href]="buildConfigService.config.myAccountLink"
      class="btn btn--primary btn-my-account"
      target="_blank"
      >Go to My Account</a
    >
  </div>
  <div class="xs-12 md-5 xs--hidden md--block my-account-image"></div>
</div>
