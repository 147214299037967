import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { QuotePartialState } from '../+state/quote/quote.reducer';
import * as QuoteSelectors from '../+state/quote/quote.selectors';
import * as QuoteActions from '../+state/quote/quote.actions';
import { GetQuote } from '../+state/quote/quote.actions';
import {
  APIQuoteRequest,
  CustomerDetails,
  filterNullUndefined,
  PlanDetails,
  PlanPaymentDetails,
  Quote,
} from '@common/util-base';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuoteFacade {
  constructor(private store: Store<QuotePartialState>) {}

  quoteRemoteState$ = this.store.select(QuoteSelectors.getQuoteRemoteState);
  faultArrayRemoteState$ = this.store.select(QuoteSelectors.getFaultArrayRemoteState);
  availabilityDatesRemoteState$ = this.store.select(QuoteSelectors.getAvailabilityDatesRemoteState);

  postcode$ = this.store.select(QuoteSelectors.getPostcode);
  outwardPostcode$ = this.store.select(QuoteSelectors.getOutwardPostcode);
  slotId$ = this.store.select(QuoteSelectors.getSlotId);
  sessionId$ = this.store.select(QuoteSelectors.getSessionId);

  repairOneOff$ = this.store.select(QuoteSelectors.isOneOffRepair);

  //todo: deprecate and use calendarFacade
  repairDate$ = this.store
    .select(QuoteSelectors.getRepairDate)
    .pipe(map((d) => (d ? new Date(d) : null)));
  //todo: deprecate and use calendarFacade
  repairTime$ = this.store.select(QuoteSelectors.getRepairTime);

  customerDetails$ = this.store.select(QuoteSelectors.getCustomerDetails);
  dynamicLandingPageDetails$ = this.store.select(QuoteSelectors.getDynamicLandingPageDetails);

  error$ = this.store.select(QuoteSelectors.getQuoteError);
  applianceDetails$ = this.store.select(QuoteSelectors.getApplianceDetails);

  planDetails$: Observable<PlanDetails> = this.store.select(QuoteSelectors.getContractDetails).pipe(
    map((details) => ({
      planNumber: `${details.result.completedItems?.[0].confirmation.planNumber}`,
      //todo: parse dates so we are returning date
      planStartDate: details.result.completedItems?.[0].confirmation.breakdownStartDate,
      planRenewalDate: details.result.completedItems?.[0].confirmation.renewalDate,
    }))
  );

  paymentDetails$: Observable<PlanPaymentDetails> = this.store
    .select(QuoteSelectors.getPreferredPaymentDay)
    .pipe(
      map((monthlyPaymentDate) => ({
        paymentMethod: 'Direct Debit',
        monthlyPaymentDate,
      }))
    );

  basketMetadata$ = this.store.select(QuoteSelectors.getBasket).pipe(
    filterNullUndefined(),
    map(({ id, itemId, itemType }) => ({ id, itemId, itemType }))
  );

  quote$ = this.store.select(QuoteSelectors.getSelectedQuote).pipe(
    filterNullUndefined(),
    map(
      (quote): Quote => {
        const {
          quoteId,
          coverType,
          totalCost,
          productType,
          // contractTypeCode,
          // companyCode,
          // schemeCode,
          subsequentPayment,
          numPayments,
          // breakdownStartDate,
          // waitDays,
          periodOfCover,
          paymentType,
          paymentProfile,
          firstPayment,
          paymentFrequency,
          // excessAmount
        } = quote;
        return {
          quoteId,
          coverType,
          totalCost,
          productType,
          // contractTypeCode,
          // companyCode,
          // schemeCode,
          subsequentPayment,
          numPayments,
          // breakdownStartDate,
          // waitDays,
          periodOfCover,
          paymentType,
          paymentProfile,
          firstPayment,
          paymentFrequency,
          // excessAmount
        };
      }
    )
  );

  quoteFromText$ = this.quote$.pipe(
    filter(({ firstPayment }) => firstPayment >= 0),
    map(({ firstPayment }) => {
      return `from £${firstPayment.toFixed(2)} per month for 12 months`;
    })
  );

  faultArray$ = this.store.select(QuoteSelectors.getFaultArrayDetails);

  getQuote(quotePayload: APIQuoteRequest) {
    this.store.dispatch(GetQuote({ payload: { quotePayload } }));
  }

  saveLandingFormValues(
    applianceTypeCode: string,
    applianceBrandCode: string,
    fullPostcode: string,
    outwardPostcode: string
  ) {
    return this.store.dispatch(
      QuoteActions.SaveLandingFormValues({
        payload: {
          applianceTypeCode: applianceTypeCode,
          applianceBrandCode: applianceBrandCode,
          postcode: fullPostcode,
          outwardPostcode: outwardPostcode,
        },
      })
    );
  }

  public addDynamicLandingPageDetails(
    applianceName: string,
    brandName: string,
    locationName: string
  ) {
    let siteVersion: string;
    if (locationName) {
      siteVersion = 'location';
    } else if (applianceName && !brandName) {
      siteVersion = 'appliance';
    } else if (brandName && !applianceName) {
      siteVersion = 'brand';
    } else if (applianceName && brandName) {
      siteVersion = 'appliance brand';
    } else {
      siteVersion = 'generic';
    }
    return this.store.dispatch(
      QuoteActions.AddDynamicLandingPageDetails({
        payload: {
          siteVersion,
          applianceName,
          brandName,
          locationName,
        },
      })
    );
  }

  public clearQuoteState() {
    return this.store.dispatch(QuoteActions.ClearQuoteState());
  }

  public getFault(sessionId: string) {
    return this.store.dispatch(QuoteActions.GetFault({ payload: { sessionId } }));
  }

  public clearErrorMessage() {
    return this.store.dispatch(QuoteActions.ClearErrorMessage());
  }

  public invalidateQuote() {
    return this.store.dispatch(QuoteActions.InvalidateQuote());
  }

  public customerContract(customerContractPayload: CustomerDetails) {
    return this.store.dispatch(
      QuoteActions.CustomerContract({
        payload: {
          customerContractPayload,
        },
      })
    );
  }
}
