import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { RemoteDataState } from '@common/util-base';
import { QuoteHelperService } from './quote-helper.service';
import { QuoteFacade } from '@common/data-access-quote';

@Injectable()
export class QuoteFetchedResolver implements Resolve<unknown> {
  constructor(
    private helper: QuoteHelperService,
    private quoteFacade: QuoteFacade,
    private router: Router
  ) {}

  resolve() {
    return this.quoteFacade.quoteRemoteState$.pipe(
      tap((state) => this.dispatchOnNotFetched(state)),
      filter((state) => this.filterSuccessOrError(state)),
      tap((itemsLoadedState) => this.handleError(itemsLoadedState)),
      take(1)
    );
  }

  private dispatchOnNotFetched(state: RemoteDataState) {
    if (state === RemoteDataState.NotFetched) {
      this.helper.getQuote();
    }
  }

  private filterSuccessOrError(state: RemoteDataState) {
    if (state === RemoteDataState.OK || state === RemoteDataState.Error) {
      return true;
    }
    return false;
  }

  private handleError(itemsLoadedState: RemoteDataState) {
    if (itemsLoadedState === RemoteDataState.Error) {
      this.router.navigate(['/generic-error']);
    }
  }
}
