<div class="container spacing-min-lg">
  <div class="xs-row justify--center text--center">
    <h2 class="xs-12 sm-8 lg-12 mb-2">{{ title }}</h2>
  </div>
</div>
<ui-key-points-stack
  [items]="_items"
  [alignContentCenter]="true"
  [roundImages]="true"
  imgWidth="144px"
  imgHeight="144px"
>
</ui-key-points-stack>
