import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CalendarDay, CalendarSelectedTimeSlot, TimeSlotType } from '@common/util-base';
import { Observable } from 'rxjs';
import { TimePickerComponentStore } from './time-picker.component.store';

@Component({
  selector: 'randc-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TimePickerComponentStore],
})
export class TimePickerComponent implements OnInit, OnChanges {
  @Input() date!: CalendarDay;
  @Output() selectTimeSlot = this.stateService
    .timeSlotChanges$ as Observable<CalendarSelectedTimeSlot>;

  public enumTimeSlotType = TimeSlotType;

  constructor(public stateService: TimePickerComponentStore) {}

  ngOnInit(): void {
    this.stateService.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.date.currentValue && changes.date.currentValue.slots) {
      this.stateService.updateState(changes.date.currentValue.slots);
    }
  }
}
