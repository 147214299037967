import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CmsParagraphReferences } from '@common/util-base';

@Component({
  selector: 'randc-paragraph-content',
  templateUrl: './paragraph-content.component.html',
  styleUrls: ['./paragraph-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParagraphContentComponent {
  @Input() paragraphContents!: CmsParagraphReferences[];
}
