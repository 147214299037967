import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'randc-form-elements',
  templateUrl: './form-elements.component.html',
  styles: ['randc-button { display: inline; }'],
})
export class FormElementsComponent {
  @Input() group!: FormGroup;
  @Input() data: any;
  @Input() validate: any;
  @Input() results: any;

  public showLandline = false;
}
