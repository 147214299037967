import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerDetails } from '@common/util-base';

@Component({
  selector: 'randc-spa-customer-details-panel',
  templateUrl: './customer-details-panel.component.html',
  styleUrls: ['./customer-details-panel.component.scss'],
})
export class CustomerDetailsPanelComponent implements OnChanges {
  _customerDetails: CustomerDetails | null = null;
  @Input() set customerDetails(value: CustomerDetails | null) {
    this._customerDetails = value;
  }
  get customerDetails() {
    return this._customerDetails;
  }

  public address = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerDetails && this.customerDetails) {
      this.address = Object.values(this.customerDetails.address)
        .filter((v) => !!v)
        .join('<br/>');
    }
  }
}
