import { createAction, props } from '@ngrx/store';
import {
  CalendarAvailabilityResponse,
  APICreateContractRequest,
  FaultArrayResponse,
  IndicativeQuoteRequest,
  APIQuoteRequest,
  APICheckOutResponse,
  Basket,
  CustomerDetails,
} from '@common/util-base';
import { DynamicLandingPageDetails } from '../../model/quote-store.model';

export const QUOTE_INIT = '[Quote] Init';
// get Dynamic Landing Page details
export const ADD_DYNAMIC_LANDING_PAGE_DETAILS =
  '[Quote] Add site version, appliance and brand name of dynamic landing page';

// Save Landing Form Details
export const SAVE_LANDING_FORM_VALUES = '[Quote] Save Landing Form Values';

// get Appointment Dates
export const GET_ENGINEER_AVAILABILITY_DATES =
  '[Book Appointment Component] Get Engineer Availability Dates';
export const GET_ENGINEER_AVAILABILITY_DATES_SUCCESS =
  '[Book Appointment API] Get Engineer Availability Dates Success';
export const GET_ENGINEER_AVAILABILITY_DATES_FAIL =
  '[Book Appointment API] Get Engineer Availability Dates Fail';

// get more Appointment Dates
export const GET_ENGINEER_MORE_AVAILABILITY_DATES =
  '[Book Appointment Component] Get Engineer More Availability Dates';
export const GET_ENGINEER_MORE_AVAILABILITY_DATES_SUCCESS =
  '[Book Appointment API] Get Engineer More Availability Dates Success';
export const GET_ENGINEER_MORE_AVAILABILITY_DATES_FAIL =
  '[Book Appointment API] Get Engineer More Availability Dates Fail';

export const ADD_APPOINTMENT_DATE = '[Book Appointment Component] Add selected Appointment Date';
export const ADD_APPOINTMENT_DATE_TIME =
  '[Book Appointment Component] Add selected Appointment Date and Time';

// get Quote
export const GET_QUOTE = '[Quote Component] Get Quote';
export const GET_QUOTE_SUCCESS = '[Quote API] Get Quote Success';
export const GET_QUOTE_FAIL = '[Quote API] Get Quote Fail';

// get Quote
export const GET_INDICATIVE_QUOTE = '[Quote Component] Get Indicative Quote';
export const GET_INDICATIVE_QUOTE_SUCCESS = '[Quote API] Get Indicative Quote Success';
export const GET_INDICATIVE_QUOTE_FAIL = '[Quote API] Get Indicative Quote Fail';

// get Fault
export const GET_FAULT = '[API Resolve] Get Fault';
export const GET_FAULT_SUCCESS = '[API Resolve] Get Fault Success';
export const GET_FAULT_FAIL = '[API Resolve] Get Fault Fail';

// create contract
export const CREATE_CONTRACT = '[Complete Order Component] Create Contract';
export const CREATE_CONTRACT_SUCCESS = '[Create Contract API] Create Contract Success';
export const CREATE_CONTRACT_FAIL = '[Create Contract API] Create Contract Fail';
export const CUSTOMER_CONTRACT = '[Complete Order Component] Customer Contract';

// clear error message
export const CLEAR_ERROR_MESSAGE = '[Error Banner Component] Clear Error Message';
export const CLEAR_ERROR_MESSAGE_SUCCESS = '[Quote Effects] Clear Error Message Success';

export const INVALIDATE_QUOTE = '[Quote] Invalidate Quote';
// clear Quote state
export const CLEAR_QUOTE_STATE = '[Order Confirmation Component] Clear Quote State';
export const CLEAR_QUOTE_STATE_SUCCESS = '[Quote Effects] Clear Quote State';

export const INVALIDATE_FAULT_ARRAY = '[Quote] Invalidate Fault Array';
export const INVALIDATE_AVAILABILITY_DATES = '[Quote] Invalidate Availability Dates';

export const Init = createAction(QUOTE_INIT);

export const AddDynamicLandingPageDetails = createAction(
  ADD_DYNAMIC_LANDING_PAGE_DETAILS,
  props<{ payload: DynamicLandingPageDetails }>()
);

export const SaveLandingFormValues = createAction(
  SAVE_LANDING_FORM_VALUES,
  props<{
    payload: {
      applianceTypeCode: string;
      applianceBrandCode: string;
      postcode: string;
      outwardPostcode: string;
    };
  }>()
);

export const GetEngineerAvailabilityDates = createAction(
  GET_ENGINEER_AVAILABILITY_DATES,
  props<{
    payload: {
      applianceDetails: {
        applianceCode: string;
        brandCode: string;
        postcode: string;
      };
    };
  }>()
);

export const GetEngineerAvailabilityDatesSuccess = createAction(
  GET_ENGINEER_AVAILABILITY_DATES_SUCCESS,
  props<{ payload: CalendarAvailabilityResponse }>()
);

export const GetEngineerAvailabilityDatesFail = createAction(GET_ENGINEER_AVAILABILITY_DATES_FAIL);

export const GetEngineerMoreAvailabilityDates = createAction(
  GET_ENGINEER_MORE_AVAILABILITY_DATES,
  props<{
    payload: {
      scrollToken: string;
      sessionId: string;
    };
  }>()
);

export const GetEngineerMoreAvailabilityDatesSuccess = createAction(
  GET_ENGINEER_MORE_AVAILABILITY_DATES_SUCCESS,
  props<{ payload: CalendarAvailabilityResponse }>()
);

export const GetEngineerMoreAvailabilityDatesFail = createAction(
  GET_ENGINEER_MORE_AVAILABILITY_DATES_FAIL
);

export const AddAppointmentDate = createAction(ADD_APPOINTMENT_DATE, props<{ payload: string }>());

export const AddAppointmentDateTime = createAction(
  ADD_APPOINTMENT_DATE_TIME,
  props<{ payload: { selectedDate: string; selectedTime: string; selectedSlotId: string } }>()
);

export const GetQuote = createAction(
  GET_QUOTE,
  props<{
    payload: {
      quotePayload: APIQuoteRequest;
    };
  }>()
);

export const GetQuoteSuccess = createAction(GET_QUOTE_SUCCESS, props<{ payload: Basket }>());

export const GetQuoteFail = createAction(GET_QUOTE_FAIL, props<{ payload: any }>());

export const GetIndicativeQuote = createAction(
  GET_INDICATIVE_QUOTE,
  props<{
    payload: IndicativeQuoteRequest;
  }>()
);
export const GetIndicativeQuoteSuccess = createAction(
  GET_INDICATIVE_QUOTE_SUCCESS,
  props<{ payload: Basket }>()
);

export const GetIndicativeQuoteFail = createAction(
  GET_INDICATIVE_QUOTE_FAIL,
  props<{ payload: any }>()
);

export const GetFault = createAction(
  GET_FAULT,
  props<{
    payload: {
      sessionId: string;
    };
  }>()
);

export const GetFaultSuccess = createAction(
  GET_FAULT_SUCCESS,
  props<{ payload: FaultArrayResponse }>()
);

export const GetFaultFail = createAction(GET_FAULT_FAIL, props<{ payload: any }>());

export const CustomerContract = createAction(
  CUSTOMER_CONTRACT,
  props<{ payload: { customerContractPayload: CustomerDetails } }>()
);

export const CreateContract = createAction(
  CREATE_CONTRACT,
  props<{ payload: { createContractPayload: APICreateContractRequest } }>()
);

export const CreateContractSuccess = createAction(
  CREATE_CONTRACT_SUCCESS,
  props<{ payload: APICheckOutResponse }>()
);

export const CreateContractFail = createAction(CREATE_CONTRACT_FAIL, props<{ payload: any }>());

export const ClearErrorMessage = createAction(CLEAR_ERROR_MESSAGE);

export const ClearErrorMessageSuccess = createAction(CLEAR_ERROR_MESSAGE_SUCCESS);

export const ClearQuoteState = createAction(CLEAR_QUOTE_STATE);
export const InvalidateQuote = createAction(INVALIDATE_QUOTE);

export const ClearQuoteStateSuccess = createAction(CLEAR_QUOTE_STATE_SUCCESS);

export const InvalidateFaultArray = createAction(INVALIDATE_FAULT_ARRAY);
export const InvalidateAvailabilityDates = createAction(INVALIDATE_AVAILABILITY_DATES);

export type QuoteActions =
  | typeof AddDynamicLandingPageDetails
  | typeof SaveLandingFormValues
  | typeof GetEngineerAvailabilityDates
  | typeof GetEngineerAvailabilityDatesSuccess
  | typeof GetEngineerAvailabilityDatesFail
  | typeof GetEngineerMoreAvailabilityDates
  | typeof GetEngineerMoreAvailabilityDatesSuccess
  | typeof GetEngineerMoreAvailabilityDatesFail
  | typeof AddAppointmentDate
  | typeof AddAppointmentDateTime
  | typeof GetQuote
  | typeof GetQuoteSuccess
  | typeof GetQuoteFail
  | typeof GetFault
  | typeof GetFaultSuccess
  | typeof GetFaultFail
  | typeof CreateContract
  | typeof CreateContractSuccess
  | typeof CreateContractFail
  | typeof ClearErrorMessage
  | typeof ClearErrorMessageSuccess
  | typeof ClearQuoteState
  | typeof ClearQuoteStateSuccess
  | typeof InvalidateQuote
  | typeof InvalidateFaultArray
  | typeof InvalidateAvailabilityDates;
