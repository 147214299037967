import { Component, Input } from '@angular/core';

@Component({
  selector: 'randc-button',
  templateUrl: './button.component.html',
  styles: [
    `
      :host {
        display: inline;
      }

      button {
        width: 100%;
      }
    `,
  ],
})
export class ButtonComponent {
  @Input() classes!: string;
  @Input() type!: string;
  @Input() control!: string;
  @Input() text!: string;
  @Input() stretch!: string | boolean;
  @Input() disabled!: boolean;
  @Input() submitted!: boolean;
}
