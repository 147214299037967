<div class="got-question-banner">
  <h3>Got a question?</h3>
  <h2>Just get in touch and we'll be happy to help</h2>
  <a
    href="https://www.domesticandgeneral.com/content/help-advice-section/faq"
    class="link"
    target="_blank"
    >Frequently asked questions</a
  >
</div>
