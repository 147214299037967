import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromBookingDetails from './+state/booking-details.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BookingDetailsEffects } from './+state/booking-details.effects';
import { BookingDetailsFacade } from './+state/booking-details.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromBookingDetails.BOOKING_DETAILS_FEATURE_KEY,
      fromBookingDetails.reducer,
      { metaReducers: fromBookingDetails.metaReducers }
    ),
    EffectsModule.forFeature([BookingDetailsEffects]),
  ],
  providers: [BookingDetailsFacade],
})
export class RandcDataAccessBookingDetailsModule {}
