const replaceTokens = (template: string, tokens: Record<string, string | null>) =>
  template.replace(/\{([^}]+)\}/g, (m, key) => tokens[key] || key);

export function recursiveReplace<D = any>(data: D, tokens: Record<string, string | null>): D {
  if (typeof data === 'string') {
    return (replaceTokens(data, tokens) as unknown) as D;
  }
  if (Array.isArray(data)) {
    return (data.map((v) => recursiveReplace(v, tokens)) as unknown) as D;
  }
  if (typeof data === 'object') {
    return (Object.entries(data).reduce((a, [k, v]) => {
      return { ...a, [k]: recursiveReplace(v, tokens) };
    }, {}) as unknown) as D;
  }
  return data;
}
