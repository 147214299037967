import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { CalendarDay } from '@common/util-base';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CalendarHelperService } from './services/calendar-helper.service';

export interface CalendarComponentState {
  daysOfTheWeek: string[];
  calendarDays: CalendarDay[];
  selectedDateId: number;
  monthInView: string;
}

export const INITIAL_STATE: CalendarComponentState = {
  daysOfTheWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  calendarDays: [],
  selectedDateId: 0,
  monthInView: '',
};

@Injectable()
export class CalendarComponentStore extends ComponentStore<CalendarComponentState> {
  constructor(private _calendarHelperService: CalendarHelperService) {
    super(INITIAL_STATE);
  }

  // Updaters
  readonly updateCalendarDays = this.updater((state, appointments: CalendarDay[]) => {
    const calendarDays = this._calendarHelperService.buildCalendarUI(appointments);
    return {
      ...state,
      calendarDays,
    };
  });

  readonly updateSelectedCalendarDay = this.updater((state, value: number | undefined) => {
    const updated = this._calendarHelperService.updateSelectedDay(state.calendarDays, value);
    return { ...state, ...updated };
  });

  readonly updateMonthInView = this.updater((state, days: { firstDay: Date; lastDay: Date }) => {
    const monthInView = this._calendarHelperService.setCalendarMonthInView(
      days.firstDay,
      days.lastDay
    );

    return {
      ...state,
      monthInView,
    };
  });

  // Selects
  public readonly daysOfTheWeek$: Observable<string[]> = this.select(
    (state: CalendarComponentState) => state.daysOfTheWeek
  );

  public readonly selectedDateId$: Observable<number> = this.select(
    (state: CalendarComponentState) => state.selectedDateId
  );

  public readonly calendarDays$: Observable<CalendarDay[]> = this.select(
    (state: CalendarComponentState) => state.calendarDays
  );

  public readonly monthInView$: Observable<string> = this.select(
    (state: CalendarComponentState) => state.monthInView
  );

  // View Model
  readonly vm$ = this.select(
    this.daysOfTheWeek$,
    this.calendarDays$,
    this.selectedDateId$,
    this.monthInView$,
    (daysOfTheWeek, calendarDays, selectedDateId, monthInView) => ({
      daysOfTheWeek,
      calendarDays,
      selectedDateId,
      monthInView,
    })
  );

  // Events
  readonly dateChanges$ = this.vm$.pipe(
    switchMap(({ calendarDays, selectedDateId }) =>
      of(calendarDays && calendarDays[selectedDateId])
    )
  );
}
