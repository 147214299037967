import { Injectable } from '@angular/core';
import {
  ControlType,
  DynamicFormbuilderService,
  FieldDef,
  SelectDef,
  SubmitDef,
  TextInputDef,
  YesNoDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { AbstractControl, Validators } from '@angular/forms';
import { filterNullUndefined, FormAnalyticsHelper, UtilService } from '@common/util-base';
import {
  ApplianceDetailsLandingFormStore,
  ApplianceDetailsLandingFormValues,
} from '../appliance-details-landing-form.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';

export interface ApplianceDetailsLandingFormFields {
  [key: string]: FieldDef;
  postcode: TextInputDef;
  appliance: SelectDef;
  brand: SelectDef;
  applianceCondition: YesNoDef;
  ageCondition: YesNoDef;
  costCondition: YesNoDef;
  submit: SubmitDef;
}

@UntilDestroy()
@Injectable()
export class ApplianceDetailsLandingFormService {
  _separateApplianceConditionFields = false;

  set separateApplianceConditionFields(value: boolean) {
    this._separateApplianceConditionFields = value;
  }

  constructor(
    private dfb: DynamicFormbuilderService,
    private _utilService: UtilService,
    public readonly store: ApplianceDetailsLandingFormStore,
    private formAnalytics: FormAnalyticsHelper
  ) {
    this.store.pageBrand$
      .pipe(filterNullUndefined(), untilDestroyed(this))
      .subscribe((pageBrand) => {
        this.formGroup.controls.brand.setValue(pageBrand.code);
      });
    this.store.pageAppliance$
      .pipe(filterNullUndefined(), untilDestroyed(this))
      .subscribe((pageAppliance) => {
        this.formGroup.controls.appliance.setValue(pageAppliance.code);
      });
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(this.store.updateFormValues);
  }

  public readonly fieldDefs: ApplianceDetailsLandingFormFields = {
    postcode: {
      controlType: ControlType.INPUT,
      controlName: 'postcode',
      validators: [Validators.required, this._utilService.isPostcodeCorrectValidator()],
      label: { text: 'What’s your postcode?' },
      placeholder: 'Enter a postcode',
      validationMessages: [
        { type: 'required', message: 'Please enter your postcode' },
        {
          type: 'isPostcodeCorrect',
          message: 'You’ve entered an invalid postcode. Please check and try again.',
        },
      ],
      tooltip: undefined,
      hint: undefined,
    } as TextInputDef,
    appliance: {
      controlType: ControlType.SELECT,
      controlName: 'appliance',
      validators: [Validators.required],
      validationMessages: [{ type: 'required', message: 'Please select an appliance type' }],
      label: { text: 'What kind of appliance is it?' },
      placeholder: 'Choose your appliance',
      optionsStream$: this.store.applianceSelectOptions$,
      showPreloader: true,
      disabledStream$: this.store.disableAppliance$,
      loadingStream$: this.store.appliancesLoading$,
    } as SelectDef,
    brand: {
      controlType: ControlType.SELECT,
      controlName: 'brand',
      validators: [Validators.required],
      validationMessages: [{ type: 'required', message: 'Please select a brand' }],
      label: { text: 'Who made your appliance?' },
      placeholder: 'Choose your brand',
      showPreloader: true,
      optionsStream$: this.store.brandSelectOptions$,
      loadingStream$: this.store.brandsLoading$,
      disabledStream$: this.store.disableBrand$,
    } as SelectDef,
    applianceCondition: {
      controlType: ControlType.YESNO,
      controlName: 'applianceCondition',
      validationMessages: [{ type: 'required', message: 'Please select yes or no' }],
      validators: [this.requiredIfNotSeparateFields()],
      label: {
        text: 'Is your appliance under 8 years old and did it cost you less than {maxPrice}?',
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      tooltip:
        "This plan is only available for appliances that are under 8 years old and cost less than {maxPrice}. If your appliance doesn’t qualify, we can still help. Call <a href='tel:+448005614489'>0800 561 4489</a> and we’ll get you sorted. ",
    },
    ageCondition: {
      controlType: ControlType.YESNO,
      controlName: 'ageCondition',
      validationMessages: [{ type: 'required', message: 'Please select yes or no' }],
      validators: [this.requiredIfSeparateFields()],
      label: {
        text: 'Is your appliance under 8 years old?',
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      tooltip:
        "This plan is only available for appliances that are under 8 years old. If your appliance doesn’t qualify, we can still help. Call <a href='tel:+448005614489'>0800 561 4489</a> and we’ll get you sorted. ",
    },
    costCondition: {
      controlType: ControlType.YESNO,
      controlName: 'costCondition',
      validationMessages: [{ type: 'required', message: 'Please select yes or no' }],
      validators: [this.requiredIfSeparateFields()],
      label: {
        text: 'Did it cost you less than £1,500?',
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      tooltip:
        "This plan is only available for appliances that cost less than £1,500. If your appliance doesn’t qualify, we can still help. Call <a href='tel:+448005614489'>0800 561 4489</a> and we’ll get you sorted. ",
    },

    submit: {
      controlType: ControlType.SUBMIT,
      label: { text: 'Book a repair' },
      classes: 'btn btn--primary',
      excludeFromFormGroup: true,
      disabled: true,
    },
  };
  formGroup = this.dfb.generateFormGroup(Object.values(this.fieldDefs));
  formatPostcode() {
    const postcodeControl = this.formGroup.controls.postcode;
    const { fullPostcode } = this._utilService.formatPostcode(postcodeControl.value);
    postcodeControl.setValue(fullPostcode);
  }

  validate() {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  async restoreValues() {
    const values = await this.store.formValues$.pipe(take(1)).toPromise();
    const fields = Object.keys(values) as (keyof ApplianceDetailsLandingFormValues)[];
    fields.forEach((field) => {
      if (values[field]) {
        this.formGroup.controls[field].setValue(values[field]);
        this.formGroup.controls[field].markAsTouched();
      }
    });
  }
  public getFormValidationErrorMessages() {
    return this.formAnalytics.getValidationErrorMessages<ApplianceDetailsLandingFormFields>(
      this.formGroup,
      this.fieldDefs
    );
  }

  private requiredIfSeparateFields() {
    return (formControl: AbstractControl) => {
      if (this._separateApplianceConditionFields) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  private requiredIfNotSeparateFields() {
    return (formControl: AbstractControl) => {
      if (!this._separateApplianceConditionFields) {
        return Validators.required(formControl);
      }
      return null;
    };
  }
}
