import { Action, createReducer, MetaReducer, on } from '@ngrx/store';
import {
  AccessDetails,
  AppliancePurchaseDetails,
  BookRepairForm,
  FaultDetails,
  ModelDetails,
  ApplianceDetails,
} from '@common/util-base';
import { updateApplianceDetails, updateBookingForm } from './booking-details.actions';

export const BOOKING_DETAILS_FEATURE_KEY = 'bookingDetails';

export interface BookingDetailsState {
  appliance?: ApplianceDetails;
  model?: ModelDetails;
  fault?: FaultDetails;
  access?: AccessDetails;
  appliancePurchase?: AppliancePurchaseDetails;
}

const initialState: BookingDetailsState = {
  appliance: {
    applianceTypeCode: '',
    applianceTypeName: '',
    applianceBrandCode: '',
    applianceBrandName: '',
  },
  model: {
    modelNumber: '',
    serialNumber: '',
  },
  fault: {
    categoryId: '',
    faultId: '',
    description: '',
  },
  access: {
    engineerAccess: false,
    engineerAccessDescription: '',
    parkingAccess: false,
    parkingAccessDescription: '',
  },
  appliancePurchase: {
    appliancePurchaseMonth: 0,
    appliancePurchaseYear: 0,
    appliancePurchasePrice: 0,
  },
};

export const metaReducers: MetaReducer<BookingDetailsState>[] = [];

const bookingDetailsReducer = createReducer(
  initialState,
  on(
    updateBookingForm,
    (state: BookingDetailsState, { form }): BookingDetailsState => {
      const bookRepairForm: BookRepairForm = form;
      return {
        ...state,
        model: {
          modelNumber: bookRepairForm.modelNumber,
          modelId: bookRepairForm.modelId,
          serialNumber: '',
        },
        fault: {
          categoryId: bookRepairForm.faultCategory,
          faultId: bookRepairForm.faultType,
          description: bookRepairForm.faultDescription,
        },
        access: {
          engineerAccess: true,
          engineerAccessDescription: '',
          parkingAccess: true,
          parkingAccessDescription: '',
        },
        appliancePurchase: {
          appliancePurchaseMonth: 12,
          appliancePurchaseYear: bookRepairForm.yearPurchased,
          appliancePurchasePrice: bookRepairForm.purchasePrice,
        },
      };
    }
  ),
  on(
    updateApplianceDetails,
    (state: BookingDetailsState, { applianceDetails }): BookingDetailsState => {
      return {
        ...state,
        appliance: {
          ...applianceDetails,
        },
      };
    }
  )
);

export function reducer(state: BookingDetailsState | undefined, action: Action) {
  return bookingDetailsReducer(state, action);
}
