import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Environment, ENVIRONMENT } from '../tokens/environment.token';
import { BuildConfig, RUNTIME_CONFIG, RuntimeConfig } from '../tokens/runtime-config.token';

/**
 * Provides dynamic runtime environment configuration.
 * See https://labs.thisdot.co/blog/runtime-environment-configuration-with-angular
 * for more information.
 * Wherever environment-specific configuration is required it can be accessed
 * via the config property of the injected service.
 */
@Injectable({
  providedIn: 'root',
})
export class BuildConfigService {
  config$: ReplaySubject<BuildConfig> = new ReplaySubject(1);
  config!: BuildConfig;

  constructor(
    @Inject(ENVIRONMENT) environment: Environment,
    @Inject(RUNTIME_CONFIG) runtimeConfig: RuntimeConfig
  ) {
    this.evaluateConfig(environment, runtimeConfig);
    this.config$.next(this.config);
  }

  private evaluateConfig(environment: Environment, runtimeConfig: RuntimeConfig) {
    this.config = { ...environment, ...runtimeConfig } as BuildConfig;
  }
}
