import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AnalyticsService } from '@common/util-base';

@Component({
  selector: 'randc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() group!: FormGroup;
  @Input() item: any;
  @Input() label: any;
  @Input() hint: any;
  @Input() labelFor: any;
  @Input() showValidation: any;
  @Input() validators: any;
  @Input() tooltip: any;
  @Input() customSelectArrow: any;
  tooltipVisible = false;
  name: any;
  id: any;
  public initValueIndex: number | null = null;

  constructor(private _analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.initValueIndex =
      this.group?.controls[this.item.control]?.value === null
        ? 0
        : this.group?.controls[this.item.control]?.value;
  }

  // Pre-select an option based on a passed in parameter
  ngOnChanges() {
    if (this.item.selected) {
      this.group?.controls[this.item.control]?.setValue(this.item.selected);
    }
  }

  onBlur(selectValue: any) {
    this._analyticsService.triggerInputEvent(this.item.control, this.group);
  }
}
