import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CmsMainHero } from '@common/util-base';

@Component({
  selector: 'randc-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroComponent {
  @Input() mainHeroDetails!: CmsMainHero;
  @Input() imageUrl = '';
}
