import { CmsDataResolverConfig, CmsPage } from '@common/util-base';
import { mapCmsFormData, mapCmsQuoteLandingData } from './cms-data-mappers';

const pageName = 'dgx-forms';

export const cmsDataUrl: { [key: string]: CmsDataResolverConfig } = {
  [CmsPage.CheckoutPayment]: {
    id: CmsPage.CheckoutPayment,
    url: `${pageName}?application=repair-and-care&formName=checkoutPaymentForm&lang=en`,
    localFile: 'checkoutPaymentForm',
    mapper: mapCmsFormData,
  },
  [CmsPage.DirectDebitGuarantee]: {
    id: CmsPage.DirectDebitGuarantee,
    url: `${pageName}?application=frp&formName=directDebitGuarantee&lang=en`,
    localFile: 'directDebitGuarantee',
    mapper: mapCmsFormData,
  },
  [CmsPage.QuoteLanding]: {
    id: CmsPage.QuoteLanding,
    url: `randc-combine-content-app?@name=quote-page`,
    localFile: 'quote-page',
    mapper: mapCmsQuoteLandingData,
  },
};
