import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'randc-got-question-banner',
  templateUrl: './got-question-banner.component.html',
  styleUrls: ['./got-question-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GotQuestionBannerComponent {}
