/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { PanelComponentsModule } from '@domgen/dgx-fe-base-components';
import { PlanDetailsPanelComponent } from './plan-details-panel/plan-details-panel.component';
import { CustomerDetailsPanelComponent } from './customer-details-panel/customer-details-panel.component';
import { FaultDetailsPanelComponent } from './fault-details-panel/fault-details-panel.component';
import { RandcUiModule } from '../randc-ui.module';

const components = [
  PlanDetailsPanelComponent,
  CustomerDetailsPanelComponent,
  FaultDetailsPanelComponent,
];

@NgModule({
  imports: [CommonModule, RandcUiModule, SharedUiBaseComponentsModule, PanelComponentsModule],
  declarations: [...components, CustomerDetailsPanelComponent, FaultDetailsPanelComponent],
  exports: [...components],
})
export class RandcUiOrderConfirmationModule {}
