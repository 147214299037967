import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { CmsFormField, PlanDocumentsFormField } from '@domgen/dgx-fe-business-models';
import {
  CheckboxToggleDef,
  ControlType,
  FieldDef,
  YesNoDef,
} from '@domgen/dgx-fe-dynamic-form-builder';

import { getDefaultFieldDef, getFormField } from '@common/util-base';

@Injectable({ providedIn: 'root' })
export class PlanDocumentsFormConfigService {
  public getFormbuilderConfig(cmsFormData: CmsFormField[]): FieldDef[] {
    return [
      this.getInputDefByFieldName(cmsFormData, PlanDocumentsFormField.Paperless),
    ] as FieldDef[];
  }

  private getInputDefByFieldName(
    cmsFormData: CmsFormField[],
    formField: PlanDocumentsFormField
  ): FieldDef {
    let fieldDef: FieldDef;
    const cmsFormField = getFormField<CmsFormField>(cmsFormData, formField);
    const defaultFieldDef = getDefaultFieldDef(cmsFormField, formField);

    switch (formField) {
      case PlanDocumentsFormField.Paperless:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.YESNO,
          validators: [Validators.required],
          options: cmsFormField.options
            ? cmsFormField.options
            : [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
          message: cmsFormField.message
            ? cmsFormField.message
            : "Going paperless means there's less post to deal with, file or recycle.",
        } as YesNoDef;
        break;
      case PlanDocumentsFormField.TermsAndConditions:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.ACCEPT,
          validators: [Validators.requiredTrue],
        } as CheckboxToggleDef;
        break;
      default:
        fieldDef = {
          ...defaultFieldDef,
        } as FieldDef;
        break;
    }

    return fieldDef;
  }
}
