<button
  role="button"
  [class]="classes"
  tabindex="0"
  [type]="type ? type : 'button'"
  [attr.disabled]="disabled ? true : null"
>
  <span class="label">{{ text }}</span>
  <i class="icon icon-arrow-right"></i>
  <ng-content></ng-content>
</button>
