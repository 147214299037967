export const mockCmsDataQuote = {
  results: [
    {
      cancellation: {
        '@name': 'cancellation',
        '@path': '/repair-and-care/cancellation',
        '@id': '5c3da085-3eb4-452c-a515-24440f17ba55',
        '@nodeType': 'dgx-paragraph',
        paragraphContent:
          '<p>You can cancel your plan at any time. However, you&#39;ll be charged for any repairs we&#39;ve carried out (including the call-out-fee). Please note, you&#39;ll be charged for the full plan if we&#39;ve replaced appliance. Fees will not exceed the price of the plan. For further details, see the <a href="${link:{uuid:{9e6cfef5-7120-4934-9328-11a91ba05e0a},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Maitenance_and_Support_TCs.pdf}}}">Repair &amp; Care Plan Terms and Conditions</a> and the <a href="${link:{uuid:{5b163969-fb28-4def-8776-c7d341925688},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Accidental_Damage_TCs.pdf}}}">Repair &amp; Care Accidental Damage Insurance Policy Terms and Conditions</a>.</p>\n',
        title: 'Cancellation',
        jcrName: 'cancellation',
        '@nodes': [],
      },
      coverForLifesMishaps: {
        '@name': 'cover-for-lifes-mishaps',
        '@path': '/repair-and-care/cover-for-lifes-mishaps',
        '@id': '8add7d89-52d6-4439-9a73-a4a20da1c24e',
        '@nodeType': 'dgx-paragraph',
        paragraphContent:
          '<p>Repair &amp; Care includes accidental damage cover, provided under a seperate policy of insurance by Domestic &amp; General Insurance PLC, part of our Domestic &amp; General Group. For more information, please read the <a href="${link:{uuid:{6d3560c6-5168-4066-9769-a232a0192668},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_IPID.pdf}}}">Insurance Product Information Document</a></p>\n',
        title: "Plus, cover for life's mishaps",
        jcrName: 'cover-for-lifes-mishaps',
        '@nodes': [],
      },
      thingsYouNeedToKnow: {
        '@name': 'things-you-need-to-know',
        '@path': '/repair-and-care/things-you-need-to-know',
        '@id': '88d927f4-5574-4553-a8aa-206264a00ae3',
        '@nodeType': 'dgx-paragraph',
        paragraphContent:
          '<p>The accidental damage insurance policy is designed to meet the demands and needs of those who wish to insure electronic appliances and/or household goods (if repaired during the initial repair under the maintenance and support plan) against accidental damage.</p>\n\n<p>For your own benefit and protection, please read the <a href="${link:{uuid:{9e6cfef5-7120-4934-9328-11a91ba05e0a},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Maitenance_and_Support_TCs.pdf}}}">Repair &amp; Care Plans Terms and Conditions</a>, <a href="${link:{uuid:{5b163969-fb28-4def-8776-c7d341925688},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Accidental_Damage_TCs.pdf}}}">Repair &amp; Care Accidental Damage Insurance Policy Terms and Conditions</a> and <a href="https://www.domesticandgeneral.com/privacy-cookies-policy">Privacy Policy</a>&nbsp;</p>\n\n<p>Full details on how to submit a complaint can be found in the Repair &amp; Care Plan Terms and Conditions.</p>\n',
        title: 'Things you need to know',
        jcrName: 'things-you-need-to-know',
        '@nodes': [],
      },
      policyFeaturesReferences: {
        '@name': 'quote-policy-features',
        '@path': '/quote-policy-features',
        '@id': 'db247e68-4b0e-47e0-9690-d105c188b93e',
        '@nodeType': 'dgx-policy-feature',
        included: [
          "Our 'no fix, no fee' guarantee",
          'Exclusive access to our Appliance Care portal for tips and advice',
          'A remote annual health check',
          'Set-up and ongoing support to keep your appliance running smoothly',
          'Unlimited phone support from our Appliance Care Team',
          'Repairs by an expert engineer if your appliance fails the annual health check or stops working',
        ],
        excluded: [
          'Cosmetic and malicious damage, neglect, or deliberate damage',
          'Costs incurred as a result of not being able to use your product',
          'Loss, theft, or attempted theft',
          'Commercial use',
          'Cost of replacing any accessories other than those provided with your product',
        ],
        jcrName: 'quote-policy-features',
        '@nodes': [],
      },
    },
  ],
};
