/*================================================================================
  FORM BUILDER
================================================================================*/

export interface FormBuilderData {
  meta: {
    header: string;
    groups: FormBuilderGroup[];
  };
  formValues: Record<string, unknown>;
}

export interface FormBuilderGroup {
  input?: FromBuilderInput;
  addressLookup?: {
    input?: FromBuilderInput;
    addressOptions?: FormBuilderAddressLookup;
  }[];
  hint?: FormBuilderHint;
  checkbox?: FormBuilderCheckBox;
  button?: FormBuilderButton[];
}

export interface FromBuilderInput {
  label: { text: string; classes: string };
  validators?: FormBuilderValidator[];
  control: string;
  placeholder: string;
  classes: string;
  useShowPassword?: boolean;
  tooltip?: { text: string; classes: string };
  PasswordStrengthChecker?: PasswordStrengthChecker; // TODO: change to camelCase
}

export interface FormBuilderValidator {
  type: string;
  message: string;
  value?: number;
  regexp?: string;
}

// expose for hint component / tests
export interface FormBuilderHint {
  classes: string;
  markup?: { link: boolean; href: string };
  text: string;
}

interface FormBuilderCheckBox {
  control: string;
  value: boolean;
  text: string;
}

interface FormBuilderButton {
  control: string;
  text: string;
  type: string;
  disabled: boolean;
  stretch: boolean;
  submitted: boolean;
  classes: string;
}

interface PasswordStrengthChecker {
  showLength: boolean;
  showUppercase: boolean;
  showLowercase: boolean;
  showNumber: boolean;
}

export interface FormBuilderAddressLookup {
  lookup?: boolean; // setting this specifies the field the user searches in
  line1?: boolean; // set to true to enable this field to be populated
  line2?: boolean;
  line3?: boolean;
  city?: boolean;
  postcode?: boolean;
  hide: boolean;
}

export interface FormBuilderInputFragment {
  label: { text: string; classes: string };
  validators?: FormBuilderValidator[];
  control: string;
  options: { inputMaxLength: number; noOfInputFields: number };
}
