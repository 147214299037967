<div class="sales call-centre container">
  <h1>{{ title }}</h1>
  <p class="p1">{{ text1 }}{{ brandOrApplianceName || 'appliance' }}{{ text2 }}</p>
  <h2 class="mt-1">
    <a class="rTapNumber4053" href="tel:08005614489" (click)="triggerPhoneClickGA('08005614489')"
      >0800 561 4489</a
    >
  </h2>
  <p class="p1">{{ text3 }}</p>
</div>
