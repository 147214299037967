import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { CmsMainHero } from '@common/util-base';

@Component({
  selector: 'randc-spa-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroBannerComponent {
  @Input() data!: CmsMainHero;
  @Input() buttonText!: string;
  @Output() bookARepairClick: EventEmitter<void> = new EventEmitter();
}
