import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CmsTitleDescImageItem } from '@common/util-base';

@Component({
  selector: 'randc-brand-logo-banner',
  templateUrl: './brand-logo-banner.component.html',
  styleUrls: ['./brand-logo-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandLogoBannerComponent {
  @Input() brandLogoBannerItems!: CmsTitleDescImageItem[];
  @Input() imageUrl!: string;
  @Input() brandUrl!: string;
}
