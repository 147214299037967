import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private _utilService: UtilService) {}

  validators: any[] = [];
  addControlValidationFn(validators: any) {
    this.validators = [];
    for (const v in validators) {
      if (v) {
        switch (validators[v].type) {
          case 'email':
            this.validators.push(Validators.email);
            break;
          case 'emailMatch':
            this.validators.push(this._utilService.emailMatchValidator(validators[v].value));
            break;
          case 'required':
            this.ifCheckbox(validators[v].checkbox);
            break;
          case 'minlength':
            this.validators.push(Validators.minLength(validators[v].value));
            break;
          case 'maxlength':
            this.validators.push(Validators.maxLength(validators[v].value));
            break;
          case 'pattern':
            this.validators.push(
              Validators.pattern(new RegExp(validators[v].regexp, validators[v].flags))
            );
            break;
          case 'isOnTheList':
            this.validators.push(this._utilService.isOnTheListValidator(validators[v].value));
            break;
          case 'isPostcodeCorrect':
            this.validators.push(this._utilService.isPostcodeCorrectValidator());
            break;
        }
      }
    }
    return this.validators;
  }
  ifCheckbox(checkbox: any) {
    if (checkbox === true) {
      this.validators.push(Validators.requiredTrue);
    } else {
      this.validators.push(Validators.required);
    }
  }
}
