<header class="sales header xs--middle">
  <div class="container">
    <div class="container xs--middle header__nav-wrapper">
      <div class="header__menu flex-auto"></div>

      <a [href]="links?.home" target="_blank" class="header__logo xs--middle flex-auto">
        <img src="assets/images/dg-logo-colour.png" role="img" alt="Domestic & General" />
      </a>
    </div>
  </div>
</header>
