import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplianceDetailsPartialState } from './appliance-details.reducer';
import * as ApplianceDetailsSelectors from './appliance-details.selectors';
import * as ApplianceDetailsActions from './appliance-details.actions';
import { map } from 'rxjs/internal/operators';
import { RemoteDataState } from '@common/util-base';

@Injectable({
  providedIn: 'root',
})
export class ApplianceDetailsFacade {
  constructor(private store: Store<ApplianceDetailsPartialState>) {}

  allApplianceTypes$ = this.store.select(ApplianceDetailsSelectors.getAllApplianceTypes);
  allApplianceBrands$ = this.store.select(ApplianceDetailsSelectors.getAllApplianceBrands);
  applianceBrandsByType$ = this.store.select(ApplianceDetailsSelectors.getApplianceBrandsByType);
  applianceTypesByBrand$ = this.store.select(ApplianceDetailsSelectors.getApplianceTypesByBrand);
  selectedApplianceType$ = this.store.select(ApplianceDetailsSelectors.getSelectedApplianceType);
  selectedApplianceBrand$ = this.store.select(ApplianceDetailsSelectors.getSelectedApplianceBrand);

  popularAppliances$ = this.store.select(ApplianceDetailsSelectors.getPopularAppliances);
  popularBrands$ = this.store.select(ApplianceDetailsSelectors.getPopularBrands);

  isAllApplianceTypesLoading$ = this.store
    .select(ApplianceDetailsSelectors.getAllApplianceTypesRemoteDataState)
    .pipe(map((state) => state === RemoteDataState.Loading));
  isAllApplianceBrandsLoading$ = this.store
    .select(ApplianceDetailsSelectors.getAllApplianceBrandsRemoteDataState)
    .pipe(map((state) => state === RemoteDataState.Loading));
  isApplianceTypesLoading$ = this.store
    .select(ApplianceDetailsSelectors.getApplianceTypesRemoteDataState)
    .pipe(map((state) => state === RemoteDataState.Loading));
  isApplianceBrandsLoading$ = this.store
    .select(ApplianceDetailsSelectors.getApplianceBrandsRemoteDataState)
    .pipe(map((state) => state === RemoteDataState.Loading));

  getApplianceTypesByBrand(brandCode: string) {
    this.store.dispatch(
      ApplianceDetailsActions.GetApplianceTypesbyBrand({
        payload: {
          selectedApplianceBrand: brandCode,
        },
      })
    );
  }

  getApplianceBrandsByType(selectedApplianceType: string) {
    this.store.dispatch(
      ApplianceDetailsActions.GetApplianceBrandsByType({
        payload: {
          selectedApplianceType,
        },
      })
    );
  }
}
