import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CmsTitleDescImageItem } from '@common/util-base';

@Component({
  selector: 'randc-customer-engagement-banner',
  templateUrl: './customer-engagement-banner.component.html',
  styleUrls: ['./customer-engagement-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerEngagementBannerComponent {
  @Input() title!: string;
  @Input() customerEngagementItems!: CmsTitleDescImageItem[];
}
