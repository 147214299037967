<div class="quote-summary">
  <div class="row">
    <div class="xs-12--none">
      <ui-panel [variant]="basic ? 'normal' : 'highlight-info'">
        <ui-panel-header *ngIf="!basic">
          <ui-panel-title [variant]="'highlight'"
            ><h4>
              <div class="quote-summary__title">
                {{ title }}
              </div>
            </h4></ui-panel-title
          >
        </ui-panel-header>
        <ui-panel-body>
          <div *ngIf="basic; else displayMoreInfo" class="quote-summary__basic">
            <div>
              Engineer reserved for <strong>{{ repairDate | dateWithOrdinal: dateFormat }}</strong>
            </div>
            <div>
              <strong>{{ quote?.firstPayment | currency: 'GBP' }} per month </strong> for
              {{ quote?.periodOfCover }} months
            </div>
          </div>

          <ng-template #displayMoreInfo>
            <div class="quote-summary__cover-info">
              <div>
                {{ quote?.periodOfCover }} months protection for
                {{ applianceDetails?.applianceBrandName }}
                {{ applianceDetails?.applianceTypeName }}
              </div>
            </div>
            <div class="quote-summary__engineer-info">
              <div>
                Engineer reserved for
                <strong>{{ repairDate | dateWithOrdinal: dateFormat }},</strong> between
                <strong>{{ repairTimeslot }}</strong>
              </div>
            </div>
            <hr />

            <div class="quote-summary__price-info">
              <div>
                <strong>{{ quote?.firstPayment | currency: 'GBP' }} per month </strong> for
                {{ quote?.periodOfCover }} months
              </div>

              <div>Total {{ quote?.totalCost | currency: 'GBP' }}</div>
            </div>
          </ng-template>
        </ui-panel-body>
      </ui-panel>
    </div>
  </div>
</div>
