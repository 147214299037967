import { ActionReducer } from '@ngrx/store';
import { QUOTE_FEATURE_KEY } from '@common/data-access-quote';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';
import { BOOKING_DETAILS_FEATURE_KEY } from '@common/data-access-booking-details';
import { APPLIANCE_FEATURE_KEY } from '@common/data-access-appliance-details';

export function localStorageSyncReducer(
  reducer: ActionReducer<Record<string, unknown>>
): ActionReducer<Record<string, unknown>> {
  return localStorageSync({
    keys: ['auth', QUOTE_FEATURE_KEY, BOOKING_DETAILS_FEATURE_KEY, APPLIANCE_FEATURE_KEY],
    storage: sessionStorage,
    rehydrate: true,
  } as LocalStorageConfig)(reducer);
}
