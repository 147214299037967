import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom: Document) {}

  updateCanonicalUrl(host?: string) {
    const canonicalUrl = new URL(this.dom.URL);

    if (host) {
      canonicalUrl.protocol = 'https';
      canonicalUrl.hostname = host;
      canonicalUrl.port = '';
    }

    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }

    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', canonicalUrl.href);
  }
}
