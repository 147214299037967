import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BuildConfigService, HttpHeaderOptions, ApiHelper } from '@common/util-base';
import { throwError, Observable } from 'rxjs';
import { map, catchError, retry, tap } from 'rxjs/operators';
import { ApplianceDetailsMapperService } from '../appliance-details-mapper/appliance-details-mapper.service';
import {
  APIApplianceBrandResponse,
  APIApplianceTypeResponse,
  ApplianceBrandDetails,
  ApplianceTypeDetails,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ApplianceDetailsApiService {
  constructor(
    private _http: HttpClient,
    private buildConfigService: BuildConfigService,
    private applianceDetailsMapperService: ApplianceDetailsMapperService,
    private apiHelper: ApiHelper
  ) {}

  public handleApiError(err: HttpErrorResponse) {
    let errMsg = '';

    if (err.error instanceof ErrorEvent) {
      errMsg = `Error occurred in front-end ${err.error.message}`;
    } else {
      errMsg = `Backend returned ${err.status}, body was ${err.error.message}`;
    }
    return throwError(errMsg);
  }

  public getAllApplianceTypes(): Observable<ApplianceTypeDetails[]> {
    return this._http
      .get<APIApplianceTypeResponse>(
        `${this.buildConfigService.config.applianceLookupRoot}/v1/appliances`,
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        retry(2),
        map(
          (response: APIApplianceTypeResponse): ApplianceTypeDetails[] =>
            response.result.map(this.applianceDetailsMapperService.mapToApplianceTypeDetails),
          catchError(this.handleApiError)
        )
      );
  }

  public getAllApplianceBrands(): Observable<ApplianceBrandDetails[]> {
    return this._http
      .get<APIApplianceBrandResponse>(
        `${this.buildConfigService.config.applianceLookupRoot}/v1/brands`,
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        retry(2),
        map(
          (response: APIApplianceBrandResponse): ApplianceBrandDetails[] =>
            response.result.map(this.applianceDetailsMapperService.mapToApplianceBrandDetails),
          catchError(this.handleApiError)
        )
      );
  }

  public getApplianceBrandsByType(applianceTypeCode: string): Observable<ApplianceBrandDetails[]> {
    return this._http
      .get<APIApplianceBrandResponse>(
        `${this.buildConfigService.config.applianceLookupRoot}/v1/appliances/${encodeURIComponent(
          applianceTypeCode
        )}/brands`,
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        retry(2),
        map((response: APIApplianceBrandResponse): ApplianceBrandDetails[] =>
          response.result.map(this.applianceDetailsMapperService.mapToApplianceBrandDetails)
        ),
        catchError(this.handleApiError)
      );
  }

  public getApplianceTypesbyBrand(brandCode: string): Observable<ApplianceTypeDetails[]> {
    return this._http
      .get<APIApplianceTypeResponse>(
        `${this.buildConfigService.config.applianceLookupRoot}/v1/brands/${encodeURIComponent(
          brandCode
        )}/appliances`,
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        retry(2),
        map((response: APIApplianceTypeResponse): ApplianceTypeDetails[] =>
          response.result.map(this.applianceDetailsMapperService.mapToApplianceTypeDetails)
        ),
        catchError(this.handleApiError)
      );
  }

  createaHeaders(options: HttpHeaderOptions): HttpHeaders {
    return new HttpHeaders({ ...options });
  }
}
