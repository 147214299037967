import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BuildConfigService, CmsParagraphReferences } from '@common/util-base';

@Component({
  selector: 'randc-spa-things-need-to-know-or-cancellation',
  templateUrl: './things-need-to-know-or-cancellation.component.html',
  styleUrls: ['./things-need-to-know-or-cancellation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThingsNeedToKnowOrCancellationComponent {
  constructor(private buildConfig: BuildConfigService) {}
  thingsYouNeedToKnow: CmsParagraphReferences = {
    title: 'Things you need to know',
    paragraphContent: `<p class="mb-1">
    The accidental damage insurance policy is designed to meet the demands and needs of those who wish to insure electronic appliances and/or household goods (if repaired during the initial repair under the maintenance and support plan)
    against accidental damage.
  </p>
  <p class="mb-1">
    For your own benefit and protection, please read the <a target="_blank" class="link" href="assets/files/DG_Maitenance_and_Support_TCs.pdf" download="DG_Maitenance_and_Support_TCs.pdf">Repair & Care Plan Terms and Conditions</a>,
    <a href="assets/files/DG_Accidental_Damage_TCs.pdf" class="link" target="_blank" download="DG_Accidental_Damage_TCs.pdf">Repair & Care Accidental Damage Insurance Policy Terms and Conditions </a> and
    <a target="_blank" class="link" href="${this.buildConfig.config.privacyPolicyLink}">Privacy Policy</a> carefully before purchase.
  </p>
  <p class="mb-1">Full details on how to submit a complaint can be found in the Repair & Care Plan Terms and Conditions.</p>
  `,
  };
  cancellation: CmsParagraphReferences = {
    title: 'Cancellation',
    paragraphContent: `<p class="mb-1">
    You can cancel your plan at any time. However, you’ll be charged for any repairs we’ve carried out (including the call-out fee). Please note, you’ll be charged for the full plan if we’ve replaced your appliance. Fees will not exceed the
    price of the plan. For further details, see the <a target="_blank" class="link" href="assets/files/DG_Maitenance_and_Support_TCs.pdf" download="DG_Maitenance_and_Support_TCs.pdf">Repair & Care Plan Terms and Conditions</a>,
    <a href="assets/files/DG_Accidental_Damage_TCs.pdf" class="link" target="_blank" download="DG_Accidental_Damage_TCs.pdf">Repair & Care Accidental Damage Insurance Policy Terms and Conditions </a>.
  </p>
  `,
  };
}
