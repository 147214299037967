import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { OptionItem } from '@domgen/dgx-fe-dynamic-form-builder/lib/_shared/interfaces/dynamic-formbuilder.interface';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

const validateInput = (
  input: string,
  options: (string | OptionItem)[]
): ValidationErrors | null => {
  const optionValues: string[] = options.map((option: string | OptionItem) =>
    typeof option === 'string' ? option : option.value
  );
  const match = optionValues.find(
    (option: string) => option.toLowerCase() === input?.toLowerCase()
  );
  return match ? null : { invalid: true };
};

export const validTypeAheadSelection = (
  optionsStream$: Observable<string[] | OptionItem[]>
): AsyncValidatorFn => {
  return (control: AbstractControl) => {
    return optionsStream$.pipe(
      map((options: (string | OptionItem)[]) => {
        const returnVal = validateInput(control.value, options);
        return validateInput(control.value, options);
      })
    );
  };
};

export const conditionallyRequired = (dependsOn: string, dependsOnValue: unknown) => (
  formControl: AbstractControl
) => {
  if (!formControl.parent) {
    return null;
  }
  if (formControl.parent.get(dependsOn)?.value === dependsOnValue) {
    return Validators.required(formControl);
  }
  return null;
};
