import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromQuote from './+state/quote/quote.reducer';
import { QuoteEffects } from './+state/quote/quote.effects';
import { QuoteFacade } from './services/quote.facade';
import { CalendarFacade } from './services/calendar.facade';
import { CheckoutFacade } from './services/checkout.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromQuote.QUOTE_FEATURE_KEY, fromQuote.reducer),
    EffectsModule.forFeature([QuoteEffects]),
  ],
  providers: [QuoteFacade, CalendarFacade, CheckoutFacade],
})
export class RandcDataAccessQuoteModule {}
