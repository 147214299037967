import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CmsTitleDescItem } from '@common/util-base';

@Component({
  selector: 'randc-what-is-repair',
  templateUrl: './what-is-repair.component.html',
  styleUrls: ['./what-is-repair.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatIsRepairComponent {
  @Input() items: CmsTitleDescItem[] = [];
  @Input() buttonText = '';
  @Output() bookARepairClick: EventEmitter<void> = new EventEmitter();
}
