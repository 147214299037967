<div class="error-line">
  <div class="error-text">
    <p>You've entered incorrect Direct Debit details.</p>
    <p *ngIf="directDebitErrorCount < 2 || directDebitErrorCount == 2">
      Please check and try again
    </p>
    <div *ngIf="directDebitErrorCount > 2">
      Please check and try again or
      <a class="link" routerLink="/contact-us" target="_blank">contact us</a>
    </div>
  </div>
</div>
