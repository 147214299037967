import { Inject, Injectable, InjectionToken } from '@angular/core';

export interface FeatureFlags {
  hideChatNowBanner?: boolean;
  bookingDetailsBeforeCalendar: boolean;
  oneOffRepairEnabled?: boolean;
}

export const FEATURE_FLAGS = new InjectionToken<FeatureFlags>('Feature Flags');

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(@Inject(FEATURE_FLAGS) private flags: FeatureFlags) {}

  isFlagEnabled(flag: keyof FeatureFlags): boolean {
    return !!this.flags[flag];
  }
}
