import { BuildConfig } from '@common/util-base';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

const headerLinks: BuildConfig['header'] = {
  home: {
    routerPath: '/',
    label: 'home',
  },
  products: {
    label: 'Get protected',
    href: 'https://www.sales.ao-care.com/',
  },
  repair: {
    label: 'Book a repair',
    href: 'https://www.ao-care.com/RepairClaimPageView',
  },
  faq: {
    label: 'Help and advice',
    href: 'https://www.ao-care.com/content/ao-care-help-advice/ao-faqs',
  },
  profile: {
    href: 'https://www.myaccount.ao-care.com/',
    label: 'My account',
    icon: 'my-account',
    isHidden: false,
  },
  basket: {
    href: '/#',
    icon: 'basket',
    label: 'Basket',
    isHidden: true,
  },
};
const footerLinks: NavigationLink[] = [
  {
    section: 'protect',
    label: 'Household appliances',
    href: 'https://www.ao-care.com/products-5/appliances-household',
  },
  {
    section: 'useful',
    label: 'Help and advice',
    href: 'https://www.ao-care.com/content/ao-care-help-advice/ao-faqs',
  },
  {
    section: 'useful',
    label: 'Contact us',
    href: 'https://www.ao-care.com/content/contact-us',
  },
  {
    section: 'useful',
    label: 'ao.com',
    href: 'https://ao.com/',
  },
  {
    section: 'useful',
    label: 'Regulatory information',
    href: 'https://www.ao-care.com/company-information-legal',
  },
  {
    section: 'useful',
    label: 'Modern Slavery Statement',
    href: 'https://www.ao-care.com/modern-slavery',
  },
  {
    section: 'legal',
    label: 'Website Terms and Conditions',
    href: 'https://www.ao-care.com/web-terms-conditions',
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Privacy and Cookies Policy',
    href: 'https://www.ao-care.com/privacy-cookies-policy',
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Accessibility Policy',
    href: 'https://www.ao-care.com/accessibility',
    newWindow: true,
  },
];

export const DEFAULT_ENVIRONMENT: BuildConfig = {
  header: headerLinks,
  footer: {
    links: footerLinks,
  },
  cookieProSrc: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
  cookieProDomainScript: 'bbf3b265-ca81-4054-929d-d396ddccdd63-test',
  applianceLookupRoot: '',
  calendarRoot: '',
  faultRoot: '',
  contractRoot: '',
  modelSearchRoot: '',
  cmsContentRoot: '',
  identityApiUrlVersion: '',
  production: false,
  configPath: '',
  imageUrl: '',
  host: '',
  recaptchaSiteKey: '',
  requestSource: '',
  requestAction: '',
  wlClient: '',
  localCms: true,
};
