export enum CmsPage {
  CheckoutPayment = 'dgx-forms-checkout-payment',
  Landing = 'randc-landing-page',
  DirectDebitGuarantee = 'dgx-direct-debit-guarantee',
  QuoteLanding = 'quote-page',
}

export interface CmsDataResolverConfig {
  url: string;
  localFile: string;
  id: string;
  mapper?: (...args: never[]) => unknown;
}

export interface CmsResult {
  results: {
    '@name': string;
    '@path': string;
    '@id': string;
    '@nodeType': string;
    paragraphReferences: CmsParagraphReferences[];
    policyFeaturesReferences?: CmsPolicyFeaturesReferences[];
    validationMessages?: CmsValidationMessages;
    jcrName: string;
    formReferences: unknown[];
    '@nodes': unknown[];
  }[];
}

export interface CmsFormResult {
  results: CmsFormControl[];
}

export interface CmsFormControl {
  '@name': string;
  '@path': string;
  '@id': string;
  '@nodeType': string;
  formName: string;
  label?: string;
  controlName: string;
  application: string;
  jcrName: string;
  validationMessages: CmsValidationMessages;
  '@nodes': string[];
}

export interface CmsParagraphReferences {
  '@name'?: string;
  '@path'?: string;
  '@id'?: string;
  '@nodeType'?: string;
  title: string;
  paragraphContent: string;
  jcrName?: string;
  '@nodes'?: unknown[];
}

export interface CmsPolicyFeaturesReferences {
  '@name': string;
  '@path': string;
  '@id': string;
  '@nodeType': string;
  included: string[];
  ipidAsset: string;
  excluded: string[];
  jcrName: string;
  '@nodes': undefined[];
}

export interface CmsValidationMessages {
  '0': {
    '@name': string;
    '@path': string;
    '@id': string;
    '@nodeType': string;
    type: string;
    message: string;
    '@nodes': undefined[];
  };
  '@name': string;
  '@path': string;
  '@id': string;
  '@nodeType': string;
  '@nodes': string[];
}

export interface ParagraphContentCms {
  paragraphReferences: CmsParagraphReferences[];
}
