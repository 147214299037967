export interface DirectDebit {
  accountName: string;
  accountNumber: string;
  sortCode: string;
  monthlyPaymentDay: number;
}

export interface PersonalAddress {
  line1: string;
  line2: string;
  city: string;
  county: string;
  postcode: string;
}

export interface PersonalDetails {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
}

export enum PersonalDetailsFormField {
  Title = 'title',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  MobileNumber = 'mobileNumber',
  Payment = 'payment',
  Address = 'address',
}

export interface PersonalDetailsFormSubmitType {
  [PersonalDetailsFormField.Title]: string;
  [PersonalDetailsFormField.FirstName]: string;
  [PersonalDetailsFormField.LastName]: string;
  [PersonalDetailsFormField.Email]: string;
  [PersonalDetailsFormField.MobileNumber]: string;
  [PersonalDetailsFormField.Payment]: DirectDebit;
  [PersonalDetailsFormField.Address]: PersonalAddress;
}
