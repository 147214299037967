<div class="plain-card-container appliance-details-card">
  <h2>Appliance details</h2>
  <ul class="list list__round-bullet">
    <li id="faultsDescription" class="list__item list__item__round-bullet">
      {{ faultsDescription }}
    </li>
    <li id="applianceAge" class="list__item list__item__round-bullet">
      {{ applianceAge }}
    </li>
    <li id="model" class="list__item list__item__round-bullet" *ngIf="model">
      Model number {{ model }}
    </li>
  </ul>
</div>
