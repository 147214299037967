<div class="container text--center">
  <div class="xs-12">
    <h2 class="mb-2">
      {{ data?.title }}
    </h2>
    <div [innerHtml]="data?.description" class="content"></div>
    <div>
      <ui-button variant="primary-inverted" (click)="bookARepairClick.emit()">{{
        buttonText
      }}</ui-button>
    </div>
  </div>
</div>
