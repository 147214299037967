import { Component, Input } from '@angular/core';

@Component({
  selector: 'randc-label',
  templateUrl: './label.component.html',
})
export class LabelComponent {
  @Input() idPrefix = 'lb';
  @Input() name = 'lb';
  @Input() forElement: any;
  @Input() label!: string;
}
