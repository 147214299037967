import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CmsTitleDescImageItem } from '@common/util-base';

export interface BannerStats {
  unitValue?: string;
  unitText?: string;
}
@Component({
  selector: 'randc-stats-banner',
  templateUrl: './stats-banner.component.html',
  styleUrls: ['./stats-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsBannerComponent {
  @Input() title!: string;
  @Input() set customerEngagementItems(value: CmsTitleDescImageItem[]) {
    this.firstRowItems = value.slice(0, 2).map((item) => this.transformItem(item));
    this.secondRowItems = value.slice(-2).map((item) => this.transformItem(item));
  }

  public firstRowItems!: (CmsTitleDescImageItem & BannerStats)[];
  public secondRowItems!: (CmsTitleDescImageItem & BannerStats)[];

  private transformItem(item: CmsTitleDescImageItem) {
    let unitText = item.title;
    let unitValue = '';

    const reg = new RegExp('^[0-9]*$');

    if (reg.test(item.title)) {
      const value = parseInt(item.title);
      unitText = '';
      unitValue = value.toFixed(0);
      if (value >= 10000 && value <= 999999) {
        unitValue = (value / 1000).toFixed(2);
        unitText = 'thousand';
      } else if (value >= 1000000) {
        unitValue = (value / 1000000).toFixed(2);
        unitText = 'million';
      }
    }

    return {
      ...item,
      unitValue,
      unitText,
    };
  }
}
