import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CmsDataResolverConfig } from '@common/util-base';
import { Observable } from 'rxjs';
import { CmsDataService } from './cms-data.service';

@Injectable({
  providedIn: 'root',
})
export class CmsDataResolver implements Resolve<Observable<unknown> | null> {
  constructor(private cmsDataService: CmsDataService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<unknown> | null {
    return route.data?.cmsDataConfig
      ? this.cmsDataService.getCmsData(route.data?.cmsDataConfig as CmsDataResolverConfig)
      : null;
  }
}
