<h2 class="text--center mb-1">Frequently asked questions</h2>
<p class="text--center mb-2">We’re here to help</p>

<div class="xs-12 sm-10 faq-wrapper">
  <div *ngFor="let faq of faqDetails">
    <randc-faq-item
      question="{{ faq.question }}"
      answer="{{ faq.answer }}"
      (handleAnalytics)="handleAnalytics($event)"
    >
    </randc-faq-item>
  </div>
</div>

<p class="text--center my-1">Need more information?</p>
<p class="text--center">
  <a class="link" name="faq" [href]="buildConfig.config.faqLink" target="_blank"
    >View all our frequently asked questions</a
  >
</p>
