import { Component, Inject } from '@angular/core';
import { BuildConfig, BuildConfigService, WINDOW } from '@common/util-base';
import { NavigationLink, NavigationLinks } from '@domgen/dgx-fe-business-models';
import { isScullyRunning } from '@scullyio/ng-lib';
import { AnalyticsService } from '@common/util-base';

@Component({
  selector: 'ao-app-root',
  templateUrl: './ao-app.component.html',
  styleUrls: ['./ao-app.component.scss'],
})
export class AoAppComponent {
  config: BuildConfig = this.buildConfigService.config;
  headerNavigationLinks: NavigationLinks | undefined = this.config.header;

  footerProtectLinks: NavigationLink[] =
    this.config.footer?.links.filter(({ section }) => section === 'protect') || [];
  footerUsefulLinks: NavigationLink[] =
    this.config.footer?.links.filter(({ section }) => section === 'useful') || [];
  footerLegalLinks: NavigationLink[] =
    this.config.footer?.links.filter(({ section }) => section === 'legal') || [];

  constructor(
    public readonly buildConfigService: BuildConfigService,
    private _analyticsService: AnalyticsService,
    @Inject(WINDOW) private window: Window
  ) {
    if (isScullyRunning()) {
      window.document.body.classList.remove('webp', 'no-webp');
      window.document.body.classList.add('no-js');
    }
  }

  handleHeaderLinkClicked({ link }: { link: string }) {
    this._analyticsService.push({
      event: 'gaEvent',
      event_category: 'Header',
      event_action: 'Interaction',
      event_label: link,
    });
  }

  handleFooterLinkClicked({ link }: { link: string }) {
    this._analyticsService.push({
      event: 'gaEvent',
      event_category: 'Footer',
      event_action: 'Interaction',
      event_label: link,
    });
  }
}
