import { createAction, props } from '@ngrx/store';
import { ApplianceBrandDetails, ApplianceTypeDetails } from '../models';

export const APPLIANCE_DETAILS_INIT = '[Appliance Details] Init';

//get All Appliance Types (Washing Machine, Dishwasher, etc)
export const GET_ALL_APPLIANCE_TYPES = '[Appliance Details] Get All Appliance Types';
export const GET_ALL_APPLIANCE_TYPES_SUCCESS =
  '[Appliance Details] Get All Appliance Types Success';
export const GET_ALL_APPLIANCE_TYPES_FAIL = '[Appliance Details] Get All Appliance Types Fail';

//get All Appliance Brands (Hoover, Bosch, etc)
export const GET_ALL_APPLIANCE_BRANDS = '[Appliance Details] Get All Appliance Brands';
export const GET_ALL_APPLIANCE_BRANDS_SUCCESS =
  '[Appliance Details] Get All Appliance Brands Success';
export const GET_ALL_APPLIANCE_BRANDS_FAIL = '[Appliance Details] Get All Appliance Brands Fail';

//get All Appliance Brands for specific Appliance Types (Bosch, Miele, etc--> Dishwasher)
export const GET_APPLIANCE_BRANDS_BY_TYPE = '[Appliance Details] Get Appliance Brands by type';
export const GET_APPLIANCE_BRANDS_BY_TYPE_SUCCESS =
  '[Appliance Details] Get Appliance Brands by type Success';
export const GET_APPLIANCE_BRANDS_BY_TYPE_FAIL =
  '[Appliance Details] Get Appliance Brands by type Fail';

//get All Appliance Types for specific Appliance brand (Dishwasher, Washing Machine --> Bosch)
export const GET_APPLIANCE_TYPES_BY_BRAND = '[Appliance Details] Get Appliance Types by brand';
export const GET_APPLIANCE_TYPES_BY_BRAND_SUCCESS =
  '[Appliance Details] Get Appliance Types by brand Success';
export const GET_APPLIANCE_TYPES_BY_BRAND_FAIL =
  '[Appliance Details] Get Appliance Types by brand Fail';

export const Init = createAction(APPLIANCE_DETAILS_INIT);
export const GetAllApplianceTypes = createAction(GET_ALL_APPLIANCE_TYPES);

export const GetAllApplianceTypesSuccess = createAction(
  GET_ALL_APPLIANCE_TYPES_SUCCESS,
  props<{ payload: ApplianceTypeDetails[] }>()
);

export const GetAllApplianceTypesFail = createAction(
  GET_ALL_APPLIANCE_TYPES_FAIL,
  props<{ payload: any }>()
);

export const GetAllApplianceBrands = createAction(GET_ALL_APPLIANCE_BRANDS);

export const GetAllApplianceBrandsSuccess = createAction(
  GET_ALL_APPLIANCE_BRANDS_SUCCESS,
  props<{ payload: ApplianceBrandDetails[] }>()
);

export const GetAllApplianceBrandsFail = createAction(
  GET_ALL_APPLIANCE_BRANDS_FAIL,
  props<{ payload: any }>()
);

export const GetApplianceBrandsByType = createAction(
  GET_APPLIANCE_BRANDS_BY_TYPE,
  props<{ payload: { selectedApplianceType: string } }>()
);

export const GetApplianceBrandsByTypeSuccess = createAction(
  GET_APPLIANCE_BRANDS_BY_TYPE_SUCCESS,
  props<{ payload: ApplianceBrandDetails[]; selectedApplianceType: string }>()
);

export const GetApplianceBrandsByTypeFail = createAction(
  GET_APPLIANCE_BRANDS_BY_TYPE_FAIL,
  props<{ payload: any }>()
);

export const GetApplianceTypesbyBrand = createAction(
  GET_APPLIANCE_TYPES_BY_BRAND,
  props<{ payload: { selectedApplianceBrand: string } }>()
);

export const GetApplianceTypesbyBrandSuccess = createAction(
  GET_APPLIANCE_TYPES_BY_BRAND_SUCCESS,
  props<{ payload: ApplianceTypeDetails[]; selectedApplianceBrand: string }>()
);

export const GetApplianceTypesbyBrandFail = createAction(
  GET_APPLIANCE_TYPES_BY_BRAND_FAIL,
  props<{ payload: any }>()
);

export type ApplianceDetailsActions =
  | typeof Init
  | typeof GetAllApplianceTypes
  | typeof GetAllApplianceTypesSuccess
  | typeof GetAllApplianceTypesFail
  | typeof GetAllApplianceBrands
  | typeof GetAllApplianceBrandsSuccess
  | typeof GetAllApplianceBrandsFail;
