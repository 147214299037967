import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core'; // to be able to style dynamially generated HTML content
// See more details at https://github.com/angular/angular/issues/7845#issuecomment-302326549
import { AnalyticsService, UtilService } from '@common/util-base';
import { ModelSearchService } from '@common/data-access-quote';

@Component({
  selector: 'randc-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputAutoCompleteComponent implements OnInit, OnChanges {
  @Input() controlName = 'applianceBrand';
  @Input() group!: FormGroup;
  @Input() item: any;
  @Input() maxLength: any;
  @Input() label: any;
  @Input() hint: any;
  @Input() labelFor: any;
  @Input() showValidation: any;
  @Input() validators: any;
  @Input() results: any;
  @Input() triggerLookUpCheck!: boolean;
  @Input() filterField = 'name'; // which field name to use when data is stored in a object
  @Input() minLength = 1; // minimum number of characters that user needs to type before matching options can be shown
  @Input() tooltip: any;
  @Input() isloading = false;

  @Output() itemSelect: EventEmitter<any> = new EventEmitter();
  @Output() keyupEvent: EventEmitter<any> = new EventEmitter();

  public id: any;
  public lookupMatch = false;
  public lookupMatchValue = '';
  public validationClass!: string;
  public filteredResults!: Array<any>;
  public query!: string;
  private _element: any;
  filteredItems!: any[];
  isFocused = false;
  showTooltip = false;

  constructor(
    // private _cdref: ChangeDetectorRef,
    private _utilService: UtilService,
    private _modelSearchService: ModelSearchService,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    if (this.group) {
      this._element = this.group.get(this.item.control);
      const control = this.group.controls[this.controlName];
      const brandControl = this.group.controls.applianceBrand;
      if (brandControl) {
        this._element.disable();
      }
      if (this.item.value) {
        this.filteredResults = this.results;
        if (this.filteredResults) {
          this.filterItemsByCode(this.item.value, control);
          // reset validators
          control.clearValidators();
          // add a validator to the input to check that user enters a valid (existing) value
          control.setValidators([
            Validators.required,
            this._utilService.isOnTheListValidator(
              this.filteredResults.map((r: { [key: string]: any }) =>
                r[this.filterField].toLowerCase()
              )
            ), // new validator
          ]);
          control.updateValueAndValidity();
          // Conditionally call lookUpCheck
          if (this.triggerLookUpCheck) {
            this.lookUpCheck();
          }
        }
      }
    }
  }

  ngOnChanges() {
    const brandControl = this.group?.controls?.applianceBrand;
    // Conditionally call lookUpCheck
    if (this.triggerLookUpCheck) {
      this.lookUpCheck();
    }

    this.filteredResults =
      this.results && this.results.length
        ? this.results.filter((r: any) => {
            return true;
          })
        : [];

    if (brandControl) {
      if (this.filteredResults && this.filteredResults.length && this._element) {
        brandControl.enable();

        // reset validators
        brandControl.clearValidators();

        // add a validator to the input to check that user enters a valid (existing) value
        brandControl.setValidators([
          Validators.required,
          Validators.minLength(this.minLength),
          this._utilService.isOnTheListValidator(
            this.filteredResults.map((r) => r[this.filterField].toLowerCase()),
            this.filterField
          ), // new validator
        ]);

        brandControl.updateValueAndValidity();
      } else {
        if (this._element) {
          brandControl.disable();
        }
      }
    }
  }

  // To avoid having 'expression has changed after it was checked' error
  // https://stackoverflow.com/a/45467987/662084
  // ngAfterContentChecked(): void {
  //   this._cdref.detectChanges();
  // }

  public getPreMatch(option: any) {
    if (!this.query) {
      return option;
    }

    const textLabel = option[this.filterField];
    const preMatch = textLabel.substring(
      0,
      textLabel.toLowerCase().indexOf(this.query.toLowerCase())
    );

    return preMatch;
  }

  public getMatch(option: any) {
    if (!option || !this.query) {
      return '';
    }

    const textLabel = option[this.filterField];
    const queryLC = this.query ? this.query.toLowerCase() : '';
    const optionLC = textLabel ? textLabel.toLowerCase() : '';

    const match = textLabel.substring(
      optionLC.indexOf(queryLC),
      optionLC.indexOf(queryLC) + this.query.length
    );

    return match;
  }

  public getPostMatch(option: any) {
    if (!option || !this.query) {
      return '';
    }

    const textLabel = option[this.filterField];

    return textLabel.substring(
      textLabel.toLowerCase().indexOf(this.query.toLowerCase()) + this.query.length
    );
  }

  filterItemsByCode(itemCode: any, control: any) {
    this.filteredItems = [];
    if (this.results && this.results.length) {
      this.results
        .filter((item: any) => item.code && item.code.toLowerCase() === itemCode.toLowerCase())
        .map((value: any) => control.setValue(value));
    }
  }

  filterItems(event: any) {
    // for Model Number field a list of options needs to be fetched from the API
    // every time a character is entered (at least 3 characters are needed).
    if (this.controlName === 'modelNumberAutoComplete' && event.query && event.query.length > 2) {
      this.isloading = true;
      this._modelSearchService.getModelNumbers(event.query).subscribe((models) => {
        this.filteredItems = models;
        this.isloading = false;
      });
    } else if (this.results && this.results.length) {
      this.filteredItems = [];
      for (let i = 0; i < this.results.length; i++) {
        const item = this.results[i];
        if (
          item[this.filterField] &&
          event.query &&
          item[this.filterField] &&
          item[this.filterField].toLowerCase().indexOf(event.query.toLowerCase()) > -1
        ) {
          this.filteredItems.push(item);
        }
      }
    }
  }

  public handleOnFocus() {
    this.showValidation = false;
    this.isFocused = true;
  }

  /**
   * handleOnBlur
   * used on API lookup field ONLY (triggerLookUpCheck boolean Input)
   * 1. replaces the field with the matched API result on blur
   * This is in case the user DOES NOT select a dropdown but the values match and just clicks out of the field
   * e.g user types dgh2n and the api returns DGH2N - on blur field with be replaced with DGH2N
   */
  public handleOnBlur() {
    // 1.
    // if (this.triggerLookUpCheck && this.lookupMatchValue && this.lookupMatch) {
    //   this.group.get(this.item.control).patchValue(this.lookupMatchValue);
    // }
    this.isFocused = false;
    this.lookUpCheck();
    this.showValid();
    this._analyticsService.triggerInputEvent(this.item.control, this.group);
  }

  /**
   * lookingUpCheck
   * checks the responses against the current value in the input
   * 1. loop through the results and return boolean and value
   * setValue() function has conditional logic based on this.lookupMatch
   * 2. call validation passing in true for setUndefined - switches off validation while the user types
   * @param event
   */
  public lookUpCheck(): void {
    if (this.results === undefined) {
      return;
    }

    const control = this.group.get(this.item.control);
    let val: any = null;

    if (control && control.value) {
      if (typeof control.value === 'string') {
        // if user types in a brand themselves then input value is a string (e.g. 'Ariston')
        val = control.value ? control.value.toLowerCase() : '';
      } else if (
        control.value &&
        typeof control.value === 'object' &&
        control.value[this.filterField]
      ) {
        // if user selects a brand from the list of suggestions then value is an object (e.g. {code: "AMIC", name: "Amica", popular: false})
        val = control.value[this.filterField].toLowerCase();
      }
    }

    if (this.filteredItems && this.filteredItems.length) {
      this.filteredItems.map((item) => {
        if (item[this.filterField].toLowerCase() === val) {
          this.lookupMatchValue = val;
        }
      });
    }

    if (!this.lookupMatchValue && control?.dirty) {
      control.setErrors({ incorrect: true });
      control.markAsTouched();
      this.showValidation = true;

      control.updateValueAndValidity();
    }

    this.isFocused = false;
  }

  /**
   * selectItem
   * @param event
   * emits the input value and query to parent element
   * conditional passes in event.query or query.
   * this is for input over select field setup
   */
  public selectItem(event: any) {
    this.itemSelect.emit({
      q: event.query === undefined ? event : event.query,
      api: this.item.control,
    });
    this.lookupMatch = true;
    this.lookUpCheck();
    // enable postcode field once a brand has been selected
    if (this.group.controls.postcode) {
      this.group.controls.postcode.enable();
    }
  }

  /**
   * keyUp
   * @param event
   * listens for keyUp and emit the value back to the parent
   */
  public keyUp(event: any) {
    this.query = event.target.value;

    this.filteredResults =
      this.results && this.results.length
        ? this.results.filter((r: any) => {
            if (r[this.filterField]) {
              return r[this.filterField].toLowerCase().indexOf(this.query) > -1;
            }
            return;
          })
        : [];

    this.keyupEvent.emit({
      q: event.target.value,
      category: this.item.DataCategory,
      type: this.item.DataType,
    });

    // if user leaves the input field blank mark form as invalid
    if (this.query.length === 0 && !this.isFocused) {
      this.group.controls[this.controlName].markAsTouched(); // reset errors
      this.showValid();
    }
  }

  public showValid() {
    this.showValidation = true;
    if (this.group.controls.postcode) {
      this.group.controls.postcode.enable();
    }
  }

  public toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }
}
