import { Component, Input } from '@angular/core';
import {
  ApplianceDetails,
  PlanDetails,
  PlanPaymentDetails,
  Quote,
  DATE_FORMAT_DAY_MONTH_LONG,
  DATE_FORMAT_DAY_ONLY,
} from '@common/util-base';

@Component({
  selector: 'randc-spa-plan-details-panel',
  templateUrl: './plan-details-panel.component.html',
  styleUrls: ['./plan-details-panel.component.scss'],
})
export class PlanDetailsPanelComponent {
  paymentMethod = '';
  // the year doesn't really matter, we just need to make sure it's a month with 31 days in
  // we are using a date pipe to render just the day
  monthlyPaymentDate = new Date(2022, 1, 1);
  @Input() applianceDetails: ApplianceDetails | null = null;
  @Input() planDetails: PlanDetails | null = null;
  @Input() set planPaymentDetails(paymentDetails: PlanPaymentDetails | null) {
    this.paymentMethod = paymentDetails?.paymentMethod || '';
    this.monthlyPaymentDate.setDate(paymentDetails?.monthlyPaymentDate || 1);
  }
  @Input() rate: Quote | null = null;
  @Input() repairDate: Date | null = null;
  @Input() repairTime: string | null = null;
  @Input() repairOneOff: boolean | null = false;

  public dateFormat: string = DATE_FORMAT_DAY_MONTH_LONG;
  public dayOnly: string = DATE_FORMAT_DAY_ONLY;
}
