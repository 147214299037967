import {
  ControlType,
  FieldDef,
  SelectDef,
  ValidationMessage,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { OptionItem } from '@domgen/dgx-fe-dynamic-form-builder/lib/_shared/interfaces/dynamic-formbuilder.interface';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormAnalyticsHelper {
  //todo: improve types here
  public getValidationErrorMessages<F = Record<string, FieldDef>>(
    formGroup: FormGroup,
    fieldDefs: F
  ) {
    const fieldNames = Object.keys(fieldDefs) as (keyof F)[];
    return fieldNames.reduce<string[]>((agg, key) => {
      const fieldErrors = formGroup.get(key as string)?.errors;
      if (!fieldErrors) {
        return agg;
      }
      const errorTypes = Object.keys(fieldErrors).filter((e) => !!e);
      const fieldDef = fieldDefs[key];

      const errorMessages = errorTypes.map((keyError) =>
        this.getErrorMessage((fieldDef as unknown) as FieldDef, keyError)
      );
      return [...agg, ...errorMessages];
    }, []);
  }

  private getErrorMessage(fieldDef: FieldDef, type: string) {
    if (!('validationMessages' in fieldDef)) {
      //we dont have validation on submit button so should not happen - but this keeps typescript happy
      return type;
    }
    return (
      fieldDef.validationMessages?.find((vm: ValidationMessage) => vm.type === type)?.message ||
      type
    );
  }

  public async getValueForField(fieldDef: FieldDef, value: unknown) {
    if (fieldDef.controlType !== ControlType.SELECT) {
      return value;
    }
    const selectDef = fieldDef as SelectDef;
    //options can be array of strings or observable stream
    const options: (string | OptionItem)[] =
      selectDef.options || (await selectDef.optionsStream$?.pipe(take(1)).toPromise()) || [];

    const selectedOption = options.find((option) => {
      //options can be simple array of strings
      if (typeof option === 'string') {
        return option === value;
      }
      //or they can be value/label pairs
      return (option as OptionItem).value === value;
    });
    if (typeof selectedOption === 'string') {
      //options can be simple array of strings
      return selectedOption;
    }
    //or they can be value/label pairs
    return selectedOption?.label || value;
  }
}
