export enum TrustBox {
  MICRO = 'micro',
  SLIDER = 'slider',
}

export interface TrustBoxTemplate {
  id: string;
  name: TrustBox;
}

export interface TrustBoxConfig {
  businessUnitId: string;
  templates: TrustBoxTemplate[];
}

export const TRUSTBOX_DEFAULT_TITLE = 'Trusted in over 7.7 million homes across the UK';
