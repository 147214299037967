import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(private recaptchaV3Service: ReCaptchaV3Service) {}

  shouldUrlBeSkipped(reqUrl: string): boolean {
    const ignoreUrls = [
      '/randc-landing-page',
      '/randc-combine-content-app',
      '/calendar/availability-start-session',
      '/delivery/dgx-forms',
    ];

    return !!ignoreUrls.filter((url) => {
      return reqUrl.indexOf(url) >= 0;
    }).length;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.recaptchaV3Service.execute('getToken').pipe(
      mergeMap((token: string) => {
        if (this.shouldUrlBeSkipped(req.url)) {
          return next.handle(req);
        } else {
          const newHeaders = req.headers.append('x-recaptcha-token', token);
          const newReq = req.clone({ headers: newHeaders });
          return next.handle(newReq);
        }
      })
    );
  }
}
