export const mockPopularAppliances = [
  {
    code: 'EC',
    name: 'Electric Cooker',
    iconURL: 'assets/images/icons/appliance/EC.png',
    heroURL: 'undefinedECundefined',
    popular: true,
  },
  {
    code: 'GC',
    name: 'Gas Cooker',
    iconURL: 'assets/images/icons/appliance/GC.png',
    heroURL: 'undefinedGCundefined',
    popular: false,
  },
  {
    code: 'DW',
    name: 'Dishwasher',
    iconURL: 'assets/images/icons/appliance/DW.png',
    heroURL: 'undefinedDWundefined',
    popular: true,
  },
  {
    code: 'TD',
    name: 'Tumble Dryer',
    iconURL: 'assets/images/icons/appliance/TD.png',
    heroURL: 'undefinedTDundefined',
    popular: false,
  },
  {
    code: 'FZ',
    name: 'Freezer',
    iconURL: 'assets/images/icons/appliance/FZ.png',
    heroURL: 'undefinedFZundefined',
    popular: true,
  },
  {
    code: 'FR',
    name: 'Fridge',
    iconURL: 'assets/images/icons/appliance/FR.png',
    heroURL: 'undefinedFRundefined',
    popular: false,
  },
  {
    code: 'FF',
    name: 'Fridge Freezer',
    iconURL: 'assets/images/icons/appliance/FF.png',
    heroURL: 'undefinedFFundefined',
    popular: true,
  },
  {
    code: 'EH',
    name: 'Electric Hob',
    iconURL: 'assets/images/icons/appliance/EH.png',
    heroURL: 'undefinedEHundefined',
    popular: false,
  },
  {
    code: 'GH',
    name: 'Gas Hob',
    iconURL: 'assets/images/icons/appliance/GH.png',
    heroURL: 'undefinedGHundefined',
    popular: true,
  },
  {
    code: 'EO',
    name: 'Electric Oven',
    iconURL: 'assets/images/icons/appliance/EO.png',
    heroURL: 'undefinedEOundefined',
    popular: true,
  },
  {
    code: 'OC',
    name: 'Gas Oven',
    iconURL: 'assets/images/icons/appliance/OC.png',
    heroURL: 'undefinedOCundefined',
    popular: true,
  },
  {
    code: 'AW',
    name: 'Washing Machine',
    iconURL: 'assets/images/icons/appliance/AW.png',
    heroURL: 'undefinedAWundefined',
    popular: true,
  },
];
