import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'randc-appliance-details-card',
  templateUrl: './appliance-details-card.component.html',
  styleUrls: ['./appliance-details-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplianceDetailsCardComponent {
  @Input() public faultsDescription: any;
  @Input() public applianceAge: any;
  @Input() public model: any;
}
