<div class="container">
  <h2 class="text--center mb-2">{{ title }}</h2>
  <div class="lg-row md-stack">
    <div *ngFor="let apItem of applianceProtectionItems" class="lg-6 md-12 ap-container">
      <div
        class="ap-image"
        [ngStyle]="{
          'background-image': 'url(' + imageUrl + apItem.imageDesktop['@link'] + ')'
        }"
      ></div>
      <div class="ap-text-container">
        <h3>{{ apItem.title }}</h3>
        <div class="description" [innerHtml]="apItem.description"></div>
      </div>
    </div>
  </div>
</div>
