import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CmsTitleDescImageItem } from '@common/util-base';

@Component({
  selector: 'randc-plan-banner',
  templateUrl: './plan-banner.component.html',
  styleUrls: ['./plan-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanBannerComponent {
  @Input() planTitle!: string;
  @Input() planSubTitle!: string;
  @Input() planItems!: CmsTitleDescImageItem[];
  @Input() imageUrl!: string;
  @Input() isEasySteps = false;
  @Output() handleBookARepairClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  onBookARepairButtonClick() {
    this.handleBookARepairClick.emit(true);
  }
}
