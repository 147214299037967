import { Component, Input } from '@angular/core';
import { ApplianceDetails, Quote, DATE_FORMAT_DAY_MONTH_LONG } from '@common/util-base';

@Component({
  selector: 'randc-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss'],
})
export class QuoteSummaryComponent {
  @Input() public title = '';
  @Input() public bodyText = '';
  @Input() public quote: Quote | null = null;
  @Input() public applianceDetails: ApplianceDetails | null = null;
  @Input() public repairDate!: Date | null;
  @Input() public repairTimeslot: string | null = null;
  @Input() public basic = false;

  public dateFormat: string = DATE_FORMAT_DAY_MONTH_LONG;
}
