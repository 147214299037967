/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import {
  DirectDebitUiModule,
  MarketingPreferencesModule,
} from '@domgen/dgx-fe-business-components';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { DateWithOrdinalPipe, KeepHtmlPipe } from '@common/util-base';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AcceptComponent } from './accept/accept.component';
import { ApplianceProtectionBannerComponent } from './appliance-protection-banner/appliance-protection-banner.component';
import { ApplianceDetailsLandingFormComponent } from './appliance-details-landing-form/appliance-details-landing-form.component';
import { ApplianceDetailsCardComponent } from './applicance-details-card/appliance-details-card.component';
import { BannerComponent } from './banner/banner.component';
import { BrandLogoBannerComponent } from './brand-logo-banner/brand-logo-banner.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CmsContentComponent } from './cms-content/cms-content.component';
import { CustomerDetailsCardComponent } from './customer-details-card/customer-details-card.component';
import { CustomerDetailsFormComponent } from './customer-details-form/customer-details-form.component';
import { CustomerEngagementBannerComponent } from './customer-engagement-banner/customer-engagement-banner.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { EngineerReservedCardComponent } from './engineer-reserved-card/engineer-reserved-card.component';
import { ErrorBannerComponent } from './error-banner/error-banner.component';
import { ErrorLineComponent } from './error-line/error-line.component';
import { FaqItemComponent } from './faq-item/faq-item.component';
import * as formBuilderComponents from './formbuilder';
import { GotQuestionBannerComponent } from './got-question-banner/got-question-banner.component';
import { HeroComponent } from './hero/hero.component';
import { HeroBannerComponent } from './hero-banner/hero-banner.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LandingPageFaqsComponent } from './landing-page-faqs/landing-page-faqs.component';
import { MyAccountBannerComponent } from './my-account-banner/my-account-banner.component';
import { OneOffRepairsComponent } from './one-off-repairs/one-off-repairs.component';
import { OurPartnersComponent } from './our-partners/our-partners.component';
import { ParagraphContentComponent } from './paragraph-content/paragraph-content.component';
import { PhoneCallsDisclaimerComponent } from './phone-calls-disclaimer/phone-calls-disclaimer.component';
import { PlanBannerComponent } from './plan-banner/plan-banner.component';
import { PlanDocumentsModule } from './plan-documents/plan-documents.module';
import { RepairCarePlanCardComponent } from './repair-care-plan-card/repair-care-plan-card.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TrustboxComponent } from './trustbox/trustbox.component';
import { UserIdleTimeoutComponent } from './user-idle-timeout/user-idle-timeout.component';
import { ThingsNeedToKnowOrCancellationComponent } from './things-need-to-know-or-cancellation/things-need-to-know-or-cancellation.component';
import { QuoteSummaryComponent } from './quote-summary/quote-summary.component';
import { PanelComponentsModule } from '@domgen/dgx-fe-base-components';
import { BookRepairFormComponent } from './book-repair-form/book-repair-form.component';
import { BackOnTrackComponent } from './back-on-track/back-on-track.component';
import { ExpertRepairComponent } from './expert-repair/expert-repair.component';
import { StatsBannerComponent } from './stats-banner/stats-banner.component';
import { CustomerTestimonialComponent } from './customer-testimonial/customer-testimonial.component';
import { WhatIsRepairComponent } from './what-is-repair/what-is-repair.component';
import { HowItWorksNewComponent } from './how-it-works-new/how-it-works-new.component';
import { ApplianceDetailsSummaryCardComponent } from './appliance-details-summary-card/appliance-details-summary-card.component';

const components = [
  ...formBuilderComponents.components,
  CustomerDetailsCardComponent,
  MyAccountBannerComponent,
  GotQuestionBannerComponent,
  ApplianceDetailsCardComponent,
  RepairCarePlanCardComponent,
  CustomerSupportComponent,
  DateWithOrdinalPipe,
  CustomerDetailsFormComponent,
  PlanBannerComponent,
  ParagraphContentComponent,
  TrustboxComponent,
  FaqItemComponent,
  KeepHtmlPipe,
  ApplianceProtectionBannerComponent,
  CustomerEngagementBannerComponent,
  BrandLogoBannerComponent,
  CarouselComponent,
  CalendarComponent,
  BannerComponent,
  AcceptComponent,
  CmsContentComponent,
  OneOffRepairsComponent,
  UserIdleTimeoutComponent,
  TimePickerComponent,
  ErrorBannerComponent,
  PhoneCallsDisclaimerComponent,
  ApplianceDetailsCardComponent,
  ErrorLineComponent,
  HeroComponent,
  HeroBannerComponent,
  HowItWorksComponent,
  LandingPageFaqsComponent,
  BookRepairFormComponent,
  OurPartnersComponent,
  EngineerReservedCardComponent,
  ApplianceDetailsLandingFormComponent,
  ThingsNeedToKnowOrCancellationComponent,
  QuoteSummaryComponent,
  BookRepairFormComponent,
  BackOnTrackComponent,
  ExpertRepairComponent,
  StatsBannerComponent,
  CustomerTestimonialComponent,
  WhatIsRepairComponent,
  HowItWorksNewComponent,
  ApplianceDetailsSummaryCardComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AutoCompleteModule,
    SharedUiDynamicFormBuilderModule,
    RouterModule,
    PlanDocumentsModule,
    DirectDebitUiModule,
    MarketingPreferencesModule,
    SharedUiBaseComponentsModule,
    PanelComponentsModule,
  ],
  providers: [DatePipe, DateWithOrdinalPipe],
  declarations: [...components],
  exports: [...components],
})
export class RandcUiModule {}
