import { CalendarDay, CalendarNextAvailability, CalendarSlot } from '../models/calendar.types';

export class CalendarFunctions {
  static getNextAvailableDateAndId(calendarDays: CalendarDay[]): CalendarNextAvailability {
    let nextAvailability: CalendarNextAvailability = {
      nextAvailableDate: new Date(),
      nextAvailableId: 0,
    };
    for (let i = 0; i < calendarDays.length; i++) {
      const calendarDay = calendarDays[i];
      if (calendarDay.slots && calendarDay.slots.find((slot: CalendarSlot) => slot.available)) {
        nextAvailability = { nextAvailableDate: calendarDay.date, nextAvailableId: i };
        break;
      }
    }
    return nextAvailability;
  }
}
