import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class PostCodeMismatchValidator extends Validators {
  static isPostCodeMatch(postCode: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c && c.value && c.parent && postCode) {
        const controlValue: string = c.parent.get('address') ? c.value.postcode : c.value;
        if (
          controlValue &&
          controlValue.replace(/\s/g, '').toUpperCase() !==
            postCode.replace(/\s/g, '').toUpperCase()
        ) {
          return {
            isPostCodeMismatch: true,
          };
        }
      }
      return null;
    };
  }
}
