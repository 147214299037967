<div class="calendar sales" *ngIf="calendarState.vm$ | async as vm">
  <div class="calendar__month-in-view">{{ vm.monthInView }}</div>
  <div class="calendar__view">
    <div class="calendar__day" *ngFor="let day of vm.daysOfTheWeek">{{ day }}</div>
    <div class="calendar__date" *ngFor="let day of vm.calendarDays; let i = index">
      <input
        type="radio"
        [id]="i"
        [attr.data-automation]="'calendar' + i"
        [attr.checked]="day.selected"
        hidden="true"
        aria-hidden="false"
      />
      <div
        class="calendar__date--slot-available"
        (click)="day.slotsAvailable && selectCalendarDate(i)"
        [ngClass]="{
          'calendar__date--no-slot-available': !day.slotsAvailable
        }"
      >
        {{ day?.date | date: 'dd' }}
      </div>
    </div>
  </div>
</div>
