export const CHECKOUT_PAYMENT_FORM = {
  results: [
    {
      '@name': 'paperless',
      '@path': '/randc/checkoutPaymentForm/paperless',
      '@id': '6318d363-14bf-425d-bb4a-a672adad4a47',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'Would you also like to receive a copy of your plan in the post?',
      controlName: 'paperless',
      application: 'repair-and-care',
      jcrName: 'paperless',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/paperless/validationMessages/0',
          '@id': '238410a6-71dd-4649-bbb7-1959005da0bd',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Please select no or yes',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/paperless/validationMessages',
        '@id': 'bf9760f7-c813-4fb4-bd98-6c1c7c7cd8af',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'post',
      '@path': '/randc/checkoutPaymentForm/post',
      '@id': 'c8d84dcd-793e-4162-a5ea-b29386497aeb',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'Post',
      controlName: 'post',
      application: 'repair-and-care',
      jcrName: 'post',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/post/validationMessages/0',
          '@id': '6c7f8d34-dc45-4cf9-a29b-e6a9fefc76d8',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Please indicate if you would like to be contacted by post',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/post/validationMessages',
        '@id': '5e6a64df-3c91-47fd-9000-e11c071182d8',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sortCode',
      '@path': '/randc/checkoutPaymentForm/sortCode',
      '@id': '26d2a349-22af-4f23-a6a8-1856a2291798',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'Sort code',
      controlName: 'sortCode',
      placeholder: 'Enter sort code',
      application: 'repair-and-care',
      jcrName: 'sortCode',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/sortCode/validationMessages/0',
          '@id': 'fad9f018-1dbc-468b-9ccd-a54a65c60f68',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Sort code should contain 6-digits. Please try again.',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/sortCode/validationMessages',
        '@id': '5909ae47-0a36-476b-bc1d-98c400d33579',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'monthlyPaymentDay',
      '@path': '/randc/checkoutPaymentForm/monthlyPaymentDay',
      '@id': 'd615eb2e-16d5-48d7-9c64-4bd32c9b90ce',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'What day would you like to pay each month?',
      controlName: 'monthlyPaymentDay',
      placeholder: 'Select',
      application: 'repair-and-care',
      jcrName: 'monthlyPaymentDay',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/monthlyPaymentDay/validationMessages/0',
          '@id': '8fb43659-fd54-4b7e-95b9-8cac66b105e8',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Payment day is required.',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/monthlyPaymentDay/validationMessages',
        '@id': '6442e518-8d96-4608-9bf7-672e6565678f',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'accountName',
      '@path': '/randc/checkoutPaymentForm/accountName',
      '@id': '764e6640-5939-4cb2-8ecf-a5a46aca3943',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'Name of account holder',
      controlName: 'accountName',
      placeholder: 'Enter name of account holder',
      application: 'repair-and-care',
      jcrName: 'accountName',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/accountName/validationMessages/0',
          '@id': '82486d18-d28b-490e-8125-e59637fc2a2a',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Name of account holder is required. Please complete this field to continue.',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/accountName/validationMessages',
        '@id': 'b1779645-6312-42dc-99f7-0c31a4b3845f',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'accountNumber',
      '@path': '/randc/checkoutPaymentForm/accountNumber',
      '@id': 'e8e777a9-23d7-431f-9a28-c8e06b1f1020',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'Account number',
      controlName: 'accountNumber',
      placeholder: 'Enter account number',
      application: 'repair-and-care',
      jcrName: 'accountNumber',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/accountNumber/validationMessages/0',
          '@id': 'd7fac16a-8631-4293-bc8b-39ef1b985ad1',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Account number should contain 8-digits. Please try again.',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/accountNumber/validationMessages',
        '@id': 'c284433f-e228-4d3a-a357-93f09efa75a3',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'termsAndConditions',
      '@path': '/randc/checkoutPaymentForm/termsAndConditions',
      '@id': 'd60e641d-72f1-4858-8e0f-38434ed54710',
      '@nodeType': 'dgx-form',
      controlName: 'termsAndConditions',
      formName: 'checkoutPaymentForm',
      application: 'repair-and-care',
      jcrName: 'termsAndConditions',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/termsAndConditions/validationMessages/0',
          '@id': 'e29357a1-677c-4207-92bf-564403e32e79',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Please confirm that you have read and agree',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/termsAndConditions/validationMessages',
        '@id': '09474da3-40d9-43e4-943a-935c6f8ba9eb',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'email',
      '@path': '/randc/checkoutPaymentForm/email',
      '@id': 'ec079f52-7fbc-4e35-978d-edb2958e9659',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'Email',
      controlName: 'email',
      application: 'repair-and-care',
      jcrName: 'email',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/email/validationMessages/0',
          '@id': 'dfb62bcc-a70b-4597-8d0e-eb5feba6ea04',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Please indicate if you would like to be contacted by email.',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/email/validationMessages',
        '@id': 'f647a840-6076-40c0-b9ac-3b6375f347e1',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'phone',
      '@path': '/randc/checkoutPaymentForm/phone',
      '@id': '2e0a9b5b-634b-4c40-bfa6-d71b4a740e6d',
      '@nodeType': 'dgx-form',
      formName: 'checkoutPaymentForm',
      label: 'Phone',
      controlName: 'phone',
      application: 'repair-and-care',
      jcrName: 'phone',
      validationMessages: {
        '0': {
          '@name': '0',
          '@path': '/randc/checkoutPaymentForm/phone/validationMessages/0',
          '@id': '4efbb24d-52f3-4b5f-97d2-f7532600d419',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Please indicate if you would like to be contacted by phone',
          '@nodes': [],
        },
        '@name': 'validationMessages',
        '@path': '/randc/checkoutPaymentForm/phone/validationMessages',
        '@id': '85fe106a-7383-448b-8a6a-51ddcaf87767',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
  ],
};
