import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BOOKING_DETAILS_FEATURE_KEY, BookingDetailsState } from './booking-details.reducer';
import {
  AccessDetails,
  ApplianceDetails,
  AppliancePurchaseDetails,
  FaultDetails,
  ModelDetails,
} from '@common/util-base';

export const getBookingDetailsState = createFeatureSelector<BookingDetailsState>(
  BOOKING_DETAILS_FEATURE_KEY
);

export const getModel = createSelector(
  getBookingDetailsState,
  ({ model }): ModelDetails | undefined => model
);
export const getAccess = createSelector(
  getBookingDetailsState,
  ({ access }): AccessDetails | undefined => access
);
export const getFault = createSelector(
  getBookingDetailsState,
  ({ fault }): FaultDetails | undefined => fault
);
export const getAppliancePurchase = createSelector(
  getBookingDetailsState,
  ({ appliancePurchase }): AppliancePurchaseDetails | undefined => appliancePurchase
);
export const getApplianceDetails = createSelector(
  getBookingDetailsState,
  ({ appliance }): ApplianceDetails | undefined => appliance
);
