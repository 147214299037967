export const overlappingNumberRanges = (a: number[], b: number[]) => {
  let counter = 0;

  // loop first time range
  for (let i = a[0]; i < a[1]; i++) {
    // loop second time range
    if (i >= b[0] && i <= b[1]) {
      counter += 1;
    }
  }

  // number count on how many hours of the first range are inside the second range.
  return counter;
};

export const rangeToAdd = (ranges: TimeRange[]) => {
  const validRanges: ValidTimeRange[] = [...ranges.map((x, i) => ({ ...x, i }))];

  for (let i = 0; i < validRanges.length; i++) {
    if (!validRanges[i] || !validRanges[i + 1]) {
      break;
    }

    // First
    const [firstStartHour, firstStartMin] = validRanges[i].startTime.split(':');
    const [firstEndHour, firstEndMin] = validRanges[i].endTime.split(':');

    const firstStart = parseInt(firstStartHour) * 60 + parseInt(firstStartMin);
    const firstEnd = parseInt(firstEndHour) * 60 + parseInt(firstEndMin);

    // Second
    const [secondStartHour, secondStartMin] = validRanges[i + 1].startTime.split(':');
    const [secondEndHour, secondEndMin] = validRanges[i + 1].endTime.split(':');

    const secondStart = parseInt(secondStartHour) * 60 + parseInt(secondStartMin);
    const secondEnd = parseInt(secondEndHour) * 60 + parseInt(secondEndMin);

    // Build objects to work with
    const first = {
      ranges: [firstStart, firstEnd],
      size: Math.abs(firstStart - firstEnd),
    };
    const second = {
      ranges: [secondStart, secondEnd],
      size: Math.abs(secondStart - secondEnd),
    };

    if (second) {
      const [fStart, fEnd]: number[] = first.ranges;
      const [sStart, sEnd]: number[] = second.ranges;

      if (fEnd < sStart || sEnd < fStart) {
        break;
      }

      const overlapCount = overlappingNumberRanges(first.ranges, second.ranges);

      const smaller: RangeSize =
        first.size < second.size ? { i, size: first.size } : { i: i + 1, size: second.size };

      const larger: RangeSize =
        first.size > second.size ? { i, size: first.size } : { i: i + 1, size: second.size };

      const eq = overlapCount / smaller.size;

      if (eq >= 0.65 || (sStart > fStart && sEnd < fEnd) || (fStart > sStart && fEnd < sEnd)) {
        validRanges.splice(larger.i, 1);
      }
    }
  }
  return validRanges;
};

interface RangeSize {
  i: number;
  size: number;
}

export interface TimeRange {
  startTime: string;
  endTime: string;
}

export interface ValidTimeRange extends TimeRange {
  i: number;
}
