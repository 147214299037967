import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getAllApplianceBrandsRemoteDataState } from '../+state/appliance-details.selectors';
import { ApplianceDetailsPartialState } from '../+state/appliance-details.reducer';
import { GetAllApplianceBrands } from '../+state/appliance-details.actions';
import { RemoteDataState } from '@common/util-base';

@Injectable()
export class AllApplianceBrandsResolver implements Resolve<unknown> {
  constructor(private store: Store<ApplianceDetailsPartialState>, private router: Router) {}

  resolve() {
    return this.store.pipe(
      select(getAllApplianceBrandsRemoteDataState),
      tap((itemsLoadedState) => this.dispatchOnNotFetched(itemsLoadedState)),
      filter((itemsLoadedState) => this.filterSuccessOrError(itemsLoadedState)),
      tap((itemsLoadedState) => this.handleError(itemsLoadedState)),
      take(1)
    );
  }

  private dispatchOnNotFetched(itemsLoadedState: RemoteDataState) {
    if (itemsLoadedState === RemoteDataState.NotFetched) {
      this.store.dispatch(GetAllApplianceBrands());
    }
  }

  private filterSuccessOrError(itemsLoadedState: RemoteDataState) {
    if (itemsLoadedState === RemoteDataState.OK || itemsLoadedState === RemoteDataState.Error) {
      return true;
    }
    return false;
  }

  private handleError(itemsLoadedState: RemoteDataState) {
    if (itemsLoadedState === RemoteDataState.Error) {
      this.router.navigate(['/generic-error']);
    }
  }
}
