import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'randc-validation-error-formcontrol',
  templateUrl: './validation-error-formcontrol.component.html',
})
export class ValidationErrorFormControlComponent {
  @Input() group!: FormGroup;
  @Input() control: any;
  @Input() validators: any;

  error: any;

  getFieldError(control: any) {
    const errors = this.group.get(control)?.errors;
    if (errors) {
      const error = Object.keys(errors)[0];
      this.error = error;

      if (error !== this.error) {
        console.log(error);
      }

      return Object.keys(errors)[0];
    }
    return;
  }
}
