import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CmsTitleDescImageItem } from '@common/util-base';
import { KeyPointStackItem } from '@domgen/dgx-fe-base-components';

@Component({
  selector: 'randc-spa-how-it-works-new',
  templateUrl: './how-it-works-new.component.html',
  styleUrls: ['./how-it-works-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksNewComponent {
  @Input() title = '';

  _items: KeyPointStackItem[] = [];
  @Input() set items(value: CmsTitleDescImageItem[]) {
    this._items = value.map((item) => {
      const { title, description, imageDesktop } = item;

      return {
        title,
        description,
        image: {
          alt: title,
          path: imageDesktop['@link'],
        },
      };
    });
  }
}
