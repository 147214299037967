<div class="loader loader--with-text">
  <div class="loader__box text--center">
    <div class="loader__wrapper">
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle class="spinner-bg" cx="50" cy="50" r="45" />
        <circle class="spinner" cx="50" cy="50" r="45" />
      </svg>
    </div>
    <div class="loader__box-message">
      <p class="p2">
        <strong>We’re processing your request. <br />This may take a few moments.</strong>
      </p>
    </div>
  </div>
</div>
