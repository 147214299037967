<div class="sales payment-details-form">
  <randc-spa-customer-details-panel
    [customerDetails]="customerDetails"
  ></randc-spa-customer-details-panel>

  <div class="md-row lg-row mt-2 heading-row">
    <div class="md-7 lg-7">
      <div class="heading">
        <h2 class="mb-1 md-mb-0">Payment Details</h2>
      </div>
    </div>

    <div class="sm-5 md-5 lg-5">
      <div class="sm-row md-row lg-row icon-row">
        <div class="sm-4 md-8 lg-8">
          <i class="icon icon--lg icon-lock"></i>
        </div>
        <div class="sm-8 md-4 lg-4">
          <i class="icon icon--lg icon-direct-debit-slim"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="sm-12 md-12 lg-12 direct-debit">
      <randc-error-line
        *ngIf="directDebitErrorCount"
        [directDebitErrorCount]="directDebitErrorCount"
      >
      </randc-error-line>
    </div>
  </div>

  <div class="row">
    <div class="lg-12">
      <dgx-uib-direct-debit-form
        [cmsFormData]="cmsFormData"
        [validate]="canValidate"
        (form)="readFormGroup($event, 'DirectDebitForm')"
      ></dgx-uib-direct-debit-form>
    </div>
  </div>

  <div class="row mt-2">
    <div class="lg-12">
      <dgx-uib-cover-starting-date-info-dynamic>
        <p class="p1">
          Your Repair & Care plan will start immediately and continue for 12 months. Paying by
          Direct Debit ensures automatic renewal each year. Don’t worry, we’ll write to you before
          your renewal date.
        </p>
      </dgx-uib-cover-starting-date-info-dynamic>
    </div>
  </div>

  <div class="row mt-2">
    <div class="sm-12 md-12 lg-12">
      <p class="p1">
        Maintenance and support plans are provided by Domestic & General Services and accidental
        damage cover is provided by Domestic & General Insurance PLC.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="sm-12 md-12 lg-12">
      <dgx-uib-direct-debit-guarantee-dynamic class="mt-3">
        <p class="p1">
          If your chosen payment date is within the next 14 days, your first and second payments
          will be taken at the same time next month.
        </p>
      </dgx-uib-direct-debit-guarantee-dynamic>
    </div>
  </div>

  <div class="row mt-2">
    <div class="lg-12">
      <dgx-uib-marketing-preferences
        [cmsFormData]="cmsFormData"
        [validate]="canValidate"
        (form)="readFormGroup($event, 'MarketingPerferencesForm')"
        (analytics)="handleMarketingAnalytics($any($event))"
        [privacyPolicyLink]="
          buildConfigService.config.privacyPolicyLink
            ? buildConfigService.config.privacyPolicyLink
            : ''
        "
      >
      </dgx-uib-marketing-preferences>
    </div>
  </div>

  <div class="row mt-2">
    <div class="lg-12">
      <randc-dgx-uib-plan-documents
        [cmsFormData]="cmsFormData"
        [validate]="canValidate"
        (form)="readFormGroup($event, 'PlanDocumentForm')"
        (analytics)="handlePlanDocAnalytics($any($event))"
      >
      </randc-dgx-uib-plan-documents>
    </div>
  </div>

  <div class="row mt-2">
    <div class="lg-12">
      <form [formGroup]="termsAndConditionsFormGroup" *ngIf="termsAndConditionsFormGroup">
        <randc-dgx-dfb-accept-terms
          [formControlName]="acceptField.controlName"
          [field]="acceptField"
          [validate]="validate"
          (analytics)="handleAnalytics($event)"
          [privacyPolicyLink]="
            buildConfigService.config.privacyPolicyLink
              ? buildConfigService.config.privacyPolicyLink
              : ''
          "
        ></randc-dgx-dfb-accept-terms>
      </form>
    </div>
  </div>

  <div class="container sm-8 mt-3">
    <randc-button
      type="submit"
      text="Confirm purchase"
      classes="btn btn--primary"
      [disabled]="!checkoutPaymentForm.valid"
      (click)="emitSubmit($event)"
    >
    </randc-button>
  </div>
</div>
