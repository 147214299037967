import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AnalyticsService, InputMask } from '@common/util-base';

@Component({
  selector: 'randc-inputs',
  templateUrl: './input.component.html',
})
export class InputComponent {
  @Input() group!: FormGroup;
  @Input() maxlength!: number;
  @Input() item: any;
  @Input() label: any;
  @Input() hint: any;
  @Input() labelFor: any;
  @Input() showValidation: any;
  @Input() validators: any;
  @Input() maxLength: string | null = null;
  @Input() tooltip: any;
  @Input() mask: InputMask | null = null;
  name: any;
  id: any;
  isFocused = false;

  constructor(private _analyticsService: AnalyticsService) {}

  public onFocus() {
    this.showValidation = false;
    this.isFocused = true;
  }

  public onBlur() {
    this._analyticsService.triggerInputEvent(this.item.control, this.group);
  }
}
