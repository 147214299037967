export const mockFaultArraySingleData = {
  status: 'OK',
  modelNumberRequired: false,
  modelNumber: 'blabla',
  faultCategories: [
    {
      categoryId: '748',
      label: 'Common',
      faultTypes: [
        { faultId: '35939', label: "It won't switch on" },
        { faultId: '35941', label: "It's leaking" },
        { faultId: '35943', label: "There's an error code" },
        { faultId: '35940', label: 'It smells unusual' },
        { faultId: '35942', label: 'It sounds different' },
        { faultId: '35944', label: 'Other' },
      ],
    },
  ],
};
