import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'randc-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input() group!: FormGroup;
  @Input() labelFor: any;
  @Input() rows: any;
  @Input() classes: any;
  @Input() control: any;
  @Input() showValidation: any;
  @Input() label: any;
  @Input() items: any;
  @Input() validationError: any;
  @Input() validators: any;
  @Input() placeholder: any;
  @Input() chrLimit: any;
  @Input() hint: any;

  defaultLimit = 200;

  theLength = 0;

  ngOnInit(): void {
    if (Number(this.chrLimit) === 0 || this.chrLimit === null) {
      this.chrLimit = this.defaultLimit;
    }

    this.theLength = this.chrLimit;

    if (this.theLength) {
      (this.group.controls[this.control] as FormControl).valueChanges.subscribe((value) => {
        this.theLength = this.chrLimit - value.length;
      });
    }
  }
}
