import { Component, Input, OnDestroy } from '@angular/core';
import { QuoteFacade } from '@common/data-access-quote';

@Component({
  selector: 'randc-error-line',
  templateUrl: './error-line.component.html',
  styleUrls: ['./error-line.component.scss'],
})
export class ErrorLineComponent implements OnDestroy {
  constructor(private quoteFacade: QuoteFacade) {}

  @Input() public directDebitErrorCount!: number;

  ngOnDestroy() {
    // house keeping error object in store
    this.quoteFacade.clearErrorMessage();
  }
}
