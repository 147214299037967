export const mockCmsDataGeneric = {
  results: [
    {
      '@name': 'generic',
      '@path': '/generic',
      '@id': '689eafdd-2fde-4538-b8e6-a97a3a3712bd',
      '@nodeType': 'randc-landing-page',
      'jcr:createdBy': 'admin',
      'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
      'mgnl:created': '2021-05-25T10:55:23.350+01:00',
      'mgnl:lastActivatedVersion': '1.2',
      'jcr:baseVersion': '',
      faqReference: {
        '@name': 'generic',
        '@path': '/repair-and-care/generic',
        '@id': 'c71f520c-17ed-4d5d-9f78-7c2ba67018bd',
        '@nodeType': 'mgnl:folder',
        'jcr:createdBy': 'system',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
        'jcr:created': '2021-05-25T11:03:02.880+01:00',
        'mgnl:lastActivated': '2021-06-14T14:14:00.411+01:00',
        'mgnl:created': '2021-05-25T10:57:01.040+01:00',
        'mgnl:lastActivatedVersion': '1.1',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        'jcr:predecessors': [''],
        'jcr:baseVersion': '',
        'mgnl:comment': '',
        'mgnl:lastModified': '2021-06-14T19:25:26.977+01:00',
        'mgnl:activationStatus': 'true',
        jcrName: 'generic',
        'jcr:isCheckedOut': 'true',
        'mgnl:lastModifiedBy': 'anonymous',
        'mgnl:lastActivatedVersionCreated': '2021-06-14T14:14:00.155+01:00',
        'jcr:versionHistory': '',
        GenericFAQ1: {
          '@name': 'GenericFAQ1',
          '@path': '/repair-and-care/generic/GenericFAQ1',
          '@id': 'd152af51-a8b5-4795-a53c-59c03ec06adf',
          '@nodeType': 'dgx-faq',
          'mgnl:tags': [],
          'jcr:createdBy': 'system',
          question: 'How much does a repair cost?',
          answer:
            '<p>Help us to get an accurate quote by telling us a few details about your appliance and its fault. Repair &amp; Care plans are payable in monthly instalments and we have a &#39;no fix, no fee&rsquo; guarantee, which means you won&rsquo;t pay a penny if we can&rsquo;t fix the initial fault.</p>\n',
          'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
          'mgnl:lastActivatedBy': 'mollynaylor',
          'jcr:created': '2021-05-25T11:03:02.882+01:00',
          'mgnl:lastActivated': '2021-06-01T18:12:22.062+01:00',
          'mgnl:created': '2021-05-25T10:58:37.478+01:00',
          'mgnl:lastActivatedVersion': '1.1',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          'jcr:predecessors': [''],
          'jcr:baseVersion': '',
          'mgnl:comment': '',
          'mgnl:lastModified': '2021-06-01T18:21:23.056+01:00',
          'mgnl:activationStatus': 'true',
          jcrName: 'GenericFAQ1',
          'jcr:isCheckedOut': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          'jcr:versionHistory': '',
          'mgnl:lastActivatedVersionCreated': '2021-06-01T18:12:21.909+01:00',
          '@nodes': [],
        },
        GenericFAQ2: {
          '@name': 'GenericFAQ2',
          '@path': '/repair-and-care/generic/GenericFAQ2',
          '@id': '67d49bd5-4d6d-4374-b82a-0daea5c3f96a',
          '@nodeType': 'dgx-faq',
          'mgnl:tags': [],
          'jcr:createdBy': 'system',
          answer:
            '<p>We have a network of expert engineers who are approved and available to look at your appliance repair as quickly as possible.</p>\n',
          question: 'Who can repair my appliance?',
          'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
          'mgnl:lastActivatedBy': 'mollynaylor',
          'jcr:created': '2021-05-25T11:03:02.883+01:00',
          'mgnl:lastActivated': '2021-06-01T18:10:18.026+01:00',
          'mgnl:created': '2021-05-25T10:59:31.436+01:00',
          'mgnl:lastActivatedVersion': '1.0',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          'jcr:predecessors': [''],
          'jcr:baseVersion': '',
          'mgnl:comment': '',
          'mgnl:lastModified': '2021-06-01T18:13:10.457+01:00',
          'mgnl:activationStatus': 'true',
          jcrName: 'GenericFAQ2',
          'jcr:isCheckedOut': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          'jcr:versionHistory': '',
          'mgnl:lastActivatedVersionCreated': '2021-06-01T18:10:17.876+01:00',
          '@nodes': [],
        },
        genericFAQ3: {
          '@name': 'genericFAQ3',
          '@path': '/repair-and-care/generic/genericFAQ3',
          '@id': 'c450acc9-f7ba-43c7-ab9b-456e1f2d051b',
          '@nodeType': 'dgx-faq',
          'mgnl:tags': [],
          'jcr:createdBy': 'admin',
          answer:
            '<p>You&rsquo;ll need to have a Repair &amp; Care plan to book a repair. You can&nbsp;get a quote for a plan&nbsp;by giving us a few details about your broken appliance and selecting your preferred date for the engineer visit.</p>\n',
          'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
          question: 'How do I book a repair?',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'jcr:created': '2021-06-01T18:14:44.535+01:00',
          'mgnl:lastActivated': '2021-06-01T18:14:44.578+01:00',
          'mgnl:created': '2021-06-01T18:10:44.696+01:00',
          'mgnl:lastActivatedVersion': '1.0',
          'mgnl:createdBy': 'mollynaylor',
          'jcr:predecessors': [''],
          'jcr:baseVersion': '',
          'mgnl:comment': '',
          'mgnl:lastModified': '2021-06-01T18:22:07.208+01:00',
          'mgnl:activationStatus': 'true',
          jcrName: 'genericFAQ3',
          'jcr:isCheckedOut': 'true',
          'jcr:versionHistory': '',
          'mgnl:lastModifiedBy': 'anonymous',
          'mgnl:lastActivatedVersionCreated': '2021-06-01T18:14:44.431+01:00',
          '@nodes': [],
        },
        genericFAQ4: {
          '@name': 'genericFAQ4',
          '@path': '/repair-and-care/generic/genericFAQ4',
          '@id': 'fce1bb23-7c08-4f44-b27a-a98b41ee3f91',
          '@nodeType': 'dgx-faq',
          'mgnl:tags': [],
          'jcr:createdBy': 'admin',
          'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
          answer:
            '<p>Yes, we have a nationwide network of approved, expert engineers across the country. We&rsquo;d love to help you find an engineer and get a quote. You can do this by giving us a few details about your broken appliance.</p>\n',
          question: 'Do you have an engineer near me?',
          'jcr:created': '2021-06-01T18:15:53.200+01:00',
          'mgnl:created': '2021-06-01T18:15:42.155+01:00',
          'mgnl:createdBy': 'mollynaylor',
          'jcr:predecessors': [''],
          'jcr:baseVersion': '',
          'mgnl:comment': '',
          'mgnl:lastModified': '2021-06-01T18:15:53.220+01:00',
          'jcr:isCheckedOut': 'true',
          jcrName: 'genericFAQ4',
          'mgnl:lastModifiedBy': 'anonymous',
          'jcr:versionHistory': '',
          '@nodes': [],
        },
        genericFAQ5: {
          '@name': 'genericFAQ5',
          '@path': '/repair-and-care/generic/genericFAQ5',
          '@id': 'b77f6edb-553f-4195-b5c6-ed23723e38ff',
          '@nodeType': 'dgx-faq',
          'mgnl:tags': [],
          'jcr:createdBy': 'admin',
          'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
          answer:
            '<p>Repair &amp; Care is a maintenance and support plan. If our expert engineers can fix your initial fault, we&rsquo;ll get you back up and running as quickly as possible. Plus, the Repair &amp; Care plan provides ongoing maintenance and support for your appliance, to help ensure it continues to function correctly.&nbsp;If your initial fault can&#39;t be fixed, we also offer a &#39;no fix, no fee&#39; guarantee for added peace of mind.</p>\n',
          question: 'What is Repair & Care?',
          'jcr:created': '2021-06-01T18:17:13.188+01:00',
          'mgnl:created': '2021-06-01T18:17:01.066+01:00',
          'jcr:predecessors': [''],
          'mgnl:createdBy': 'mollynaylor',
          'jcr:baseVersion': '',
          'mgnl:comment': '',
          'mgnl:lastModified': '2021-06-01T18:17:13.194+01:00',
          'jcr:isCheckedOut': 'true',
          jcrName: 'genericFAQ5',
          'mgnl:lastModifiedBy': 'anonymous',
          'jcr:versionHistory': '',
          '@nodes': [],
        },
        genericFAQ6: {
          '@name': 'genericFAQ6',
          '@path': '/repair-and-care/generic/genericFAQ6',
          '@id': '944b88e2-2bd1-4080-b313-4d138669b06c',
          '@nodeType': 'dgx-faq',
          'mgnl:tags': [],
          'jcr:createdBy': 'admin',
          question: 'What’s included in a Repair & Care plan?',
          'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
          answer:
            '<p>The plan includes an initial repair with a guarantee of money back if your appliance can&#39;t be fixed, as well as ongoing maintenance and support to ensure it runs smoothly for as long as possible. If your appliance stops working, we can arrange a visit by one of our expert engineers. If we can&rsquo;t repair your appliance or we decide that it&rsquo;s beyond economic repair, we&rsquo;ll happily replace it (installation costs are not included). You&rsquo;ll also benefit from accidental damage cover, provided under a separate policy of insurance by Domestic &amp; General Insurance PLC, part of our Domestic &amp; General Group.</p>\n',
          'jcr:created': '2021-06-01T18:19:07.644+01:00',
          'mgnl:created': '2021-06-01T18:18:53.487+01:00',
          'jcr:predecessors': [''],
          'mgnl:createdBy': 'mollynaylor',
          'jcr:baseVersion': '',
          'mgnl:comment': '',
          'mgnl:lastModified': '2021-06-01T18:19:07.650+01:00',
          'jcr:isCheckedOut': 'true',
          jcrName: 'genericFAQ6',
          'jcr:versionHistory': '',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '@nodes': [
          'GenericFAQ1',
          'GenericFAQ2',
          'genericFAQ3',
          'genericFAQ4',
          'genericFAQ5',
          'genericFAQ6',
        ],
      },
      applianceProtectionTitle: 'We’re the leaders in home appliance protection',
      rncPlanTitle: 'What will I get with a Repair & Care plan?',
      'mgnl:activationStatus': 'true',
      jcrName: 'generic',
      'jcr:versionHistory': '',
      customerEngagementTitle: 'Count on us to protect the things you need',
      'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
      'mgnl:lastActivatedBy': 'mollynaylor',
      'jcr:created': '2021-05-25T11:00:26.575+01:00',
      'jcr:predecessors': [''],
      'mgnl:createdBy': 'surekhanandiyalathikesavan',
      planStepsTitle: 'How to fix your appliance in 3 easy steps with Repair & Care',
      'mgnl:comment': '',
      'mgnl:lastModified': '2021-06-04T12:10:53.760+01:00',
      'jcr:isCheckedOut': 'true',
      'mgnl:lastModifiedBy': 'anonymous',
      'mgnl:lastActivatedVersionCreated': '2021-06-03T18:57:37.873+01:00',
      mainHeroDetails: {
        '@name': 'mainHeroDetails',
        '@path': '/generic/mainHeroDetails',
        '@id': 'dc699a95-d420-4f1f-9858-3bfabf3fd347',
        '@nodeType': 'mgnl:contentNode',
        heroTitle: 'Repair & Care',
        'jcr:createdBy': 'admin',
        heroImageMobile: {
          '@name': 'Generic_375x250.jpg',
          '@path':
            '/dgx-repair-and-care/images/hero_images_desktop_tablet_mobile/Generic_375x250.jpg',
          '@id': 'jcr:6d9ff944-047b-40a1-a0df-dddff6263126',
          '@link': '/dam/jcr:6d9ff944-047b-40a1-a0df-dddff6263126/Generic_375x250.jpg',
          metadata: {
            fileName: 'Generic_375x250.jpg',
            mimeType: 'image/jpeg',
            fileSize: '20402',
            height: '250',
            width: '375',
            format: 'image/jpeg',
            creator: ['surekhanandiyalathikesavan'],
            date: '2021-05-24T12:57:21.860+01:00',
            created: '2021-05-11T11:10:08.572+01:00',
            modified: '2021-05-24T12:57:21.860+01:00',
          },
        },
        heroImageTablet: {
          '@name': 'Generic_768x415.jpg',
          '@path':
            '/dgx-repair-and-care/images/hero_images_desktop_tablet_mobile/Generic_768x415.jpg',
          '@id': 'jcr:517cae38-a5a0-4cd2-a12a-3ecfdf5ff86a',
          '@link': '/dam/jcr:517cae38-a5a0-4cd2-a12a-3ecfdf5ff86a/Generic_768x415.jpg',
          metadata: {
            fileName: 'Generic_768x415.jpg',
            mimeType: 'image/jpeg',
            fileSize: '41641',
            height: '415',
            width: '768',
            format: 'image/jpeg',
            creator: ['surekhanandiyalathikesavan'],
            date: '2021-05-24T12:57:21.771+01:00',
            created: '2021-05-11T11:10:08.578+01:00',
            modified: '2021-05-24T12:57:21.771+01:00',
          },
        },
        heroDescription: 'Book a quick repair for your appliance',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'jcr:created': '2021-06-04T12:10:53.616+01:00',
        heroImageDesktop: {
          '@name': 'Generic_1366x350.jpg',
          '@path':
            '/dgx-repair-and-care/images/hero_images_desktop_tablet_mobile/Generic_1366x350.jpg',
          '@id': 'jcr:3cc4e2ca-d878-4152-b1ae-a4723d5e63ab',
          '@link': '/dam/jcr:3cc4e2ca-d878-4152-b1ae-a4723d5e63ab/Generic_1366x350.jpg',
          metadata: {
            fileName: 'Generic_1366x350.jpg',
            mimeType: 'image/jpeg',
            fileSize: '69574',
            height: '350',
            width: '1366',
            format: 'image/jpeg',
            creator: ['surekhanandiyalathikesavan'],
            date: '2021-05-24T12:57:21.955+01:00',
            created: '2021-05-11T11:10:08.565+01:00',
            modified: '2021-05-24T12:57:21.955+01:00',
          },
        },
        'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
        'mgnl:created': '2021-05-25T10:55:23.353+01:00',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        heroSubDescription:
          '<ul>\n\t<li>Arrange a visit now with a repairman</li>\n\t<li>Get ongoing maintenance for 12 months</li>\n\t<li>Enjoy our &lsquo;no fix, no fee&rsquo; guarantee if your initial fault can&rsquo;t be fixed</li>\n</ul>\n\n<p>&nbsp;</p>\n',
        'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
        'mgnl:activationStatus': 'true',
        'mgnl:lastModifiedBy': 'anonymous',
        '@nodes': [],
      },
      seoDetails: {
        '@name': 'seoDetails',
        '@path': '/generic/seoDetails',
        '@id': '9a1552c8-e8e6-40ec-a3bc-9914698a959e',
        '@nodeType': 'mgnl:contentNode',
        'jcr:createdBy': 'admin',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
        'jcr:created': '2021-06-04T12:10:53.618+01:00',
        'mgnl:created': '2021-05-25T10:55:23.356+01:00',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        metaTitle: 'Appliance Repairs | Domestic & General',
        metaDescription:
          'Fix your broken appliance and add ongoing protection with our Repair & Care plan. Book a repairman today.',
        'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
        'mgnl:activationStatus': 'true',
        'mgnl:lastModifiedBy': 'anonymous',
        '@nodes': [],
      },
      rncPlanItems: {
        '@name': 'rncPlanItems',
        '@path': '/generic/rncPlanItems',
        '@id': '26383112-5443-4de4-a488-bc993bd8a02e',
        '@nodeType': 'mgnl:contentNode',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        'jcr:createdBy': 'admin',
        'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
        'mgnl:activationStatus': 'true',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'jcr:created': '2021-06-04T12:10:53.619+01:00',
        'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
        'mgnl:lastModifiedBy': 'anonymous',
        'mgnl:created': '2021-05-25T10:55:23.357+01:00',
        '0': {
          '@name': '0',
          '@path': '/generic/rncPlanItems/0',
          '@id': '5f2581dd-5521-4a05-9322-513f7509c164',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'jcr:created': '2021-06-04T12:10:53.619+01:00',
          'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
          'mgnl:created': '2021-05-25T10:55:23.357+01:00',
          description:
            '<p>All our&nbsp;engineers are fully qualified, vetted by us and selected for their expertise. We&rsquo;ll send you the most appropriate person based on your location.</p>\n',
          imageDesktop: {
            '@name': 'icon-local-engineers.svg',
            '@path': '/dgx-repair-and-care/images/icon-local-engineers.svg',
            '@id': 'jcr:1045eaae-e8d9-4c06-8d8e-da5d92ed1d7e',
            '@link': '/dam/jcr:1045eaae-e8d9-4c06-8d8e-da5d92ed1d7e/icon%20local%20engineers.svg',
            metadata: {
              fileName: 'icon local engineers.svg',
              mimeType: 'image/svg+xml',
              fileSize: '2469',
              height: '0',
              width: '0',
              format: 'image/svg+xml',
              creator: ['surekhanandiyalathikesavan'],
              date: '2021-05-24T12:57:20.654+01:00',
              created: '2021-05-11T11:51:19.659+01:00',
              modified: '2021-05-24T12:57:20.654+01:00',
            },
          },
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: 'Local engineers',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '1': {
          '@name': '1',
          '@path': '/generic/rncPlanItems/1',
          '@id': '077ba23a-fe21-488d-9713-50dab6d2fc5e',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
          'jcr:created': '2021-06-04T12:10:53.620+01:00',
          'mgnl:created': '2021-05-25T10:55:23.360+01:00',
          description:
            '<p>A remote annual health check plus ongoing maintenance and support to help ensure your appliance&nbsp;runs smoothly for as long as possible.</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          imageDesktop: {
            '@name': 'icon_ongoing_support.svg',
            '@path': '/dgx-repair-and-care/images/icon_ongoing_support.svg',
            '@id': 'jcr:eed18533-3b94-465a-848a-22f057404f4e',
            '@link': '/dam/jcr:eed18533-3b94-465a-848a-22f057404f4e/icon_ongoing_support.svg',
            metadata: {
              fileName: 'icon_ongoing_support.svg',
              mimeType: 'image/svg+xml',
              fileSize: '1827',
              height: '0',
              width: '0',
              format: 'image/svg+xml',
              creator: ['surekhanandiyalathikesavan'],
              date: '2021-05-24T12:57:20.571+01:00',
              created: '2021-05-11T11:52:09.306+01:00',
              modified: '2021-05-24T12:57:20.571+01:00',
            },
          },
          title: 'Ongoing support',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '2': {
          '@name': '2',
          '@path': '/generic/rncPlanItems/2',
          '@id': '4b06f142-f0ea-4d0e-a3e2-080a400cef56',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
          'jcr:created': '2021-06-04T12:10:53.621+01:00',
          'mgnl:created': '2021-05-25T10:55:23.362+01:00',
          description:
            '<p>You can pay for your 12-month plan, including the initial repair, with Direct Debit. Plus, with our &lsquo;no fix, no fee&rsquo; guarantee, you won&rsquo;t pay a penny if we can&rsquo;t fix the initial fault.</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          imageDesktop: {
            '@name': 'icon--unexpected-billls.svg',
            '@path': '/dgx-repair-and-care/images/icon--unexpected-billls.svg',
            '@id': 'jcr:bc01e195-2a3a-42be-b9e4-17898b71dfe5',
            '@link':
              '/dam/jcr:bc01e195-2a3a-42be-b9e4-17898b71dfe5/icon%20-unexpected%20billls.svg',
            metadata: {
              fileName: 'icon -unexpected billls.svg',
              mimeType: 'image/svg+xml',
              fileSize: '2714',
              height: '0',
              width: '0',
              format: 'image/svg+xml',
              creator: ['surekhanandiyalathikesavan'],
              date: '2021-05-24T12:57:20.336+01:00',
              created: '2021-05-11T11:52:22.754+01:00',
              modified: '2021-05-24T12:57:20.336+01:00',
            },
          },
          title: 'No unexpected bills',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '@nodes': ['0', '1', '2'],
      },
      applianceProtectionItems: {
        '@name': 'applianceProtectionItems',
        '@path': '/generic/applianceProtectionItems',
        '@id': 'a39695fc-88d6-4faa-8665-9673e2563dd0',
        '@nodeType': 'mgnl:contentNode',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        'jcr:createdBy': 'admin',
        'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
        'mgnl:activationStatus': 'true',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
        'jcr:created': '2021-06-04T12:10:53.625+01:00',
        'mgnl:lastModifiedBy': 'anonymous',
        'mgnl:created': '2021-05-25T10:55:23.380+01:00',
        '0': {
          '@name': '0',
          '@path': '/generic/applianceProtectionItems/0',
          '@id': '4695fd95-4d8c-4061-9615-8b6d56eaa330',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
          'jcr:created': '2021-06-04T12:10:53.626+01:00',
          'mgnl:created': '2021-05-25T10:55:23.380+01:00',
          description:
            '<p>We provide protection, maintenance and support for domestic products and consumer electronics, ranging from washing machines to televisions and dishwashers. In fact, we&nbsp;look after 22.5 million products and, through our expert service network, we repair or replace 2.4 million appliances every year*.<br />\n<br />\n*Based on the number of repairs and replacements completed worldwide in 2019-20</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          imageDesktop: {
            '@name': 'block1_img_left.jpg',
            '@path': '/dgx-repair-and-care/images/block1_img_left.jpg',
            '@id': 'jcr:0b951762-5c23-4507-b11a-db81fca743c8',
            '@link': '/dam/jcr:0b951762-5c23-4507-b11a-db81fca743c8/block1_img_left.jpg',
            metadata: {
              fileName: 'block1_img_left.jpg',
              mimeType: 'image/jpeg',
              fileSize: '25852',
              height: '200',
              width: '514',
              format: 'image/jpeg',
              creator: ['surekhanandiyalathikesavan'],
              date: '2021-05-24T12:57:20.239+01:00',
              created: '2021-05-11T11:52:35.775+01:00',
              modified: '2021-05-24T12:57:20.239+01:00',
            },
          },
          title: 'It’s our mission to keep your world running',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '1': {
          '@name': '1',
          '@path': '/generic/applianceProtectionItems/1',
          '@id': 'f1ed4a07-4516-4a1d-8618-548efe710185',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.252+01:00',
          'jcr:created': '2021-06-04T12:10:53.627+01:00',
          'mgnl:created': '2021-05-25T10:55:23.383+01:00',
          description:
            '<p>For over a hundred years, Domestic &amp; General have been a trusted provider of aftercare for millions of domestic appliances. Today, we&rsquo;re the UK&rsquo;s leading provider of appliance breakdown protection and deliver products and services to meet your needs.</p>\n',
          imageDesktop: {
            '@name': 'block1_img_right.jpg',
            '@path': '/dgx-repair-and-care/images/block1_img_right.jpg',
            '@id': 'jcr:df12965f-c2c6-493f-b423-6469bf49971f',
            '@link': '/dam/jcr:df12965f-c2c6-493f-b423-6469bf49971f/block1_img_right.jpg',
            metadata: {
              fileName: 'block1_img_right.jpg',
              mimeType: 'image/jpeg',
              fileSize: '24522',
              height: '200',
              width: '514',
              format: 'image/jpeg',
              creator: ['surekhanandiyalathikesavan'],
              date: '2021-05-24T12:57:20.097+01:00',
              created: '2021-05-11T11:52:50.720+01:00',
              modified: '2021-05-24T12:57:20.097+01:00',
            },
          },
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: 'Giving customers peace of mind for over 100 years',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '@nodes': ['0', '1'],
      },
      planStepsItems: {
        '@name': 'planStepsItems',
        '@path': '/generic/planStepsItems',
        '@id': '7bec5a5c-8af9-4529-a23d-780a6f8bdf0a',
        '@nodeType': 'mgnl:contentNode',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        'jcr:createdBy': 'admin',
        'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
        'mgnl:activationStatus': 'true',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'jcr:created': '2021-06-04T12:10:53.628+01:00',
        'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
        'mgnl:lastModifiedBy': 'anonymous',
        'mgnl:created': '2021-05-25T10:55:23.395+01:00',
        '0': {
          '@name': '0',
          '@path': '/generic/planStepsItems/0',
          '@id': '94c07d93-6df7-4149-a26a-0f8aec6b8119',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'jcr:created': '2021-06-04T12:10:53.629+01:00',
          'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
          'mgnl:created': '2021-05-25T10:55:23.395+01:00',
          description:
            '<p>Simply tell us your postcode and some details about your broken appliance, and then select a day that suits you for one of our expert engineers to visit.</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          imageDesktop: {
            '@name': '3_easy_1.png',
            '@path': '/dgx-repair-and-care/images/3-easy-step-images-PNG/3_easy_1.png',
            '@id': 'jcr:c265ba2e-b113-4f9b-8890-01f84c7f698b',
            '@link': '/dam/jcr:c265ba2e-b113-4f9b-8890-01f84c7f698b/3_easy_1.png',
            metadata: {
              fileName: '3_easy_1.png',
              mimeType: 'image/png',
              fileSize: '25362',
              height: '123',
              width: '136',
              format: 'image/png',
              creator: ['mollynaylor'],
              date: '2021-05-28T16:26:15.026+01:00',
              created: '2021-05-28T16:25:44.760+01:00',
              modified: '2021-05-28T16:26:15.026+01:00',
            },
          },
          title: 'Search for an engineer',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '1': {
          '@name': '1',
          '@path': '/generic/planStepsItems/1',
          '@id': 'e138909f-992d-4e42-b599-916913b2bc76',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
          'jcr:created': '2021-06-04T12:10:53.630+01:00',
          'mgnl:created': '2021-05-25T10:55:23.397+01:00',
          description:
            '<p>Take a look at the benefits you&rsquo;ll get with a Repair &amp; Care plan, including ongoing maintenance and support. When you&rsquo;re ready, confirm your booking with our engineer.</p>\n',
          imageDesktop: {
            '@name': '3_easy_2.png',
            '@path': '/dgx-repair-and-care/images/3-easy-step-images-PNG/3_easy_2.png',
            '@id': 'jcr:d56be2cd-813a-46af-be6a-d93f85975c4d',
            '@link': '/dam/jcr:d56be2cd-813a-46af-be6a-d93f85975c4d/3_easy_2.png',
            metadata: {
              fileName: '3_easy_2.png',
              mimeType: 'image/png',
              fileSize: '28372',
              height: '123',
              width: '136',
              format: 'image/png',
              creator: ['mollynaylor'],
              date: '2021-05-28T16:26:14.914+01:00',
              created: '2021-05-28T16:25:55.163+01:00',
              modified: '2021-05-28T16:26:14.914+01:00',
            },
          },
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: 'Review our quote and confirm your booking',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '2': {
          '@name': '2',
          '@path': '/generic/planStepsItems/2',
          '@id': 'f31926cf-958e-4f5a-bcdc-8c452397b5f3',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
          'jcr:created': '2021-06-04T12:10:53.630+01:00',
          'mgnl:created': '2021-05-25T10:55:23.400+01:00',
          description:
            '<p>Once you&rsquo;ve purchased your plan, you&rsquo;ll receive confirmation of your booking and access to your plan document. You&rsquo;re all set!</p>\n',
          imageDesktop: {
            '@name': '3_easy_3.png',
            '@path': '/dgx-repair-and-care/images/3-easy-step-images-PNG/3_easy_3.png',
            '@id': 'jcr:2945af49-28c6-4819-ab88-9c8269399989',
            '@link': '/dam/jcr:2945af49-28c6-4819-ab88-9c8269399989/3_easy_3.png',
            metadata: {
              fileName: '3_easy_3.png',
              mimeType: 'image/png',
              fileSize: '25026',
              height: '123',
              width: '136',
              format: 'image/png',
              creator: ['mollynaylor'],
              date: '2021-05-28T16:26:14.761+01:00',
              created: '2021-05-28T16:26:03.637+01:00',
              modified: '2021-05-28T16:26:14.761+01:00',
            },
          },
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: 'Download your Repair & Care plan',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '@nodes': ['0', '1', '2'],
      },
      customerEngagementItems: {
        '@name': 'customerEngagementItems',
        '@path': '/generic/customerEngagementItems',
        '@id': '23f2be38-3f64-4a09-9706-14ed805a5a37',
        '@nodeType': 'mgnl:contentNode',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        'jcr:createdBy': 'admin',
        'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
        'mgnl:activationStatus': 'true',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'jcr:created': '2021-06-04T12:10:53.631+01:00',
        'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
        'mgnl:created': '2021-05-25T10:55:23.414+01:00',
        'mgnl:lastModifiedBy': 'anonymous',
        '0': {
          '@name': '0',
          '@path': '/generic/customerEngagementItems/0',
          '@id': '6280ae7e-227c-4909-9a9c-d0ecb0508955',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'jcr:created': '2021-06-04T12:10:53.632+01:00',
          'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
          'mgnl:created': '2021-05-25T10:55:23.414+01:00',
          description: '<p>Number of UK customers</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: '8m+',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '1': {
          '@name': '1',
          '@path': '/generic/customerEngagementItems/1',
          '@id': '27a0400e-2870-4f12-a71f-a27ed3724b0c',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'jcr:created': '2021-06-04T12:10:53.633+01:00',
          'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
          'mgnl:created': '2021-05-25T10:55:23.416+01:00',
          description: '<p>Appliance repairs per year</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: '2.4m',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '2': {
          '@name': '2',
          '@path': '/generic/customerEngagementItems/2',
          '@id': 'd4df278b-a165-4060-9cb3-995941e41fdd',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
          'jcr:created': '2021-06-04T12:10:53.634+01:00',
          'mgnl:created': '2021-05-25T10:55:23.418+01:00',
          description: '<p>Appliances on average repaired every day</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: '6.4k',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '3': {
          '@name': '3',
          '@path': '/generic/customerEngagementItems/3',
          '@id': '446faa89-7021-41a3-87fe-6eedeba1599a',
          '@nodeType': 'mgnl:contentNode',
          'jcr:createdBy': 'admin',
          'mgnl:lastActivatedBy': 'mollynaylor',
          'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
          'jcr:created': '2021-06-04T12:10:53.634+01:00',
          'mgnl:created': '2021-05-25T10:56:16.589+01:00',
          description: '<p>Appliances replaced last year</p>\n',
          'mgnl:createdBy': 'surekhanandiyalathikesavan',
          title: '500k',
          'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
          'mgnl:activationStatus': 'true',
          'mgnl:lastModifiedBy': 'anonymous',
          '@nodes': [],
        },
        '@nodes': ['0', '1', '2', '3'],
      },
      brandLogoBannerItems: {
        '@name': 'brandLogoBannerItems',
        '@path': '/generic/brandLogoBannerItems',
        '@id': 'ab6a1f9c-645f-419a-b3fe-a392a0716c0b',
        '@nodeType': 'mgnl:contentNode',
        'mgnl:createdBy': 'surekhanandiyalathikesavan',
        'jcr:createdBy': 'admin',
        'mgnl:lastModified': '2021-06-04T12:10:53.400+01:00',
        'mgnl:activationStatus': 'true',
        'mgnl:lastActivatedBy': 'mollynaylor',
        'jcr:created': '2021-06-04T12:10:53.635+01:00',
        'mgnl:lastActivated': '2021-06-03T18:57:38.253+01:00',
        'mgnl:lastModifiedBy': 'anonymous',
        'mgnl:created': '2021-05-25T10:55:23.430+01:00',
        '@nodes': [],
      },
      '@nodes': [
        'mainHeroDetails',
        'seoDetails',
        'rncPlanItems',
        'applianceProtectionItems',
        'planStepsItems',
        'customerEngagementItems',
        'brandLogoBannerItems',
      ],
    },
  ],
};
