import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllApplianceBrandsResolver, AllApplianceTypesResolver, services } from './services';
import { EffectsModule } from '@ngrx/effects';
import { ApplianceDetailsEffects } from './+state/appliance-details.effects';
import { StoreModule } from '@ngrx/store';
import { APPLIANCE_FEATURE_KEY, reducer } from './+state/appliance-details.reducer';
import { ApplianceDetailsFacade } from './+state/appliance-details.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(APPLIANCE_FEATURE_KEY, reducer),
    EffectsModule.forFeature([ApplianceDetailsEffects]),
  ],
  providers: [
    ...services,
    ApplianceDetailsFacade,
    AllApplianceTypesResolver,
    AllApplianceBrandsResolver,
  ],
})
export class CommonDataAccessApplianceDetailsModule {}
