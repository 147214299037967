import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CmsTitleDescItem } from '@common/util-base';

@Component({
  selector: 'randc-back-on-track',
  templateUrl: './back-on-track.component.html',
  styleUrls: ['./back-on-track.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackOnTrackComponent {
  @Input() data: CmsTitleDescItem | null = null;
  @Input() buttonText = '';
  @Output() bookARepairClick: EventEmitter<void> = new EventEmitter();
}
