import { Injectable } from '@angular/core';
import { BuildConfigService } from '../services/build.config.service';
import { HttpContentType } from '../models';

interface HeaderParams {
  requestSource: boolean;
  wlClient: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiHelper {
  constructor(private buildConfigService: BuildConfigService) {}

  public generateHeaders(headerParams: HeaderParams) {
    return {
      'content-type': HttpContentType.JSON,
      ...(headerParams.requestSource
        ? {
            'request-source': this.buildConfigService.config.requestSource,
            'request-action': this.buildConfigService.config.requestAction,
          }
        : {}),
      ...(headerParams.wlClient
        ? {
            'wl-client': this.buildConfigService.config.wlClient,
          }
        : {}),
    };
  }
}
