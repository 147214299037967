import { ButtonComponent } from './button/button.component';
import { FieldsetComponent } from './fieldset/fieldset.component';
import { FormElementsComponent } from './form-elements/form-elements.component';
import { HintComponent } from './hint/hint.component';
import { InputAutoCompleteComponent } from './input-autocomplete/input-autocomplete.component';
import { InputComponent } from './input/input.component';
import { LabelComponent } from './label/label.component';
import { SelectComponent } from './select/select.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ValidationErrorFormControlComponent } from './validation-error-formcontrol/validation-error-formcontrol.component';
import { FormsComponent } from './forms/forms.component';
import { directives } from '@common/util-base';

export const components = [
  ...directives,
  ButtonComponent,
  FieldsetComponent,
  FormElementsComponent,
  HintComponent,
  InputAutoCompleteComponent,
  InputComponent,
  LabelComponent,
  SelectComponent,
  TextareaComponent,
  ValidationErrorFormControlComponent,
  FormsComponent,
];

export * from './button/button.component';
export * from './fieldset/fieldset.component';
export * from './form-elements/form-elements.component';
export * from './hint/hint.component';
export * from './input-autocomplete/input-autocomplete.component';
export * from './input/input.component';
export * from './label/label.component';
export * from './select/select.component';
export * from './textarea/textarea.component';
export * from './validation-error-formcontrol/validation-error-formcontrol.component';
export * from './forms/forms.component';
