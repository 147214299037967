import { ApplianceDetailsApiService } from './appliance-details-api/appliance-details-api.service';
import { ApplianceDetailsMapperService } from './appliance-details-mapper/appliance-details-mapper.service';

export const services = [ApplianceDetailsApiService, ApplianceDetailsMapperService];

export * from './appliance-details-api/appliance-details-api.service';
export * from './appliance-details-mapper/appliance-details-mapper.service';

export * from './all-appliance-types.resolver';
export * from './all-appliance-brands.resolver';
