export const mockFaultArrayMultipleData = {
  status: 'OK',
  modelNumberRequired: true,
  modelNumber: 'blabla',
  faultCategories: [
    {
      categoryId: '748',
      label: 'Common',
      faultTypes: [
        { faultId: '35939', label: "It won't switch on" },
        { faultId: '35941', label: "It's leaking" },
        { faultId: '35943', label: "There's an error code" },
        { faultId: '35940', label: 'It smells unusual' },
        { faultId: '35942', label: 'It sounds different' },
        { faultId: '35944', label: 'Other' },
      ],
    },
    {
      categoryId: '749',
      label: 'Common1',
      faultTypes: [
        { faultId: '35939', label: "It won't switch on1" },
        { faultId: '35941', label: "It's leaking1" },
        { faultId: '35943', label: "There's an error code1" },
        { faultId: '35940', label: 'It smells unusual1' },
        { faultId: '35942', label: 'It sounds different1' },
        { faultId: '35944', label: 'Other1' },
      ],
    },
    {
      categoryId: '750',
      label: 'Common2',
      faultTypes: [
        { faultId: '35939', label: "It won't switch on2" },
        { faultId: '35941', label: "It's leaking2" },
        { faultId: '35943', label: "There's an error code2" },
        { faultId: '35940', label: 'It smells unusual2' },
        { faultId: '35942', label: 'It sounds different2' },
        { faultId: '35944', label: 'Other2' },
      ],
    },
  ],
};
