<div class="sales sm-no-padding">
  <div class="main-container">
    <div class="user-idle-timeout">
      <div class="info-icon mt-1 mr-1">
        <img src="assets/images/icons/icon_info.svg" alt="User Idle Info" />
      </div>
      <div class="info-message">
        <div class="user-idle-timeout-card__wrapper">
          <div class="user-idle-timeout-card">
            <h3>You’ve gone a bit quiet</h3>
            <p>Your session is about to time out — would you like to continue with your booking?</p>
            <p class="row--padding-top-medium">Just a few more minutes and you’ll be done.</p>
          </div>

          <div class="pt-1 pb-1">
            <button class="continue-booking-button btn btn--primary" (click)="continueBooking()">
              <span class="label"> Continue booking </span>
              <i class="icon icon-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
