import {
  Component,
  Input,
  Output,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { CalendarDay, CalendarNextAvailability } from '@common/util-base';
import { CalendarComponentStore } from './calendar-component.store';
import { CalendarHelperService } from './services/calendar-helper.service';

@Component({
  selector: 'randc-dg-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CalendarComponentStore, CalendarHelperService],
})
export class CalendarComponent implements OnChanges {
  @Input() nextAvailability: CalendarNextAvailability | undefined;
  @Input() appointments: CalendarDay[] = [];
  @Input() dateSelected = false;
  @Output() selectDate = this.calendarState.dateChanges$;

  constructor(public calendarState: CalendarComponentStore) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appointments?.currentValue?.length) {
      this.updateCalendarDays();
      this.setCalendarMonthInView();
      if (!this.dateSelected) {
        this.selectCalendarDate(this.nextAvailability?.nextAvailableId);
      }
    }
  }

  public updateCalendarDays(): void {
    this.calendarState.updateCalendarDays(this.appointments);
  }

  public setCalendarMonthInView(): void {
    this.calendarState.updateMonthInView({
      firstDay: new Date(this.appointments[0].date),
      lastDay: new Date(this.appointments[this.appointments.length - 1].date),
    });
  }

  public selectCalendarDate(value: number | undefined): void {
    this.calendarState.updateSelectedCalendarDay(value);
  }
}
