import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CmsFormField, AnalyticsData, WrappedValidate } from '@domgen/dgx-fe-business-models';
import {
  AnalyticsService,
  BuildConfigService,
  CustomerDetails,
  getDefaultFieldDef,
  getFormField,
  GtmEvent,
  QuoteSteps,
} from '@common/util-base';
import {
  CheckboxToggleDef,
  ControlType,
  DynamicFormbuilderService,
} from '@domgen/dgx-fe-dynamic-form-builder';
@UntilDestroy()
@Component({
  selector: 'randc-payment-details',
  templateUrl: './payment-details-form.component.html',
  styleUrls: ['./payment-details-form.component.scss'],
})
export class PaymentDetailsFormComponent implements AfterViewInit {
  @Input() cmsFormData: CmsFormField<string>[] = [];
  @Input() directDebitErrorCount = 0;
  @Input() customerDetails: CustomerDetails = {
    title: '',
    firstName: '',
    surname: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      postalCode: '',
      countryCode: '',
    },
    email: {
      main: '',
    },
    telephone: {
      mobile: '',
    },
    sendMethod: 'EMAIL',
    preferredContactMethod: 'EMAIL',
    marketingPreferences: { email: false, phone: false, post: false },
  };
  @Output() submitForm: EventEmitter<any> = new EventEmitter();

  directDebitFormGroup: FormGroup = new FormGroup({});
  marketingPerferencesFormGroup: FormGroup = new FormGroup({});
  planDocumentFormGroup: FormGroup = new FormGroup({});
  checkoutPaymentForm: FormGroup = new FormGroup({});
  termsAndConditionsFormGroup: FormGroup | null = null;
  acceptField: any;
  validate = false;
  canValidate: WrappedValidate = { validate: false };

  constructor(
    private _analyticsService: AnalyticsService,
    private fb: FormBuilder,
    private _dynamicFormbuilderService: DynamicFormbuilderService,
    public buildConfigService: BuildConfigService
  ) {}

  ngOnInit(): void {
    const termsControlName = 'termsAndConditions';
    const cmsFormField = getFormField<CmsFormField>(this.cmsFormData, termsControlName);
    const defaultFieldDef = getDefaultFieldDef(cmsFormField, termsControlName);

    this.acceptField = {
      ...defaultFieldDef,
      controlName: termsControlName,
      controlType: ControlType.ACCEPT,
      validators: [Validators.requiredTrue],
      label: { text: '' },
      validationMessages: [
        { message: 'Please confirm that you have read and agree', type: 'required' },
      ],
    } as CheckboxToggleDef;

    const fieldDefs = [];
    fieldDefs.push(this.acceptField);

    this.termsAndConditionsFormGroup = this._dynamicFormbuilderService.generateFormGroup(fieldDefs);
  }

  ngAfterViewInit(): void {
    this.checkoutPaymentForm = this.fb.group({
      directDebitFormGroup: this.directDebitFormGroup,
      marketingPerferencesFormGroup: this.marketingPerferencesFormGroup,
      planDocumentFormGroup: this.planDocumentFormGroup,
      termsAndConditionsFormGroup: this.termsAndConditionsFormGroup,
    });

    this.planDocumentFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((planDocument) => {
      if (planDocument && planDocument.paperless) {
        this._triggerPlanDocumentGA(planDocument.paperless);
      }
    });
  }

  emitSubmit($event: any): void {
    this.validate = true;
    this.canValidate = { validate: true };
    this.submitForm.emit(this.checkoutPaymentForm);

    if (this.checkoutPaymentForm && !this.checkoutPaymentForm.valid) {
      this._triggerValidationErrorGA();
    } else {
      this._triggerSubmitGA();
    }
  }

  readFormGroup(form: FormGroup, source: string) {
    if (source === 'DirectDebitForm') {
      this.directDebitFormGroup = form;
    } else if (source === 'MarketingPerferencesForm') {
      this.marketingPerferencesFormGroup = form;
    } else if (source === 'PlanDocumentForm') {
      this.planDocumentFormGroup = form;
    }
  }

  // TODO: Need to pass proper payment form data to GA
  handleAnalytics(value: AnalyticsData) {
    if (value) {
      if (value.error && value.error.length > 0) {
        this._analyticsService.trigger(GtmEvent.GENERIC, {
          eventCategory: 'payment-details',
          eventAction: 'error',
          eventLabel: value.error,
        });
      } else {
        this._analyticsService.trigger(GtmEvent.GENERIC, {
          eventCategory: 'payment-details',
          eventAction: `${value.controlName}`,
          eventLabel: 'completed',
        });
      }
    }
  }

  handleMarketingAnalytics(analyticsData: AnalyticsData) {
    this._analyticsService.trigger(GtmEvent.GENERIC, {
      eventCategory: 'marketing-preferences',
      eventAction: analyticsData.controlName,
      eventLabel: analyticsData.value,
    });
  }

  handlePlanDocAnalytics(analyticsData: AnalyticsData) {
    this._analyticsService.trigger(GtmEvent.GENERIC, {
      eventCategory: 'send-plan-docs',
      eventAction: 'receive-plan-in-post',
      eventLabel: analyticsData.value,
    });
  }

  private _triggerValidationErrorGA(): void {
    const validationErrorMessage = this._analyticsService.getValidationErrorMessages(
      this.checkoutPaymentForm,
      this.cmsFormData
    );
    this._analyticsService.trigger(GtmEvent.GENERIC, {
      eventCategory: 'payment-details',
      eventAction: 'error',
      eventLabel: validationErrorMessage,
    });
  }

  private _triggerSubmitGA() {
    this._analyticsService.trigger(GtmEvent.QUOTE, {
      eventCategory: 'payment-details-complete',
      eventAction: 'confirm-booking',
      eventLabel: 'success',
      quoteStep: QuoteSteps.PAYMENT_DETAILS,
    });
  }

  private _triggerPlanDocumentGA(paperless: string) {
    this._analyticsService.trigger(GtmEvent.QUOTE, {
      event: GtmEvent.GENERIC,
      eventCategory: 'plan-documents',
      eventAction: 'post',
      eventLabel: paperless,
    });
  }
}
