import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { CmsFormField } from '@domgen/dgx-fe-business-models';

declare global {
  interface Window {
    dataLayer: any;
    optimizely: any;
    Trustpilot: any;
  }
}
export enum QuoteSteps {
  LANDING_FORM = '1',
  BOOK_APPOINTMENT = '2',
  APPLIANCE_DETAILS = '3',
  QUOTE_SUMMARY = '4',
  ABOUT_FAULT = 'OLD STEP',
  PERSONAL_DETAILS = '5',
  PAYMENT_DETAILS = '6',
}
export enum GtmEvent {
  // "page load" events
  PAGEVIEW = 'spa-pageview',
  // all interactions that will generate a quote for the user
  QUOTE = 'QuoteEvent',
  // generic stuff like header clicks, link clicks, footer clicks
  GENERIC = 'genericGAEvent',
  ORDER_CONFIRMATION = 'orderConfirmation',
}
export type GtmData = { [key: string]: any };

export type GtmPageViewData = {
  pagename: string;
  genericpagename: string;
  category: string;
} & GtmData;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public readonly landingPageInputControl: string[] = [
    'postcode',
    'applianceType',
    'applianceBrand',
  ];
  public readonly aboutFaultInputControl: string[] = ['modelNumber'];
  public readonly customerDetailsInputControl: string[] = [
    'firstName',
    'lastName',
    'email',
    'confirmEmail',
    'mobile',
    'landline',
  ];
  public readonly paymentDetailsInputControl: string[] = [
    'accountHolder',
    'accountNumber',
    'sortCode',
  ];

  constructor() {
    if (window && !Object.hasOwnProperty.call(window, 'dataLayer')) {
      window.dataLayer = window.dataLayer || [];
      // throw new Error('`window.dataLayer` is not defined.');
    }
  }

  public booleanToWord(booleanWord: boolean): string {
    if (booleanWord === false) {
      return 'no';
    }
    return 'yes';
  }

  public push(data: GtmData): void {
    // uncomment for debugging only
    console.log('%c🕵️ Analytics Service', 'font-weight:bold; font-size:18px; margin-bottom:8px;', {
      ...data,
    });
    if (window && Object.hasOwnProperty.call(window, 'dataLayer')) {
      window['dataLayer'].push({ journey: 'experiment', ...data });
    }
  }

  public trigger(event: GtmEvent, data?: GtmData): void {
    this.push({ event, ...data });
  }
  public pageView(data: GtmPageViewData) {
    this.push({ event: GtmEvent.PAGEVIEW, ...data });
  }

  /**
   * triggerInputEvent - triggers for only input elements
   * @param controlName - Input control name
   * @param formGroup - Form Group
   */
  public triggerInputEvent(controlName: string, formGroup: FormGroup): void {
    let data = {
      eventCategory: '',
      eventAction: `${controlName}`,
      eventLabel: '',
    } as GtmData;

    if (this.aboutFaultInputControl.indexOf(controlName) > -1) {
      data.eventLabel = 'completed';
      data.eventCategory = 'tell-us-about-the-fault';
    } else if (this.customerDetailsInputControl.indexOf(controlName) > -1) {
      data.eventLabel = 'completed';
      data.eventCategory = 'your-details';
    } else if (this.paymentDetailsInputControl.indexOf(controlName) > -1) {
      data.eventLabel = 'completed';
      data.eventCategory = 'payment-details';
    } else if (this.landingPageInputControl.indexOf(controlName) > -1) {
      data.eventCategory = 'quote-search';
      if (controlName === 'postcode') {
        data.eventLabel = 'completed';
      } else if (controlName === 'applianceBrand' && formGroup.value[controlName]) {
        data.eventLabel = formGroup.value[controlName].name;
      } else {
        data.eventLabel = formGroup.value[controlName];
      }
    } else if (controlName === 'modelNumberAutoComplete') {
      const modelNumber = formGroup.get('modelNumberAutoComplete');
      if (modelNumber && modelNumber.valid) {
        data = {
          eventCategory: 'modelNumberEntered',
          eventAction: 'modelNumberAutoComplete',
          eventLabel: modelNumber.value.modelLabel,
          ModelNumber: modelNumber.value.modelLabel,
        };
      }
    }

    if (formGroup && formGroup.value[controlName]) {
      this.trigger(GtmEvent.QUOTE, data);
    }
  }

  /**
   * getValidationErrorMessage - get validation error displayed on UI
   * @param formGroup - to get error name
   * @param formData - to get error message and equating to error name from invalid formGroup control
   */
  public getValidationErrorMessage(formGroup: FormGroup, formDataGroup: any): string[] {
    const validateMessage: string[] = [];
    const controls = formGroup.controls;

    for (const name in controls) {
      // formGroup.get('applianceBrand').setErrors({ required: true });
      if (formGroup.get(name)?.errors) {
        const errorKey = formGroup.get(name)?.errors;
        let error!: string;
        if (errorKey) {
          const errorObj = Object.keys(errorKey);
          error = errorObj[0];
        }

        if (error) {
          validateMessage.push(
            formDataGroup
              .filter((formData: any) => {
                if (formData.select) {
                  return formData.select.control === name;
                } else if (formData.input) {
                  return formData.input.control === name;
                } else if (formData.radios) {
                  const radioData = formData.radios;
                  if (radioData.textarea.control === name) {
                    return radioData.textarea.control === name;
                  } else if (radioData.control === name) {
                    return radioData.control === name;
                  }
                } else if (formData.inputFragment) {
                  return formData.inputFragment.control === name;
                } else if (formData.checkbox) {
                  return formData.checkbox.control === name;
                } else if (formData.autocomplete) {
                  return formData.autocomplete.control === name;
                } else if (formData.addressLookup) {
                  return formData.addressLookup.map((addressLookupData: any) => {
                    if (addressLookupData.select) {
                      return addressLookupData.select.control === name;
                    } else if (addressLookupData.input) {
                      return addressLookupData.input.control === name;
                    }
                    return;
                  });
                }
              })
              .map((formData: any) => {
                if (formData.select) {
                  return formData.select.validators;
                } else if (formData.input) {
                  return formData.input.validators;
                } else if (formData.radios) {
                  if (
                    formData.radios.textarea.validators &&
                    formData.radios.textarea.control === name
                  ) {
                    return formData.radios.textarea.validators;
                  } else if (formData.radios.validators && formData.radios.control === name) {
                    return formData.radios.validators;
                  }
                } else if (formData.inputFragment) {
                  return formData.inputFragment.validators;
                } else if (formData.checkbox) {
                  return formData.checkbox.validators;
                } else if (formData.autocomplete) {
                  return formData.autocomplete.validators;
                } else if (formData.addressLookup) {
                  return formData.addressLookup.map((addressLookupData: any) => {
                    if (
                      addressLookupData.select &&
                      addressLookupData.select.validators &&
                      addressLookupData.select.control === name
                    ) {
                      return addressLookupData.select.validators;
                    } else if (
                      addressLookupData.input &&
                      addressLookupData.input.validators &&
                      addressLookupData.input.control === name
                    ) {
                      return addressLookupData.input.validators;
                    }
                  });
                }
              })
              .filter((validators: any) => validators && validators.length > 0)
              .map((validators: any) => {
                let validatorsArray;
                if (validators && validators.length > 0) {
                  for (let i = 0; i < validators.length; i++) {
                    if (validators[i]) {
                      if (validators[i].message) {
                        validatorsArray = validators;
                      } else if (
                        validators[i][0].message.indexOf('Address') > -1 ||
                        validators[i][0].message.indexOf('Town') > -1
                      ) {
                        validatorsArray = validators[i];
                      }
                    }
                  }
                }
                if (validatorsArray && validatorsArray.length > 0) {
                  return validatorsArray
                    .filter((validator: any) => {
                      if (validator && validator.type === error) {
                        return validator.type;
                      }
                    })
                    .map((validator: any) => validator.message);
                }
              })
              .toString()
          );
        }
      }
    }
    return validateMessage;
  }

  public getValidationErrorMessages(
    group: FormGroup | FormArray,
    cmsFormData: CmsFormField<string>[]
  ): string[] {
    const validationMessages: string[] = [];
    getValidationErrors(group, cmsFormData);

    function getValidationErrors(
      group: FormGroup | FormArray,
      cmsFormData: CmsFormField<string>[]
    ) {
      Object.keys(group.controls).forEach((key: string) => {
        const abstractControl: AbstractControl = (group.controls as {
          [key: string]: AbstractControl;
        })[key];

        if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
          getValidationErrors(abstractControl, cmsFormData);
        } else {
          if (
            (abstractControl as AbstractControl).invalid &&
            (abstractControl as AbstractControl).dirty
          ) {
            const cmsControl: CmsFormField<string> | undefined = cmsFormData.find(
              (x) => x.controlName === key
            );
            if (cmsControl && cmsControl.validationMessages) {
              validationMessages.push(cmsControl.validationMessages[0].message);
            }
          }
        }
      });
    }
    return validationMessages;
  }

  public getFormValidationErrorMessages(form: FormGroup, formFields: any): string[] {
    const result: string[] = [];
    Object.keys(form.controls).forEach((key) => {
      if (form && key && form.get(key)) {
        const controlErrors = form!.get(key)!.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach((keyError) => {
            const controlFieldDef = formFields.find(
              (field: { controlName: string }) => field.controlName === key
            );
            if (controlFieldDef) {
              const validationError = controlFieldDef.validationMessages.find(
                (message: { type: string }) => message.type === keyError
              );
              if (validationError && validationError.message) {
                result.push(validationError.message);
              }
            }
          });
        }
      }
    });

    return result;
  }
}
