<div *ngIf="inputFields && form" [formGroup]="form" class="book-a-repair__form">
  <dgx-dfb-type-ahead
    *ngIf="modelNumbersRequired && inputFields.modelNumber"
    [field]="inputFields.modelNumber"
    (analytics)="handleFieldAnalytics($event)"
    [validate]="triggerValidation"
    formControlName="modelNumber"
  ></dgx-dfb-type-ahead>
  <dgx-dfb-select
    [field]="inputFields.yearPurchased"
    (analytics)="handleFieldAnalytics($event)"
    [validate]="triggerValidation"
    formControlName="yearPurchased"
    (change)="onChangeYearPurchased($event)"
  ></dgx-dfb-select>
  <ng-container *ngIf="showForm">
    <dgx-dfb-select
      [field]="inputFields.purchasePrice"
      (analytics)="handleFieldAnalytics($event)"
      [validate]="triggerValidation"
      formControlName="purchasePrice"
    ></dgx-dfb-select>
    <ng-container *ngIf="showFaultCategory && inputFields.faultCategory">
      <dgx-dfb-select
        [field]="inputFields.faultCategory"
        (analytics)="handleFieldAnalytics($event)"
        [validate]="triggerValidation"
        formControlName="faultCategory"
      ></dgx-dfb-select>
    </ng-container>
    <!-- divs used as container to apply margin styles to all children of form -->
    <dgx-dfb-select
      [field]="inputFields.faultType"
      (analytics)="handleFieldAnalytics($event)"
      [validate]="triggerValidation"
      formControlName="faultType"
    ></dgx-dfb-select>
    <div>
      <dgx-dfb-textarea
        [field]="inputFields.faultDescription"
        [validate]="triggerValidation"
        formControlName="faultDescription"
      ></dgx-dfb-textarea>
    </div>
  </ng-container>
</div>
