<ui-panel class="order-confirmation__care-plan" [variant]="'highlight-feedback'">
  <ui-panel-header>
    <ui-panel-title [variant]="'normal'">
      <span *ngIf="!repairOneOff">Your Repair & Care plan</span>
      <span *ngIf="repairOneOff">Your One-off repair</span>
    </ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <div class="order-confirmation__cover-info" *ngIf="!repairOneOff">
      <div class="plan-details-panel__row plan-details-panel__row--top">
        <div class="plan-details-panel__row-item">
          <i class="icon icon-generic-appliance icon--lg plan-details-panel__icon"></i>
          <div>
            12-months protection for {{ applianceDetails?.applianceBrandName }}
            {{ applianceDetails?.applianceTypeName }}
          </div>
        </div>
        <i
          class="icon icon-tick icon--md plan-details-panel__icon plan-details-panel__icon--success"
        ></i>
      </div>
    </div>
    <div class="order-confirmation__engineer-info">
      <div class="plan-details-panel__row plan-details-panel__row--top">
        <div class="plan-details-panel__row-item">
          <i class="icon icon-generic-appliance icon--lg plan-details-panel__icon"></i>
          <div>
            Engineer
            <span *ngIf="!repairOneOff">reserved</span> for
            <span *ngIf="repairOneOff">booked </span>
            <strong>{{ repairDate | dateWithOrdinal: dateFormat }}, </strong> between
            <strong>{{ repairTime }}</strong>
          </div>
        </div>
        <i
          class="icon icon-tick icon--md plan-details-panel__icon plan-details-panel__icon--success"
        ></i>
      </div>
    </div>
    <hr />
    <div class="order-confirmation__plan-details">
      <!-- Create observable and loop values-->
      <ui-panel-display-info-row
        [direction]="'row'"
        [data]="{ key: 'Your plan number', value: planDetails?.planNumber }"
        *ngIf="!repairOneOff"
      ></ui-panel-display-info-row>

      <ui-panel-display-info-row
        [direction]="'row'"
        [data]="{ key: 'Total price', value: rate?.totalCost | currency: 'GBP' }"
      ></ui-panel-display-info-row>
      <ui-panel-display-info-row
        [direction]="'row'"
        [data]="{ key: 'Monthly payments', value: rate?.subsequentPayment | currency: 'GBP' }"
        *ngIf="!repairOneOff"
      ></ui-panel-display-info-row>
      <ui-panel-display-info-row
        [direction]="'row'"
        [data]="{ key: 'Plan start date', value: planDetails?.planStartDate | date: 'dd/MM/yy' }"
        *ngIf="!repairOneOff"
      ></ui-panel-display-info-row>
      <ui-panel-display-info-row
        [direction]="'row'"
        [data]="{ key: 'Payment method', value: paymentMethod }"
      ></ui-panel-display-info-row>
      <ui-panel-display-info-row
        [direction]="'row'"
        [data]="{
          key: 'Monthly payment date',
          value: (monthlyPaymentDate | dateWithOrdinal: dayOnly) + ' of each month'
        }"
        *ngIf="!repairOneOff"
      ></ui-panel-display-info-row>
      <p *ngIf="!repairOneOff">Your plan will start immediately and continue for 12 months</p>
    </div>
    <hr />
    <div class="plan-details-panel__rows">
      <ng-container *ngIf="!repairOneOff">
        <div class="plan-details-panel__row">
          <i class="icon icon-generic-appliance icon--lg plan-details-panel__icon"></i>
          <a
            class="link"
            target="_blank"
            href="assets/files/DG_Maitenance_and_Support_TCs.pdf"
            download="DG_Maitenance_and_Support_TCs.pdf"
            >Repair & care plan terms and conditions</a
          >
        </div>
        <div class="plan-details-panel__row">
          <i class="icon icon-generic-appliance icon--lg plan-details-panel__icon"></i>
          <a
            href="assets/files/DG_Accidental_Damage_TCs.pdf"
            class="link"
            target="_blank"
            download="DG_Accidental_Damage_TCs.pdf"
            >Repair & care plan accidental insurance policy terms and conditions</a
          >
        </div>
        <div class="plan-details-panel__row">
          <i class="icon icon-generic-appliance icon--lg plan-details-panel__icon"></i>
          <a href="assets/files/DG_IPID.pdf" class="link" target="_blank" download="DG_IPID.pdf"
            >Insurance product information document for accidental damage cover</a
          >
        </div>
      </ng-container>
      <ng-container *ngIf="repairOneOff">
        <div class="plan-details-panel__row">
          <i class="icon icon-generic-appliance icon--lg plan-details-panel__icon"></i>
          <a
            class="link"
            target="_blank"
            href="assets/files/DG_Maitenance_and_Support_TCs.pdf"
            download="DG_Maitenance_and_Support_TCs.pdf"
          >
            Repair Terms and Conditions
          </a>
        </div>
      </ng-container>
    </div>
  </ui-panel-body>
</ui-panel>
