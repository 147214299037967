<div
  *ngIf="group"
  [formGroup]="group"
  class="form-input"
  [ngClass]="{
    'is-invalid': !group.controls[item.control]?.valid && group.controls[item.control]?.touched,
    'is-valid': group.controls[item.control]?.valid && group.controls[item.control]?.touched
  }"
>
  <div *ngIf="tooltipVisible" class="tooltip-content">{{ tooltip.text }}</div>
  <randc-label *ngIf="label" [label]="label" [class]="label.classes" [forElement]="labelFor"
    >{{ label.text }}
  </randc-label>
  <randc-hint *ngIf="hint" [hint]="hint"></randc-hint>
  <div [class]="'form-control ' + item.controlClasses">
    <i
      *ngIf="tooltip && tooltip.enabled"
      class="icon icon-tooltip"
      (click)="tooltipVisible = !tooltipVisible"
      (blur)="tooltipVisible = false"
    ></i>
    <select
      #exampleSelect
      [formControlName]="item.control"
      [id]="item.control"
      [class]="item.classes"
      [attr.disabled]="item.disabled ? '' : null"
      (blur)="onBlur($event); showValidation = true"
      [value]="initValueIndex"
    >
      <option value="0" disabled selected>{{ item.placeholder }}</option>
      <option *ngFor="let o of item.options" [value]="o.id">{{ o.name }} {{ o.selected }}</option>
    </select>
    <i class="icon icon-tick-circle"></i>
    <i class="icon icon-error-circle"></i>
    <i class="icon icon-arrow-down"></i>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <randc-validation-error-formcontrol
      *ngIf="showValidation"
      [group]="group"
      [validators]="validators"
      [control]="item.control"
    ></randc-validation-error-formcontrol>
  </ng-container>
</div>
