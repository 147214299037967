export interface ApplianceDetails {
  applianceTypeCode: string;
  applianceTypeName: string;
  applianceBrandCode: string;
  applianceBrandName: string;
}

export interface AppliancePurchaseDetails {
  appliancePurchaseMonth: number;
  appliancePurchaseYear: number;
  appliancePurchasePrice: number;
}

export interface GetPlanDataResponse {
  Status: string;
  StatusCode: string;
  StatusDebugging: any[];
  PlanNumber: string;
  ApplianceData: [
    {
      Manufacturer: string;
      ManufacturerGroup: string;
      ModelNumber: string;
      SerialNumber: string;
      ProductType: string;
    }
  ];
  ClaimTypes: [
    {
      ClaimTypeID: string;
      ClaimTypeName: string;
      ClaimTypeLabel: string;
    }
  ];
  RequiredAttributes: [
    {
      AttributeName: string;
      DataCategory: string;
      UseGetData: boolean;
      Mandatory: boolean;
      AttributeValues: string[];
    }
  ];
}
export interface PutServiceOptionRequest {
  ClaimID: number;
  ServiceOptionID: number;
  ServiceOptionRequiredFields: any[];
  ChannelCode: string;
  CountryCode: string;
}

export interface QuoteRequest {
  currency: string;
  sessionId: string;
  postcode: string;
  slotId: string;
  purchaseMonth: number;
  purchaseYear: number;
  purchasePrice: number;
  manufacturerGuaranteeMonths: number;
}
export type IndicativeQuoteRequest = Omit<
  QuoteRequest,
  'purchaseMonth' | 'purchaseYear' | 'purchasePrice' | 'manufacturerGuaranteeMonths'
>;

export interface Quote {
  quoteId: string;
  coverType: string;
  periodOfCover?: number;
  productType?: string;

  //these are all relating to payment method
  //todo: move into sub object "paymentMethod(s)"
  numPayments: number;
  firstPayment: number;
  subsequentPayment: number;
  totalCost: number;
  //todo: add enum
  paymentType: string;
  paymentProfile?: string;
  paymentFrequency?: number;
}

export interface Basket {
  id: string;
  itemType: string;
  itemId: string;
  createdDate: string;
  expiry: string;
  quotes: Quote[];
}

export interface CalendarAvailabilityRequest {
  storeId: string;
  catalogId: string;
  countryCode: string;
  applianceCode: string;
  brandCode: string;
  postcode: string;
}

export interface CalendarAvailabilityResponse {
  availabilityDates: CalendarAvailabilityData[];
  sessionId?: string;
  scrollToken?: string;
  availabilityStartDate: string;
  availabilityEndDate: string;
  blockAvailabilityDates: CalendarAvailabilityData[];
}

export interface CalendarAvailabilityData {
  date: string;
  slots: {
    slotId: string;
    slotType: string;
    startTime?: string;
    endTime?: string;
    available: boolean;
  }[];
}

export interface SelectOptionsDataType {
  id: string;
  name: string;
}

export interface PopularItem {
  code: string;
  name: string;
  iconURL: string;
  heroURL?: string;
  routerPath?: string;
}

export interface ModelDetails {
  modelNumber?: string;
  serialNumber?: string;
  modelId?: string;
}

export interface FaultDetails {
  categoryId: string;
  faultId: string;
  description: string;
}

export interface AccessDetails {
  engineerAccess: boolean;
  engineerAccessDescription: string;
  parkingAccess: boolean;
  parkingAccessDescription: string;
}

export interface CustomerDetails {
  title?: string;
  initial?: string;
  firstName: string;
  surname: string;
  preferredName?: string;
  telephone?: CustomerTelephone;
  email: CustomerEmail;
  address: CustomerAddress;
  /**
   * Method for sending contract documents.
   */
  sendMethod: string;

  /**
   * Preferred method for general correspondence.
   */
  preferredContactMethod: string;
  marketingPreferences: MarketingPreferences;
}

export interface CustomerTelephone {
  mobile?: string;
  home?: string;
  work?: string;
  preferred?: 'H' | 'M' | 'W';
}

export interface CustomerEmail {
  main: string;
}

export interface CustomerAddress {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postalCode: string;
  countryCode: string;
}

// export enum PlanDocumentsSendMethod {
//   EMAIL = 'EMAIL',
//   POST = 'POST',
//   BOTH = 'BOTH',
// }
//
// export enum PreferredContactMethod {
//   EMAIL = 'EMAIL',
//   POST = 'POST',
//   TELEPHONE = 'TELEPHONE',
// }

export interface MarketingPreferences {
  post?: boolean;
  email?: boolean;
  telephone?: boolean;
  phone?: boolean;
}

export interface PaymentByDirectDebit {
  paymentType: 'D';
  directDebitDetails: PaymentByDirectDebitDetails;
  preferredPaymentDay: number;
}

export interface PaymentByDirectDebitDetails {
  sortCode: string;
  accountNumber: string;
  accountName: string;
}

export interface PaymentByDirectDebitToken {
  paymentType: 'D';
  directDebitPaymentToken: string;
  preferredPaymentDay: number;
}

export interface PaymentByCreditCard {
  paymentType: 'C';
  cardPaymentReference: string;
}

export enum PaymentType {
  DirectDebit = 'D',
  Card = 'C',
}

export interface ConsentDetails {
  email: boolean;
  phone: boolean;
  post: boolean;
  sms: boolean;
}

export interface PaymentDetails {
  accountName: string;
  accountNumber: string;
  sortCode: string;
  paymentDay: number;
  paymentToken?: string | null;
}

export interface Root {
  applianceLookupRoot: string;
  calendarRoot: string;
  faultRoot: string;
  modelSearchRoot: string;
  contractRoot: string;
  cmsContentRoot: string;
}

export interface FaultArrayCategory {
  categoryId: string;
  label: string;
  faultTypes: FaultArrayType[];
}

export interface FaultArrayType {
  faultId: string;
  label: string;
}

export interface FaultArrayResponse {
  status: string;
  faultCategories: FaultArrayCategory[];
  modelNumberRequired: boolean;
}

export interface ModelSearchResponse {
  models: {
    modelId: string;
    modelLabel: string;
  }[];
}

export interface ApplianceTile {
  code: ApplianceTileEnum;
  label: string;
  iconURL: string;
}

export enum ApplianceTileEnum {
  'Washing machine' = 'AW',
  'Dishwasher' = 'DW',
  'Fridge freezer' = 'FF',
  'Other appliances' = 'Other',
}

export enum ApplianceTypes {
  CN,
  FR,
  WD,
  EA,
  FZ,
  AW,
  FF,
  TD,
  DW,
  CH,
  CK,
  EC,
  DO,
  FS,
  HB,
  OV,
  RQ,
  EJ,
  EH,
  E0,
  R0,
  EO,
}

export interface OverrideAction {
  action(tabId: string): any;
  params: string;
}
