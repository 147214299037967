<div class="hero">
  <div class="hero__body">
    <div class="hero__body--desktop">
      <div class="hero__title">
        {{ mainHeroDetails?.heroTitle }}
      </div>
      <div class="hero__description">
        <h1>{{ mainHeroDetails?.heroDescription }}</h1>
      </div>
    </div>
    <div
      *ngIf="mainHeroDetails?.heroImageTablet"
      [ngStyle]="{
        'background-image': 'url(' + imageUrl + mainHeroDetails.heroImageTablet['@link'] + ')'
      }"
      class="hero__body--tablet hero__body--mobile"
    >
      <div class="hero__body-inner">
        <div class="hero__title">{{ mainHeroDetails?.heroTitle }}</div>
        <div class="hero__description">
          <h1>{{ mainHeroDetails?.heroDescription }}</h1>
        </div>
      </div>
    </div>
    <div class="hero__list">
      <ng-content select="ui-list"></ng-content>
    </div>
    <div class="hero__links">
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="mainHeroDetails?.heroImageTablet" class="hero__desktop-image">
    <img alt="" src="{{ imageUrl }}{{ mainHeroDetails.heroImageTablet['@link'] }}" srcset="" />
  </div>
</div>
