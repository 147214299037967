export const mockPopularBrands = [
  {
    code: 'WHIR',
    name: 'Whirlpool',
    iconURL: 'assets/images/icons/appliance_brand/WHIR.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-WHIR.jpg',
  },
  {
    code: 'SIEM',
    name: 'Siemens',
    iconURL: 'assets/images/icons/appliance_brand/SIEM.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-SIEM.jpg',
  },
  {
    code: 'SAMS',
    name: 'Samsung',
    iconURL: 'assets/images/icons/appliance_brand/SAMS.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-SAMS.jpg',
  },
  {
    code: 'NEFF',
    name: 'Neff',
    iconURL: 'assets/images/icons/appliance_brand/NEFF.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-NEFF.jpg',
  },
  {
    code: 'INDE',
    name: 'Indesit',
    iconURL: 'assets/images/icons/appliance_brand/INDE.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-INDE.jpg',
  },
  {
    code: 'HOTP',
    name: 'Hotpoint',
    iconURL: 'assets/images/icons/appliance_brand/HOTP.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-HOTP.jpg',
  },
  {
    code: 'HOOV',
    name: 'Hoover',
    iconURL: 'assets/images/icons/appliance_brand/HOOV.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-HOOV.jpg',
  },
  {
    code: 'BOSC',
    name: 'Bosch',
    iconURL: 'assets/images/icons/appliance_brand/BOSC.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-BOSC.jpg',
  },
  {
    code: 'AEG',
    name: 'AEG',
    iconURL: 'assets/images/icons/appliance_brand/AEG.svg',
    heroURL: 'assets/images/hero/appliance_brand/hero-AEG.jpg',
  },
];
