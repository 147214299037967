import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoaderComponent } from './loader.component';

@Injectable({
  providedIn: 'root',
})
export class LoaderOverlayService {
  private overlayRef!: OverlayRef;

  constructor(private overlay: Overlay) {}

  public show(message = '') {
    // Returns an OverlayRef (which is a PortalHost)
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }
    // Create ComponentPortal that can be attached to a PortalHost
    // run in async context for triggering "tick", thus avoid ExpressionChangedAfterItHasBeenCheckedError
    this.overlayRef.attach(new ComponentPortal(LoaderComponent)); // Attach ComponentPortal to PortalHost
  }

  public hide() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
