import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { APIFaultArrayResponse, FaultArrayResponse } from '../models';
import { BuildConfigService } from './build.config.service';
import { ApiHelper } from '../helpers';
import { BuildConfig } from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class FaultService {
  constructor(
    private _http: HttpClient,
    private buildConfigService: BuildConfigService,
    private apiHelper: ApiHelper
  ) {}

  public modelNumber!: string;
  public modelNumberRequired!: boolean;

  public get config(): BuildConfig {
    return this.buildConfigService.config;
  }

  private _handleError(err: HttpErrorResponse) {
    let errMsg = '';
    if (err.error instanceof ErrorEvent) {
      errMsg = `Error occured in front-end ${err.error.message}`;
    } else {
      errMsg = `Backend returned ${err.status}, body was ${err.error.message}`;
    }
    if (err.error.status && err.error.message) {
      return throwError(err.error);
    } else {
      return throwError({ status: err.status, message: err.message });
    }
  }

  public getFault(sessionId: string): Observable<FaultArrayResponse> {
    return this._http
      .post<APIFaultArrayResponse>(
        `${this.config.faultRoot}/v1/get-fault-array`,
        {
          sessionId: sessionId,
        },
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        map((response: APIFaultArrayResponse) => {
          return {
            status: response.status,
            faultCategories: response.result.faultCategories,
            modelNumberRequired: response.result.modelNumberRequired,
          };
        }),
        catchError(this._handleError)
      );
  }
}
