import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'randc-spa-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Output() handleWindowTapNotify: EventEmitter<HTMLElement> = new EventEmitter();
  @Output() handleOpenChat: EventEmitter<unknown> = new EventEmitter();

  ngOnInit() {
    this.handleWindowTapNotify.emit(document.documentElement);
  }

  openChatWindow() {
    this.handleOpenChat.emit();
  }
}
