import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CheckboxComponent } from '@domgen/dgx-fe-dynamic-form-builder';

@Component({
  selector: 'randc-dgx-dfb-accept-terms',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptComponent extends CheckboxComponent {
  @Input() privacyPolicyLink = 'https://domesticandgeneral.com/shop/en/dg/privacy-cookies-policy';
}
