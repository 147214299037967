import { Component, Input } from '@angular/core';

@Component({
  selector: 'randc-spa-fault-details-panel',
  templateUrl: './fault-details-panel.component.html',
  styleUrls: ['./fault-details-panel.component.scss'],
})
export class FaultDetailsPanelComponent {
  @Input() faultDetails: any;
}
