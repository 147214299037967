import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CmsTestimonial, TrustBox } from '@common/util-base';

@Component({
  selector: 'randc-customer-testimonial',
  templateUrl: './customer-testimonial.component.html',
  styleUrls: ['./customer-testimonial.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerTestimonialComponent {
  @Input() data: CmsTestimonial | undefined;
  public trustboxType = TrustBox.MICRO;
}
