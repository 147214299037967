<dgx-ui-wl-header
  clientLogoPath="assets/images/ao-logo.svg"
  [navigationLinks]="$any(headerNavigationLinks)"
  (clicked)="handleHeaderLinkClicked($event)"
  [poweredByLinkUrl]="null"
></dgx-ui-wl-header>
<common-app></common-app>
<dgx-ui-wl-ao-footer
  [protectLinks]="footerProtectLinks"
  [legalLinks]="footerLegalLinks"
  [usefulLinks]="footerUsefulLinks"
  (clicked)="handleFooterLinkClicked($event)"
></dgx-ui-wl-ao-footer>
