<div class="text--center error-wrapper mb-2 {{ classes }}">
  <p>This page is unavailable at the moment. We’d love to talk to you instead.</p>
  <h4 class="mt-1 row--spacer-bottom-small">Give us a call on</h4>
  <h2 class="row--spacer-bottom-medium">
    <span
      ><a class="rTapNumber4053" href="tel:08005614489" (click)="triggerPhoneClickGA('08005614489')"
        >0800 561 4489</a
      ></span
    >
  </h2>

  <randc-phone-calls-disclaimer></randc-phone-calls-disclaimer>
</div>
