import { CmsFormResult, CmsPage, CmsResult } from '@common/util-base';
import { LANDING_PAGE_DATA } from './landing-page.data';
import { CHECKOUT_PAYMENT_FORM } from './checkout-payment-form.data';
import { DIRECT_DEBIT_GUARANTEE } from './direct-debit-guarantee.data';

export const CMS_DATA: CmsData = {
  [CmsPage.Landing]: { ...LANDING_PAGE_DATA },
  [CmsPage.CheckoutPayment]: { ...CHECKOUT_PAYMENT_FORM },
  [CmsPage.DirectDebitGuarantee]: { ...DIRECT_DEBIT_GUARANTEE },
};

export interface CmsData {
  [CmsPage.Landing]: CmsResult;
  [CmsPage.CheckoutPayment]: CmsFormResult;
  [CmsPage.DirectDebitGuarantee]: CmsResult;
}
