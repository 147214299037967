import { Component, Input } from '@angular/core';
import { FormBuilderHint } from '@common/util-base';

@Component({
  selector: 'randc-hint',
  templateUrl: './hint.component.html',
})
export class HintComponent {
  @Input() hint!: FormBuilderHint;
}
