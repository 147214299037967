import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { filter, take, tap, withLatestFrom } from 'rxjs/operators';
import { RemoteDataState } from '@common/util-base';
import { QuoteFacade } from './quote.facade';
import { CalendarFacade } from './calendar.facade';

@Injectable({ providedIn: 'root' })
export class AvailabilityDatesResolver implements Resolve<any> {
  constructor(
    private quoteFacade: QuoteFacade,
    private calendarFacade: CalendarFacade,
    private router: Router
  ) {}

  resolve() {
    return this.quoteFacade.availabilityDatesRemoteState$.pipe(
      filter((state) => this.filterSuccessOrError(state)),
      tap((itemsLoadedState) => this.handleError(itemsLoadedState)),
      withLatestFrom(this.calendarFacade.calendarAvailability$, this.calendarFacade.scrollToken$),
      tap(([remoteState, availability, scrollToken]) => {
        if (
          remoteState === RemoteDataState.OK &&
          !availability.availabilityDates.length &&
          !scrollToken
        ) {
          this.router.navigate(['/engineer-not-found']);
        }
      }),
      take(1)
    );
  }

  private filterSuccessOrError(state: RemoteDataState) {
    if (state === RemoteDataState.OK || state === RemoteDataState.Error) {
      return true;
    }
    return false;
  }

  private handleError(itemsLoadedState: RemoteDataState) {
    if (itemsLoadedState === RemoteDataState.Error) {
      this.router.navigate(['/generic-error']);
    }
  }
}
