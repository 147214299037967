import { CmsFormField } from '@domgen/dgx-fe-business-models';
import { CmsQuoteData, CmsResult, CmsParagraphReferences } from '@common/util-base';

export const mapCmsFormData = (cmsData: { results: CmsFormField[] }): CmsFormField[] => {
  return cmsData.results;
};

export const mapCmsQuoteLandingData = (cmsData: CmsResult): CmsQuoteData => {
  const results = cmsData?.results[0];
  const cancellations = results.paragraphReferences?.filter(
    (x: CmsParagraphReferences) => x['@name'] === 'cancellation'
  );
  const coverForLifesMishaps = results.paragraphReferences?.filter(
    (x: CmsParagraphReferences) => x['@name'] === 'cover-for-lifes-mishaps'
  );
  const thingsYouNeedToKnows = results.paragraphReferences?.filter(
    (x: CmsParagraphReferences) => x['@name'] === 'things-you-need-to-know'
  );

  const policyFeaturesReferences =
    results && results.policyFeaturesReferences ? results.policyFeaturesReferences[0] : undefined;

  const brandOneOffRepairs = results.paragraphReferences;

  const cmsQuoteData = {
    cancellation: cancellations.length ? cancellations[0] : undefined,
    coverForLifesMishaps: coverForLifesMishaps.length ? coverForLifesMishaps[0] : undefined,
    thingsYouNeedToKnow: thingsYouNeedToKnows.length ? thingsYouNeedToKnows[0] : undefined,
    policyFeaturesReferences: policyFeaturesReferences ? policyFeaturesReferences : undefined,
    brandOneOffRepairs: brandOneOffRepairs,
  } as CmsQuoteData;

  return cmsQuoteData;
};
