<form *ngIf="pageItems" [formGroup]="formDraft" (ngSubmit)="onSubmit()">
  <div
    [ngClass]="{
      'form-group': true,
      'form-group--error': useValidation && formDraft.invalid
    }"
  >
    <h1 class="heading-xl">
      <span *ngIf="pageItems.caption" [class]="pageItems.caption.classes">{{
        pageItems.caption.text
      }}</span>
      {{ pageItems.header }}
    </h1>
    <div class="form-group" *ngFor="let group of pageItems.groups">
      <randc-fieldset
        *ngIf="group.fieldset"
        [classes]="'fieldset'"
        [group]="formDraft"
        [data]="group.fieldset"
        [validate]="useValidation"
      >
      </randc-fieldset>
      <randc-form-elements
        [group]="formDraft"
        [validate]="useValidation"
        [data]="group"
      ></randc-form-elements>
    </div>
  </div>
</form>
