import { Injectable } from '@angular/core';
import { ModelDetails } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ObjectsCleansingHelper {
  public removeEmptyValues = (obj: ModelDetails): ModelDetails => {
    const acc: ModelDetails = {};
    for (const key in obj) {
      if (obj.serialNumber !== '') acc.serialNumber = obj.serialNumber;
      if (obj.modelNumber !== '') acc.modelNumber = obj.modelNumber;
      if (obj.modelId !== '') acc.modelId = obj.modelId;
    }
    return acc;
  };
}
