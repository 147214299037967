import { Injectable } from '@angular/core';
import { AppliancePurchaseDetails, filterNullUndefined } from '@common/util-base';
import { QuoteFacade } from '@common/data-access-quote';
import { BookingDetailsFacade } from '@common/data-access-booking-details';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuoteHelperService {
  private payload?: {
    sessionId: string;
    postcode: string;
    slotId: string;
    purchaseMonth: number;
    purchaseYear: number;
    purchasePrice: number;
  };

  constructor(
    private quoteFacade: QuoteFacade,
    private bookingDetailsFacade: BookingDetailsFacade
  ) {
    combineLatest([
      this.quoteFacade.postcode$,
      this.quoteFacade.slotId$,
      this.quoteFacade.sessionId$,
      this.bookingDetailsFacade.appliancePurchase$.pipe(filterNullUndefined()),
    ]).subscribe(
      ([
        postcode,
        slotId,
        sessionId,
        { appliancePurchaseMonth, appliancePurchaseYear, appliancePurchasePrice },
      ]) => {
        this.payload = {
          postcode: postcode,
          slotId: slotId,
          sessionId: sessionId,
          purchaseMonth: +appliancePurchaseMonth,
          purchaseYear: +appliancePurchaseYear,
          purchasePrice: +appliancePurchasePrice,
        };
      }
    );
  }

  getQuote() {
    if (!this.payload) {
      throw new Error('Missing required information');
    }
    const quotePayload = {
      itemType: 'QUOTERC',
      manufacturerGuaranteeMonths: 12,
      currency: 'GBP',
      warranty: 12,
      ...this.payload,
    };
    this.quoteFacade.getQuote(quotePayload);
  }
}
