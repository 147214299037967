import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TrustBox } from '@common/util-base';

@Component({
  selector: 'randc-trustbox',
  templateUrl: './trustbox.component.html',
  styleUrls: ['./trustbox.component.scss'],
})
export class TrustboxComponent implements AfterViewInit {
  @ViewChild('microRef', { static: false }) microRef: ElementRef | undefined;
  @ViewChild('sliderRef', { static: false }) sliderRef: ElementRef | undefined;
  TrustBox = TrustBox;
  type: TrustBox = TrustBox.SLIDER;

  @Input() templateIdForSlider = '54ad5defc6454f065c28af8b';
  @Input() templateIdForMicro = '5419b6ffb0d04a076446a9af';
  @Input() businessUnitId = '4cb3113600006400050dfb02';
  @Input() title?: string = '';
  @Input() set trustBoxType(type: TrustBox) {
    if (type) {
      this.type = type;
    }
  }

  ngAfterViewInit() {
    const trustboxRef =
      this.type === TrustBox.MICRO ? this.microRef?.nativeElement : this.sliderRef?.nativeElement;
    if (window && window.Trustpilot && window.ScullyIO !== 'running') {
      window.Trustpilot.loadFromElement(trustboxRef);
    }
  }
}
