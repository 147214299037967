import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CmsTitleDescItem } from '@common/util-base';

@Component({
  selector: 'randc-expert-repair',
  templateUrl: './expert-repair.component.html',
  styleUrls: ['./expert-repair.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertRepairComponent {
  @Input() data: CmsTitleDescItem | null = null;
  @Input() buttonText = '';
  @Output() bookARepairClick: EventEmitter<void> = new EventEmitter();
}
