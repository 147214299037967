import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormsService } from '@common/util-base';

@Component({
  selector: 'randc-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  public formDraft!: FormGroup;
  pageValues: any;
  pageItems: any;
  useValidation = false;

  @Input() data: any;
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasErrors: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() customButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private formsService: FormsService) {}

  createForm(pageItems: any, pageValues: any) {
    this.formDraft = new FormGroup(this.formsService.defineformControls(pageItems, pageValues));
  }

  onCreateForm() {
    this.pageItems = this.data.meta;
    this.pageValues = this.data.formValues || {};
    this.createForm(this.pageItems, this.pageValues);
  }

  ngOnInit() {
    this.onCreateForm();
  }

  onSubmit() {
    if (this.formDraft.invalid) {
      this.useValidation = true;
      this.hasErrors.emit(true);
      return;
    } else {
      this.data.formValues = this.formDraft.value;
      this.submitForm.emit(this.data.formValues);
    }
  }
}
