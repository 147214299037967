<div class="container spacing-min-lg" *ngIf="data">
  <div class="xs--stack lg-row">
    <div class="xs-12 lg-6">
      <ui-picture aspectRatio="4:3" [src]="data.imageDesktop['@link']" [lazy]="true"></ui-picture>
    </div>
    <div class="xs-12 lg-6 pt-2 lg-pt-0 testimonial__quote">
      <p class="typog-h3">
        {{ data.description }}
      </p>
      <a class="link typog-body-large-link mt-1" [href]="data.allReviewsLink" target="_blank">{{
        data.trustpilotReviewText
      }}</a>
    </div>
  </div>
  <div class="text--center mt-2">
    <a class="link typog-body-large-link" [href]="data.allReviewsLink">{{ data.readMoreText }}</a>
  </div>
  <randc-trustbox class="mt-2" [trustBoxType]="trustboxType"></randc-trustbox>
</div>
