export const LANDING_PAGE_DATA = {
  results: [
    {
      '@name': 'quote-page',
      '@path': '/quote-page',
      '@id': '902045a5-98d3-4544-825f-d91bd711d9ab',
      '@nodeType': 'randc-combine-content-app',
      paragraphReferences: [
        {
          '@name': 'cover-for-lifes-mishaps',
          '@path': '/repair-and-care/cover-for-lifes-mishaps',
          '@id': '8add7d89-52d6-4439-9a73-a4a20da1c24e',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>Repair &amp; Care includes accidental damage cover, provided under a seperate policy of insurance by Domestic &amp; General Insurance PLC, part of our Domestic &amp; General Group. For more information, please read the <a href="${link:{uuid:{6d3560c6-5168-4066-9769-a232a0192668},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_IPID.pdf}}}">Insurance Product Information Document</a></p>\n',
          title: "Plus, cover for life's mishaps",
          jcrName: 'cover-for-lifes-mishaps',
          '@nodes': [],
        },
        {
          '@name': 'things-you-need-to-know',
          '@path': '/repair-and-care/things-you-need-to-know',
          '@id': '88d927f4-5574-4553-a8aa-206264a00ae3',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>The accidental damage insurance policy is designed to meet the demands and needs of those who wish to insure electronic appliances and/or household goods (if repaired during the initial repair under the maintenance and support plan) against accidental damage.</p>\n\n<p>For your own benefit and protection, please read the <a href="${link:{uuid:{9e6cfef5-7120-4934-9328-11a91ba05e0a},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Maitenance_and_Support_TCs.pdf}}}">Repair &amp; Care Plans Terms and Conditions</a>, <a href="${link:{uuid:{5b163969-fb28-4def-8776-c7d341925688},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Accidental_Damage_TCs.pdf}}}">Repair &amp; Care Accidental Damage Insurance Policy Terms and Conditions</a> and <a href="https://www.domesticandgeneral.com/privacy-cookies-policy">Privacy Policy</a>&nbsp;</p>\n\n<p>Full details on how to submit a complaint can be found in the Repair &amp; Care Plan Terms and Conditions.</p>\n',
          title: 'Things you need to know',
          jcrName: 'things-you-need-to-know',
          '@nodes': [],
        },
        {
          '@name': 'cancellation',
          '@path': '/repair-and-care/cancellation',
          '@id': '5c3da085-3eb4-452c-a515-24440f17ba55',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>You can cancel your plan at any time. However, you&#39;ll be charged for any repairs we&#39;ve carried out (including the call-out-fee). Please note, you&#39;ll be charged for the full plan if we&#39;ve replaced appliance. Fees will not exceed the price of the plan. For further details, see the <a href="${link:{uuid:{9e6cfef5-7120-4934-9328-11a91ba05e0a},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Maitenance_and_Support_TCs.pdf}}}">Repair &amp; Care Plan Terms and Conditions</a> and the <a href="${link:{uuid:{5b163969-fb28-4def-8776-c7d341925688},repository:{dam},path:{/dgx-repair-and-care/pdf/DG_Accidental_Damage_TCs.pdf}}}">Repair &amp; Care Accidental Damage Insurance Policy Terms and Conditions</a>.</p>\n',
          title: 'Cancellation',
          jcrName: 'cancellation',
          '@nodes': [],
        },
        {
          '@name': 'hotpoint',
          '@path': '/repair-and-care/enginepair/hotpoint',
          '@id': '7b629b59-a92b-4ead-849b-eb47209b2f6a',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by Hotpoint engineer. Based on your appliance (non-dryer) being 1&ndash;5 years old and out of guarantee with a 10 Year Parts Guarantee. Price correct as of April 2021.</p>\n',
          title: '119.99',
          jcrName: 'hotpoint',
          '@nodes': [],
        },
        {
          '@name': 'aeg',
          '@path': '/repair-and-care/one-off-repair/aeg',
          '@id': '64b175c5-351e-41b4-8710-f4e1d000f84d',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by AEG engineer. Based on your appliance being up to 8 years old. Price correct as of April 2021.</p>\n',
          title: '140.00',
          jcrName: 'aeg',
          '@nodes': [],
        },
        {
          '@name': 'candy',
          '@path': '/repair-and-care/one-off-repair/candy',
          '@id': '618780f4-f786-410d-ae08-f6cdd73980a3',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by Hoover Candy engineer. Based on your appliance being up to 10 years old. Price correct as of April 2021.</p>\n',
          title: '129.00',
          jcrName: 'candy',
          '@nodes': [],
        },
        {
          '@name': 'indesit',
          '@path': '/repair-and-care/one-off-repair/indesit',
          '@id': '7939a2fc-ee82-4f92-8b1f-7e410fc68984',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by Hotpoint engineer. Based on your appliance (non-dryer) being 1&ndash;5 years old and out of guarantee with a 10 Year Parts Guarantee. Price correct as of April 2021.</p>\n',
          title: '119.99',
          jcrName: 'indesit',
          '@nodes': [],
        },
        {
          '@name': 'whirlpool',
          '@path': '/repair-and-care/one-off-repair/whirlpool',
          '@id': 'e69a2cf1-491b-4b14-b394-380f35c98e5a',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by Hotpoint engineer. Based on your appliance (non-dryer) being 1&ndash;5 years old and out of guarantee with a 10 Year Parts Guarantee. Price correct as of April 2021.</p>\n',
          title: '119.99',
          jcrName: 'whirlpool',
          '@nodes': [],
        },
        {
          '@name': 'zanussi',
          '@path': '/repair-and-care/one-off-repair/zanussi',
          '@id': '1f2890f5-2a84-4769-aef4-14a81859d6fd',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by Zanussi engineer. Based on your appliance being up to 8 years old. Price correct as of April 2021.</p>\n',
          title: '130.00',
          jcrName: 'zanussi',
          '@nodes': [],
        },
        {
          '@name': 'haier',
          '@path': '/repair-and-care/one-off-repair/haier',
          '@id': '02ede095-c4de-476d-9bcf-d668038c547e',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by Haier engineer. Based on your appliance being up to 10 years old. Price correct as of April 2021.&nbsp;</p>\n',
          title: '129.00',
          jcrName: 'haier',
          '@nodes': [],
        },
        {
          '@name': 'nff',
          '@path': '/repair-and-care/one-off-repair/nff',
          '@id': 'e28b4a37-e330-41f9-82b7-37b2c2eb3613',
          '@nodeType': 'dgx-paragraph',
          paragraphContent: '<p>*Price correct as of April 2021.</p>\n',
          title: '64.50',
          jcrName: 'nff',
          '@nodes': [],
        },
        {
          '@name': 'baumatic',
          '@path': '/repair-and-care/one-off-repair/baumatic',
          '@id': 'cdea3fec-2b85-41c8-8d93-cd9dc35b7ec6',
          '@nodeType': 'dgx-paragraph',
          paragraphContent:
            '<p>*One-off repair by Baumatic engineer. Based on your appliance being up to 5 years old. Price correct as of April 2021.</p>\n',
          title: '129.00',
          jcrName: 'baumatic',
          '@nodes': [],
        },
      ],
      policyFeaturesReferences: [
        {
          '@name': 'randc-quote-policy-features',
          '@path': '/randc-quote-policy-features',
          '@id': '96013ef4-eaa7-487a-9aab-68779f9ddb19',
          '@nodeType': 'dgx-policy-feature',
          included: [
            "Our 'no fix, no fee' guarantee",
            'Exclusive access to our Appliance Care portal for tips and advice',
            'A remote annual health check',
            'Set-up and ongoing support to keep your appliance running smoothly',
            'Unlimited phone support from our Appliance Care Team',
            'Repairs by an expert engineer if your appliance fails the annual health check or stops working',
          ],
          ipidAsset: 'jcr:6d3560c6-5168-4066-9769-a232a0192668',
          excluded: [
            'Using your appliance for commercial purposes',
            'Neglect, cosmetic and malicious damage',
            'Disposing of the original product and installation charges if your appliance is replaced',
            'Any refund from not following the manufacturer’s instructions or not being unable to use your product',
          ],
          jcrName: 'randc-quote-policy-features',
          '@nodes': [],
        },
      ],
      formReferences: [],
      jcrName: 'quote-page',
      '@nodes': [],
    },
  ],
};
