import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './components/app/app.component';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, SharedUiBaseComponentsModule, RouterModule],
  declarations: [AppComponent],
  exports: [AppComponent],
})
export class CommonFeatureAppModule {}
