import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  APIQuoteResponse,
  APICreateContractRequest,
  APIQuoteRequest,
  APICheckOutResponse,
  Basket,
} from '../models';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiHelper } from '../helpers';
import { BuildConfigService } from './build.config.service';
import { BuildConfig } from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class CreateContractService {
  constructor(
    private _http: HttpClient,
    private buildConfigService: BuildConfigService,
    private apiHelper: ApiHelper
  ) {}

  public get config(): BuildConfig {
    return this.buildConfigService.config;
  }

  private _handleError(err: HttpErrorResponse) {
    let errMsg = '';
    if (err.error instanceof ErrorEvent) {
      errMsg = `Error occured in front-end ${err.error.message}`;
    } else {
      errMsg = `Backend returned ${err.status}, body was ${err.error.message}`;
    }
    if (err.error.status && err.error.message) {
      return throwError(err.error);
    } else {
      return throwError({ status: err.status, message: err.message });
    }
  }

  public getQuote(payload: APIQuoteRequest): Observable<Basket> {
    return this._http
      .post<APIQuoteResponse>(`${this.config.contractRoot}/v1/quote`, payload, {
        headers: this.apiHelper.generateHeaders({
          requestSource: true,
          wlClient: false,
        }),
      })
      .pipe(
        map((response: APIQuoteResponse) => {
          const item = response.result.basket.items[0];
          const quotes = item.data.quotes.map(
            ({
              breakdownStartDate,
              companyCode,
              contractTypeCode,
              coverType,
              excessAmount,
              paymentOptions,
              periodOfCover,
              productType,
              quoteId,
              schemeCode,
              waitDays,
            }) => {
              const {
                firstPayment,
                numPayments,
                paymentFrequency,
                paymentProfile,
                paymentType,
                subsequentPayment,
              } = paymentOptions[0];
              const totalCost = numPayments * subsequentPayment;
              return {
                quoteId,
                coverType,
                totalCost,
                productType,
                contractTypeCode,
                companyCode,
                schemeCode,
                subsequentPayment,
                numPayments,
                breakdownStartDate,
                waitDays,
                periodOfCover,
                paymentType,
                paymentProfile,
                firstPayment,
                paymentFrequency,
                excessAmount,
              };
            }
          );
          return {
            id: response.result.basket.basketId,
            itemId: item.itemId,
            itemType: item.itemType,
            createdDate: item.data.created,
            expiry: item.data.expiry,
            quotes,
          } as Basket;
        }),
        catchError(this._handleError)
      );
  }

  public createContract(payload: APICreateContractRequest): Observable<APICheckOutResponse> {
    return this._http
      .post<APICheckOutResponse>(`${this.config.contractRoot}/v1/checkout`, payload, {
        headers: this.apiHelper.generateHeaders({
          requestSource: true,
          wlClient: false,
        }),
      })
      .pipe(
        map((response: APICheckOutResponse) => response),
        catchError(this._handleError)
      );
  }
}
