import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilService {
  /**
   * Provides functionality to validate if two input fields match
   * @param originalEmailInput name of the control to match with
   */
  public emailMatchValidator(originalEmailInput: string): any {
    return (control: FormControl) => {
      let confirmEmailControl!: FormControl;
      let emailControl!: FormControl;
      if (!control.parent) {
        return null;
      }

      if (!confirmEmailControl) {
        confirmEmailControl = control;
        emailControl = control.parent.get(originalEmailInput) as FormControl;
      }

      if (
        emailControl &&
        emailControl.value &&
        confirmEmailControl &&
        confirmEmailControl.value &&
        emailControl.value.toLocaleLowerCase() !== confirmEmailControl.value.toLocaleLowerCase()
      ) {
        return {
          // key of the return object has to match name of the validator, e.g. emailMatch
          // and it has to return true in case of an error
          emailMatch: true,
        };
      }
      return null;
    };
  }

  /**
   * Checks whether value is on a particular list (e.g. brand name entered by user mathes one of the brands returned by the server)
   * @param itemList - list of allowed values to search through
   * @param fieldName - name of the field to use if value is represented as an object
   */
  public isOnTheListValidator(itemList: any[], fieldName = 'name'): ValidatorFn {
    return (control: AbstractControl) => {
      const val =
        typeof control.value === 'object' && control.value !== null && control.value[fieldName]
          ? control.value[fieldName]
          : control.value;

      if (val && itemList && itemList.length && itemList.indexOf(val.toLowerCase()) === -1) {
        return {
          isOnTheList: true, // true means validation didn't pass
        };
      }

      return null; // no validation error
    };
  }

  /**
   * A custom validator to check for postcode edge cases
   */
  public isPostcodeCorrectValidator() {
    return (control: FormControl) => {
      const val = control.value;
      const emptyRegex = /^\s+$/;
      const startsWithADigitRegex = /^\s*\d+.*$/;
      const fullPostcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;

      if (
        val &&
        !val.match(emptyRegex) &&
        !val.match(startsWithADigitRegex) &&
        val.match(fullPostcodeRegex)
      ) {
        return null; // validation passed
      } else {
        return {
          isPostcodeCorrect: true, // validation failed
        };
      }
    };
  }

  public formatPostcode(postcode: any) {
    if (!postcode) {
      return {
        fullPostcode: '',
        outwardPostcode: '',
      };
    }
    const userPostcode = postcode.toUpperCase();
    const postcodeWithoutSpace = userPostcode.replace(/\s+/g, '');
    const inwardPostcode = postcodeWithoutSpace.slice(-3);
    const outwardPostcode = postcodeWithoutSpace.split(inwardPostcode)[0];
    return {
      fullPostcode: `${outwardPostcode} ${inwardPostcode}`,
      outwardPostcode: outwardPostcode,
    };
  }

  public scrollIntoView(element: any) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
