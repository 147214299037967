import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CmsTitleDescImageItem } from '@common/util-base';

@Component({
  selector: 'randc-spa-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksComponent {
  @Input() label!: string;
  @Input() heading!: string;
  @Input() subHeading!: string;
  @Input() items!: CmsTitleDescImageItem[];
  @Input() imageUrl!: string;
}
