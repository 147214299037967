import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { addBusinessDays } from 'date-fns';
import { BuildConfigService } from './build.config.service';
import { ApiHelper } from '../helpers/api.helper';
import { BuildConfig } from '../tokens/runtime-config.token';
import {
  APICalendarAvailabilityResponse,
  CalendarAvailabilityRequest,
  CalendarAvailabilityResponse,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class CalendarAvailabilityService {
  private _payload = {
    storeId: '10',
    catalogId: 'A',
    countryCode: 'GBR',
  };

  constructor(
    private _http: HttpClient,
    private buildConfigService: BuildConfigService,
    private apiHelper: ApiHelper
  ) {}

  public get config(): BuildConfig {
    return this.buildConfigService.config;
  }

  private handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      console.error(`Error occured in front-end ${err.error.message}`);
    } else {
      console.error(`Backend returned ${err.status}, body was ${err.error}`);
    }
    return throwError(err.error);
  }

  public getEarliestEngineerAvailability() {
    return this._http
      .get<{ status: string; earliestAvailability: string }>(
        `${this.config.calendarRoot}/v1/get-earliest-availability`,
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        map(
          (response: { status: string; earliestAvailability: string }) =>
            response.earliestAvailability
        ),
        catchError(this.handleError)
      );
  }

  public getEngineerAvailability(applianceDetails: any): Observable<CalendarAvailabilityResponse> {
    const payload: CalendarAvailabilityRequest = {
      ...this._payload,
      ...applianceDetails,
    };
    return this._http
      .post<APICalendarAvailabilityResponse>(
        `${this.config.calendarRoot}/v1/start-availability-session`,
        payload,
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        map((response: APICalendarAvailabilityResponse) => {
          return response.result;
        }),
        catchError(this.handleError)
      );
    // return this._http
    //   .get(`https://domgen.mockable.io/randc/calendar/availability-start-session`)
    //   .pipe(
    //     map((response: CalendarAvailabilityResponse) => response),
    //     catchError(this.handleError)
    //   );
  }

  public getEngineerMoreAvailability(
    sessionId: string,
    scrollToken: string
  ): Observable<CalendarAvailabilityResponse> {
    const payload = {
      sessionId,
      scrollToken,
    };
    return this._http
      .post<APICalendarAvailabilityResponse>(
        `${this.config.calendarRoot}/v1/get-scroll-availability`,
        payload,
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        map((response: APICalendarAvailabilityResponse) => response.result),
        catchError(this.handleError)
      );
  }

  public getExtraDays() {
    return new Date().getHours() < 15 ? 2 : 3;
  }

  public getNextBusinessDay() {
    // Assuming that the user is in UK.
    // For proper calculation a timezone library with a polyfill (e.g. date-fns-tz) will be required
    // Add 2 days if before 3pm; 3 otherwise
    return addBusinessDays(new Date(), this.getExtraDays());
  }
}
