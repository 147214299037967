import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { BuildConfigService } from '@common/util-base';

@Component({
  selector: 'randc-my-account-banner',
  templateUrl: './my-account-banner.component.html',
  styleUrls: ['./my-account-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAccountBannerComponent {
  constructor(public buildConfigService: BuildConfigService) {}
}
