<div class="container spacing-min-lg">
  <h1 class="text--center xs-12 mb-1">Tell us about your repair and we’ll take care of the rest</h1>
  <p class="text--center xs-12 mb-2" [innerHTML]="cmsData?.subtitle"></p>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div [ngClass]="{ 'lg-row': responsiveLayout, 'column-layout': !responsiveLayout }">
      <div class="lg-6 field-column" [ngClass]="{ 'mb-2': !responsiveLayout }">
        <div class="field-column__inner">
          <dgx-dfb-input
            [field]="fields.postcode"
            [formControlName]="fields.postcode.controlName"
            (analytics)="handleFieldAnalytics($event)"
            [validate]="triggerValidation"
            class="field-item"
          >
          </dgx-dfb-input>
          <div class="field-item">
            <dgx-dfb-select
              [field]="fields.appliance"
              [formControlName]="fields.appliance.controlName"
              (analytics)="handleFieldAnalytics($event)"
              [validate]="triggerValidation"
            ></dgx-dfb-select>
            <div class="mt-1" *ngIf="showApplianceLink$ | async">
              Please go back to the <a href="/" class="link">home page</a> to change your appliance
            </div>
          </div>
          <div class="field-item">
            <dgx-dfb-select
              [field]="fields.brand"
              [formControlName]="fields.brand.controlName"
              (analytics)="handleFieldAnalytics($event)"
              [validate]="triggerValidation"
            >
            </dgx-dfb-select>
            <div class="mt-1" *ngIf="showBrandLink$ | async">
              Please go back to the <a [routerLink]="'/'" class="link">home page</a> to change your
              brand
            </div>
          </div>
        </div>
      </div>
      <div class="lg-6 field-column">
        <div class="field-column__inner">
          <div class="field-item" *ngIf="!_separateApplianceConditionFields">
            <dgx-dfb-yes-no
              *ngIf="applianceConditionField$ | async as applianceConditionField"
              [field]="applianceConditionField"
              [formControlName]="fields.applianceCondition.controlName"
              (analytics)="handleFieldAnalytics($event)"
              [validate]="triggerValidation"
            ></dgx-dfb-yes-no>
            <div *ngIf="showApplianceConditionStopMsg$ | async" class="stop-message mt-1">
              Unfortunately, we’re only able to book your repair online if your appliance cost less
              than {{ maxPrice$ | async | currency: 'GBP':'symbol':'1.0-0' }}. Call us at
              <a class="link" href="tel:08005614489">0800 561 4489</a> and we can help set up a
              repair.
            </div>
          </div>
          <div class="field-item" *ngIf="_separateApplianceConditionFields">
            <dgx-dfb-yes-no
              [field]="fields.ageCondition"
              [formControlName]="fields.ageCondition.controlName"
              (analytics)="handleFieldAnalytics($event)"
              [validate]="triggerValidation"
            ></dgx-dfb-yes-no>
            <div *ngIf="showAgeConditionStopMsg$ | async" class="stop-message mt-1">
              Unfortunately, we’re only able to book your repair online if your appliance is under 8
              years old. Call us at
              <a class="link" href="tel:08005614489">0800 561 4489</a> and we can help set up a
              repair.
            </div>
          </div>
          <div class="field-item" *ngIf="_separateApplianceConditionFields">
            <dgx-dfb-yes-no
              [field]="fields.costCondition"
              [formControlName]="fields.costCondition.controlName"
              (analytics)="handleFieldAnalytics($event)"
              [validate]="triggerValidation"
            ></dgx-dfb-yes-no>
            <div *ngIf="showCostConditionStopMsg$ | async" class="stop-message mt-1">
              Unfortunately, we’re only able to offer this plan if your appliance cost less than
              {{ maxPrice$ | async | currency: 'GBP':'symbol':'1.0-0' }}. Call us at
              <a class="link" href="tel:08005614489">0800 561 4489</a> and we’ll find you another
              plan or book a one-off repair.
            </div>
          </div>

          <div class="field-item">
            <ui-button
              variant="primary"
              icon="arrow-right"
              type="submit"
              [disabled]="(invalidApplianceCondition$ | async) || !formGroup.valid"
              [disableMode]="formGroup.valid ? 'disable' : 'visual'"
              >Book a repair</ui-button
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
