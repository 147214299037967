<div>
  <ng-container [ngSwitch]="headingLevel" *ngIf="cmsContent?.title">
    <ng-container *ngSwitchCase="1">
      <h1 [ngClass]="[customHeadingClass ? customHeadingClass : 'mb-1']">{{ cmsContent.title }}</h1>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <h2 [ngClass]="[customHeadingClass ? customHeadingClass : 'mb-1']">{{ cmsContent.title }}</h2>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <h3 [ngClass]="[customHeadingClass ? customHeadingClass : 'mb-1']">{{ cmsContent.title }}</h3>
    </ng-container>
    <ng-container *ngSwitchCase="5">
      <h5 [ngClass]="[customHeadingClass ? customHeadingClass : 'mb-1']">{{ cmsContent.title }}</h5>
    </ng-container>
    <ng-container *ngSwitchCase="6">
      <h6 [ngClass]="[customHeadingClass ? customHeadingClass : 'mb-1']">{{ cmsContent.title }}</h6>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <h4 [ngClass]="[customHeadingClass ? customHeadingClass : 'mb-1']">{{ cmsContent.title }}</h4>
    </ng-container>
  </ng-container>

  <div [innerHtml]="cmsContent?.paragraphContent"></div>
</div>
