<!--todo: remove logic from here about padding/container - handle this in layouts -->
<div class="container">
  <div class="xs-12">
    <h2 class="typog-h2 text--center mb-2" *ngIf="title">
      {{ title }}
    </h2>
    <!-- TrustBox widget - Micro Combo -->
    <div
      *ngIf="type === TrustBox.MICRO"
      #microRef
      id="trustbox-micro"
      class="trustpilot-widget"
      data-locale="en-GB"
      [attr.data-template-id]="templateIdForMicro"
      [attr.data-businessunit-id]="businessUnitId"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    ></div>
    <!-- End TrustBox widget - Micro Combo -->

    <!-- TrustBox widget - Slider -->
    <div
      *ngIf="type === TrustBox.SLIDER"
      #sliderRef
      id="trustbox-slider"
      class="trustpilot-widget"
      data-locale="en-GB"
      [attr.data-template-id]="templateIdForSlider"
      [attr.data-businessunit-id]="businessUnitId"
      data-style-height="240px"
      data-style-width="100%"
      data-theme="light"
      data-stars="4,5"
    ></div>
    <!-- End TrustBox widget - Slider -->
  </div>
</div>
