<ui-panel [variant]="'normal'">
  <!-- Create observable and loop values-->
  <ui-panel-header>
    <ui-panel-title [variant]="'normal'">Your fault details</ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <ui-panel-display-info-row
      [direction]="'column'"
      [data]="{ key: 'Model number', value: faultDetails.model.modelNumber }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'column'"
      [data]="{
        key: 'When did you buy your appliance?',
        value: faultDetails.appliance.appliancePurchaseYear
      }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'column'"
      [data]="{
        key: 'How much did you pay for it?',
        value: faultDetails.appliance.appliancePurchasePrice | currency: 'GBP'
      }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      *ngIf="faultDetails.fault.categoryLabel !== null"
      [direction]="'column'"
      [data]="{ key: 'Fault type', value: faultDetails.fault.categoryLabel }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'column'"
      [data]="{ key: 'Fault description', value: faultDetails.fault.faultLabel }"
    ></ui-panel-display-info-row>

    <ui-panel-display-info-row
      [direction]="'column'"
      [data]="{ key: 'Fault details', value: faultDetails.fault.description }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'column'"
      [data]="{
        key: 'Can the engineer park safely and legally near your home',
        value: faultDetails.access.parkingAccess ? 'Yes' : 'No'
      }"
    ></ui-panel-display-info-row>
    <ui-panel-display-info-row
      [direction]="'column'"
      [data]="{
        key: 'Will the engineer have easy access to your appliance',
        value: faultDetails.access.engineerAccess ? 'Yes' : 'No'
      }"
    ></ui-panel-display-info-row>
  </ui-panel-body>
</ui-panel>
