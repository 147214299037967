import { InjectionToken } from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

export interface RuntimeConfig {
  applianceLookupRoot: string;
  calendarRoot: string;
  faultRoot: string;
  contractRoot: string;
  modelSearchRoot: string;
  cmsContentRoot: string;
  // Auth Lib needs the version in config.json. This is the reason this property is included here even though its always static
  identityApiUrlVersion: string;
  host: string;
  recaptchaSiteKey: string;
}

export interface BuildConfig {
  header?: {
    home: NavigationLink;
    products: NavigationLink;
    repair: NavigationLink;
    faq: NavigationLink;
    profile?: NavigationLink;
    basket?: NavigationLink;
  };
  footer?: {
    copyrightOwner?: string;
    links: NavigationLink[];
    socialLinks?: NavigationLink[];
  };
  applianceLookupRoot: string;
  calendarRoot: string;
  faultRoot: string;
  contractRoot: string;
  modelSearchRoot: string;
  cmsContentRoot: string;
  identityApiUrlVersion: string;
  production: boolean;
  configPath: string;
  imageUrl: string;
  cookieProSrc: string;
  cookieProDomainScript: string;
  host: string;
  recaptchaSiteKey: string;
  requestSource: string;
  requestAction: string;
  wlClient: string;
  localCms?: boolean;
  myAccountLink?: string;
  contactUsLink?: string;
  faqLink?: string;
  privacyPolicyLink?: string;
}

export const RUNTIME_CONFIG = new InjectionToken<RuntimeConfig>(
  'Config that changes per Deployment Environment'
);
