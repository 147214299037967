export class QueryParameter {
  static remove(url: string): string {
    //Ignore all query params after ?
    if (url.indexOf('?') >= 0) {
      const urlSegment = url.split('?');
      if (urlSegment.length > 0) {
        return urlSegment[0];
      }
    }
    return url;
  }
}
