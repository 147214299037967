<randc-cms-content
  class="mb-2"
  [headingLevel]="3"
  [customHeadingClass]="'mb-1'"
  [cmsContent]="thingsYouNeedToKnow"
>
</randc-cms-content>
<randc-cms-content
  class="mb-2"
  [headingLevel]="3"
  [customHeadingClass]="'mb-1'"
  [cmsContent]="cancellation"
>
</randc-cms-content>
