import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { BookRepairFields } from '@common/util-base';
import { distinctUntilKeyChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';

@UntilDestroy()
@Component({
  selector: 'randc-spa-book-repair-form',
  templateUrl: './book-repair-form.component.html',
  styleUrls: ['./book-repair-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookRepairFormComponent implements OnInit {
  @Input() inputFields: BookRepairFields | null = null;
  @Input() showFaultCategory = false;
  @Input() modelNumbersRequired: boolean | null = false;
  @Input() triggerValidation = false;
  @Output() fieldAnalytics = new EventEmitter<AnalyticsData>();
  @Output() showBookRepairForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  showForm = true;
  form: FormGroup | null = null;

  //todo: has to be a nicer way to keep typescript happy than create pointless observables
  parkSafelyShow$: Observable<boolean> = new Observable<boolean>();
  blockedShow$: Observable<boolean> = new Observable<boolean>();

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    if (!this.form) {
      throw new Error('Could not find form');
    }
    this.parkSafelyShow$ = this.form.valueChanges.pipe(
      distinctUntilKeyChanged('parkSafely'),
      map((value) => value.parkSafely === false),
      untilDestroyed(this)
    );

    this.blockedShow$ = this.form.valueChanges.pipe(
      distinctUntilKeyChanged('applianceBlocked'),
      map((value) => value.applianceBlocked === true),
      untilDestroyed(this)
    );

    this.form.valueChanges
      .pipe(distinctUntilKeyChanged('faultCategory'), untilDestroyed(this))
      .subscribe(() => {
        const faultType = this.form?.get('faultType');
        if (faultType?.value) {
          //todo: this marks the field as invalid, we need a fix for that
          faultType?.reset(null, { emitValue: false });
        }
      });
  }

  onChangeYearPurchased = (e: Event) => {
    const purchaseYear = parseInt(this.form?.get('yearPurchased')?.value.toString(), 10);
    const currentYear = new Date().getFullYear();
    const age = currentYear - purchaseYear;
    this.showForm = age > 1;
    this.showBookRepairForm.emit(this.showForm);
  };

  public async handleFieldAnalytics($event: AnalyticsData | undefined) {
    if ($event) {
      this.fieldAnalytics.emit($event);
    }
  }
}
