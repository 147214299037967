import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as QuoteActions from '../+state/quote/quote.actions';
import * as QuoteSelectors from '../+state/quote/quote.selectors';
import { APICreateContractRequest } from '@common/util-base';
import { QuotePartialState } from '../+state/quote/quote.reducer';

@Injectable({
  providedIn: 'root',
})
export class CheckoutFacade {
  constructor(private store: Store<QuotePartialState>) {}

  public contractDetails$ = this.store.select(QuoteSelectors.getContractDetails);

  public createContract(createContractPayload: APICreateContractRequest) {
    return this.store.dispatch(
      QuoteActions.CreateContract({
        payload: {
          createContractPayload,
        },
      })
    );
  }
}
