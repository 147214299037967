import { Component, Input } from '@angular/core';
import { CmsParagraphReferences } from '@common/util-base';

@Component({
  selector: 'randc-cms-content',
  templateUrl: './cms-content.component.html',
  styleUrls: ['./cms-content.component.scss'],
})
export class CmsContentComponent {
  @Input() cmsContent!: CmsParagraphReferences;
  @Input() headingLevel!: number;
  @Input() customHeadingClass!: string;
}
