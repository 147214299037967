import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ApplianceDetails, ModelDetails } from '@common/util-base';

@Component({
  selector: 'randc-appliance-details-summary-card',
  templateUrl: './appliance-details-summary-card.component.html',
  styleUrls: ['./appliance-details-summary-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplianceDetailsSummaryCardComponent {
  _applianceFullName = '';
  @Input() set applianceDetails(value: ApplianceDetails | null) {
    this._applianceFullName = value ? `${value.applianceBrandName} ${value.applianceTypeName}` : '';
  }
  @Input() public modelDetails: ModelDetails | null = null;
  @Output() handleEditClick: EventEmitter<boolean> = new EventEmitter();

  onEditClick(): void {
    this.handleEditClick.emit(true);
  }
}
