import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDetailsFormComponent } from './payment-details-form.component';
import { RandcUiModule } from '../randc-ui.module';
import {
  MarketingPreferencesModule,
  DirectDebitUiModule,
} from '@domgen/dgx-fe-business-components';
import { PlanDocumentsModule } from '../plan-documents/plan-documents.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RandcUiOrderConfirmationModule } from '@common/ui';

@NgModule({
  declarations: [PaymentDetailsFormComponent],
  imports: [
    CommonModule,
    RandcUiModule,
    PlanDocumentsModule,
    FormsModule,
    ReactiveFormsModule,
    MarketingPreferencesModule,
    DirectDebitUiModule,
    RandcUiOrderConfirmationModule,
  ],
  exports: [PaymentDetailsFormComponent],
})
export class PaymentDetailsFormModule {}
