import {
  Directive,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';

import { InputMask } from '../../enums';

@Directive({
  selector: '[randcInputMask], mask',
})
export class InputMaskDirective implements OnInit {
  @HostBinding('value')
  get value(): string {
    return this.randcInputMask;
  }

  @HostListener('input', ['$event.target.value']) applyMask(value: string) {
    if (this.mask === InputMask.POST_CODE) {
      return this.applyPostCodeMask(value);
    }
  }

  @Input() randcInputMask = '';
  @Input() mask: InputMask | null = null;

  @Output() randcInputMaskChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.applyMask(this.randcInputMask);
  }

  applyPostCodeMask(value: string): void {
    if (value) {
      let postcode = value.toUpperCase();

      if (postcode.match(/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i)) {
        const postcodeWithoutSpace = postcode.replace(/\s+/g, '');
        const inwardPostcode = postcodeWithoutSpace.slice(-3);
        const outwardPostcode = postcodeWithoutSpace.split(inwardPostcode)[0];
        postcode = `${outwardPostcode} ${inwardPostcode}`;
      }

      this.randcInputMaskChange.next(postcode);
    }
  }
}
