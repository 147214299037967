<div class="customer-details-card" *ngIf="customerDetails">
  <h2>Your details</h2>
  <div class="sm-stack md-row lg-row border-bottom-grey row-extra-padding">
    <div class="xs-12 md-6">
      <strong>Name</strong>
    </div>
    <div class="xs-12 md-6 md-right">
      <p class="p1 customer-name">
        {{ customerDetails!.title }} {{ customerDetails!.firstName }}
        {{ customerDetails!.lastName }}
      </p>
    </div>
  </div>
  <div class="sm-stack md-row lg-row row-extra-padding">
    <div class="xs-12 md-6">
      <strong>Email address</strong>
    </div>
    <div class="xs-12 md-6 md-right">
      <p class="p1">{{ customerDetails!.email }}</p>
    </div>
  </div>
  <div class="sm-stack md-row lg-row row-extra-padding">
    <div class="xs-12 md-6">
      <strong>Phone number</strong>
    </div>
    <div class="xs-12 md-6 md-right">
      <p class="p1">{{ customerDetails!.mobile }}</p>
    </div>
  </div>
  <div class="sm-stack md-row lg-row row-extra-padding">
    <div class="xs-12 md-6">
      <strong>Address</strong>
    </div>
    <div class="xs-12 md-6 md-right">
      <p class="p1">{{ customerDetails!.address1 }}</p>
      <p
        class="p1 small-line-height small-margin-bottom"
        *ngIf="customerDetails!.address2 && customerDetails!.address2 != ''"
      >
        {{ customerDetails!.address2 }}
      </p>
      <p class="p1 small-line-height small-margin-bottom">
        {{ customerDetails!.town }}
      </p>
      <p class="p1 small-line-height small-margin-bottom">
        {{ customerDetails!.postcode }}
      </p>
      <p class="p1 small-line-height small-margin-bottom">
        {{ customerDetails!.countryCode }}
      </p>
    </div>
  </div>
</div>
