import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { CmsFormField, PlanDocumentsFormField } from '@domgen/dgx-fe-business-models';
import { DynamicFormbuilderService, FieldDef } from '@domgen/dgx-fe-dynamic-form-builder';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PlanDocumentsFormConfigService } from './plan-documents-form-config.service';

export interface PlanDocumentsFormState {
  formBuilderConfig: FieldDef[];
  cmsFormData: CmsFormField[];
  form: FormGroup;
  validate: boolean;
}

@Injectable({ providedIn: 'root' })
export class PlanDocumentsFormStateService extends ComponentStore<PlanDocumentsFormState> {
  // Selectors
  readonly formBuilderConfig$ = this.select(
    (state: PlanDocumentsFormState) => state.formBuilderConfig
  );
  readonly formGroup$ = this.select((state: PlanDocumentsFormState) => state.form);
  readonly validate$ = this.select((state: PlanDocumentsFormState) => state.validate);
  readonly cmsFormData$ = this.select((state: PlanDocumentsFormState) => state.cmsFormData);

  readonly valueChanges$ = this.formGroup$.pipe(
    switchMap((formGroup) =>
      this.dynamicFormBuilder
        .selectValidFormValue$<{
          [PlanDocumentsFormField.Paperless]: string;
          [PlanDocumentsFormField.TermsAndConditions]: string;
        }>(formGroup)
        .pipe(
          map((formValue) => ({
            paperless: formValue.paperless?.toLocaleLowerCase() === 'yes' ? true : false,
          }))
        )
    )
  );

  readonly setCmsFormData = this.updater(
    (state: PlanDocumentsFormState, value: CmsFormField[]) => ({
      ...state,
      cmsFormData: value,
    })
  );

  // *** Updater Sources ****
  private fieldDefinitionUpdater$ = this.cmsFormData$.pipe(
    switchMap((cmsData) =>
      of(cmsData).pipe(
        map((formFieldsData) => {
          return formFieldsData.length ? this.formConfig.getFormbuilderConfig(formFieldsData) : [];
        })
      )
    )
  );

  readonly validate = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state: PlanDocumentsFormState) => ({
      ...state,
      validate: true,
    })
  );

  private readonly cmsFieldDefinitionUpdater = this.updater(
    (state: PlanDocumentsFormState, value: FieldDef[]) => ({
      ...state,
      formBuilderConfig: value,
    })
  )(this.fieldDefinitionUpdater$);

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    switchMap((formBuilderConfig) =>
      of(formBuilderConfig).pipe(map((conf) => this.dynamicFormBuilder.generateFormGroup(conf)))
    )
  );

  private readonly formGroupUpdater = this.updater(
    (state: PlanDocumentsFormState, value: FormGroup) => ({
      ...state,
      form: value,
    })
  )(this.formGroupUpdater$);

  readonly vm$ = this.select(
    this.formBuilderConfig$,
    this.formGroup$,
    this.validate$,
    (fieldDef, formGroup, validate) => ({
      fieldDef,
      formGroup,
      validate,
    })
  );

  constructor(
    private formConfig: PlanDocumentsFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      formBuilderConfig: [],
      cmsFormData: [],
      form: new FormGroup({}),
      validate: false,
    });
  }
}
