import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as applianceDetailsActions from './appliance-details.actions';
import { Init } from './appliance-details.actions';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApplianceBrandDetails, ApplianceTypeDetails } from '../models';

import { Router } from '@angular/router';
import { ApplianceDetailsApiService } from '../services/appliance-details-api/appliance-details-api.service';

@Injectable({ providedIn: 'root' })
export class ApplianceDetailsEffects implements OnInitEffects {
  constructor(
    private action$: Actions,
    private applianceDetailsApiService: ApplianceDetailsApiService,
    private router: Router
  ) {}

  ngrxOnInitEffects(): Action {
    return Init();
  }

  getAllApplianceTypes$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(applianceDetailsActions.GET_ALL_APPLIANCE_TYPES),
      switchMap(() => {
        return this.applianceDetailsApiService.getAllApplianceTypes().pipe(
          map((response: ApplianceTypeDetails[]) =>
            applianceDetailsActions.GetAllApplianceTypesSuccess({ payload: response })
          ),
          catchError((err: any) => of(applianceDetailsActions.GetAllApplianceTypesFail(err)))
        );
      })
    )
  );

  getAllApplianceBrands$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(applianceDetailsActions.GET_ALL_APPLIANCE_BRANDS),
      switchMap((action: any) => {
        return this.applianceDetailsApiService.getAllApplianceBrands().pipe(
          map((response: ApplianceBrandDetails[]) =>
            applianceDetailsActions.GetAllApplianceBrandsSuccess({ payload: response })
          ),
          catchError((err: any) => of(applianceDetailsActions.GetAllApplianceBrandsFail(err)))
        );
      })
    )
  );

  getApplianceBrandsByType$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(applianceDetailsActions.GET_APPLIANCE_BRANDS_BY_TYPE),
      switchMap((action: { payload: { selectedApplianceType: string } }) => {
        return this.applianceDetailsApiService
          .getApplianceBrandsByType(action.payload.selectedApplianceType)
          .pipe(
            map((response: ApplianceBrandDetails[]) =>
              applianceDetailsActions.GetApplianceBrandsByTypeSuccess({
                payload: response,
                selectedApplianceType: action.payload.selectedApplianceType,
              })
            ),
            catchError((err: any) => of(applianceDetailsActions.GetApplianceBrandsByTypeFail(err)))
          );
      })
    )
  );

  getApplianceTypesByBrand$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(applianceDetailsActions.GET_APPLIANCE_TYPES_BY_BRAND),
      switchMap((action: { payload: { selectedApplianceBrand: string } }) => {
        return this.applianceDetailsApiService
          .getApplianceTypesbyBrand(action.payload.selectedApplianceBrand)
          .pipe(
            map((response: ApplianceTypeDetails[]) =>
              applianceDetailsActions.GetApplianceTypesbyBrandSuccess({
                payload: response,
                selectedApplianceBrand: action.payload.selectedApplianceBrand,
              })
            ),
            catchError((err: any) => of(applianceDetailsActions.GetApplianceTypesbyBrandFail(err)))
          );
      })
    )
  );

  redirectToError$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(
          applianceDetailsActions.GET_ALL_APPLIANCE_BRANDS_FAIL,
          applianceDetailsActions.GET_ALL_APPLIANCE_TYPES_FAIL,
          applianceDetailsActions.GET_APPLIANCE_BRANDS_BY_TYPE_FAIL,
          applianceDetailsActions.GET_APPLIANCE_TYPES_BY_BRAND_FAIL
        ),
        tap(() => {
          this.router.navigate(['/generic-error']);
        }),
        map(() => null)
      ),
    { dispatch: false }
  );
}
