import { CalendarAvailabilityData } from './common.types';

export interface CalendarDay {
  date: Date;
  slotsAvailable?: boolean;
  slots?: CalendarSlot[];
  newMonth?: boolean;
  text?: string;
  selected?: boolean | null;
}

export interface CalendarDateSlot {
  slotType: string;
  startTime: string;
  endTime: string;
  slotId: string;
  available: boolean;
}
export interface CalendarSlot extends CalendarDateSlot {
  identifier: string;
}

export interface CalendarNextAvailability {
  nextAvailableDate: Date;
  nextAvailableId: number;
}

export enum TimeSlotType {
  ALLDAY = 'AllDay',
  SPECIFIC = 'Specific',
}

export interface CalendarAvailibityGAData {
  calendarStartDate: string;
  calendarEndDate: string;
  calendarDaysAvailable: number;
  blockAvailabilityDates: CalendarAvailabilityData[];
}

export interface CalendarSelectedTimeSlot {
  timeSlot: CalendarSlot;
  isValid: boolean;
}

export interface DateInCurrentWeek {
  monday: { date: Date };
  tuesday: { date: Date };
  wednesday: { date: Date };
  thursday: { date: Date };
  friday: { date: Date };
  saturday: { date: Date };
  sunday: { date: Date };
}
