<ui-panel>
  <ui-panel-header>
    <ui-panel-title>{{ _applianceFullName }}</ui-panel-title>
    <ui-panel-action-button
      [buttonText]="'Edit'"
      (buttonClick)="onEditClick()"
    ></ui-panel-action-button>
  </ui-panel-header>
  <ui-panel-body>
    <div class="detail-panel-item" *ngIf="modelDetails?.modelNumber">
      {{ modelDetails?.modelNumber }}
    </div>
  </ui-panel-body>
</ui-panel>
