<div class="customer-details-form">
  <h3 class="form__title">Your details</h3>
  <dgx-dfb-form
    [fieldset]="fieldDef"
    [form]="formGroup"
    (validatedSubmit)="handleValidFormSubmission()"
    (analytics)="handleAnalytics($any($event))"
  >
  </dgx-dfb-form>
</div>
