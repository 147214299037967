<div class="how-it-works">
  <div class="container">
    <div class="xs-row">
      <div class="xs-12">
        <div class="how-it-works__label">{{ label }}</div>
        <div class="how-it-works__heading">{{ heading }}</div>
        <div class="how-it-works__sub-heading">{{ subHeading }}</div>
      </div>
    </div>
    <div class="xs--stack lg-row xs--middle">
      <div *ngFor="let item of items" class="xs-12 sm-8 lg-4">
        <div class="how-it-works__image-with-title">
          <div class="how-it-works__item-image">
            <img *ngIf="item.imageDesktop" [src]="imageUrl + item.imageDesktop['@link']" />
          </div>

          <div class="how-it-works__item-title">
            {{ item.title }}
          </div>
        </div>
        <div class="how-it-works__item-description" [innerHtml]="item.description"></div>
      </div>
    </div>
  </div>
</div>
