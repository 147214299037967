<h3>Your plan documents</h3>

<p class="info">
  We’ll send you an email with your plan documents, including the full Terms and Conditions.
</p>

<dgx-dfb-form
  *ngIf="vm$ | async as vm"
  [fieldset]="vm.fieldDef"
  [form]="vm.formGroup"
  [validate]="vm.validate"
>
</dgx-dfb-form>
