import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { DATE_FORMAT_DAY_MONTH_LONG } from '@common/util-base';

@Component({
  selector: 'randc-engineer-reserved-card',
  templateUrl: './engineer-reserved-card.component.html',
  styleUrls: ['./engineer-reserved-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineerReservedCardComponent {
  @Input() reservationDate: Date | null = null;
  @Input() reservationTime: string | null = null;
  @Output() handleEditClick: EventEmitter<boolean> = new EventEmitter();

  public dateFormat: string = DATE_FORMAT_DAY_MONTH_LONG;

  onEditClick(): void {
    this.handleEditClick.emit(true);
  }
}
