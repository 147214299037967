<div class="appliance-details-card__wrapper">
  <div class="appliance-details-card">
    <h4>Your Repair & Care plan</h4>
    <div class="appliance-details-card__appliance-title-section">
      <ul class="list">
        <li class="list__item list__price-row">
          <span id="firstPayment" class="appliance-details-card__price-amount"
            ><strong>{{ quote.firstPayment | currency: 'GBP' }}</strong></span
          >
          <p id="periodOfCover" class="appliance-details-card__price-text">
            per month for {{ quote.periodOfCover }} months
          </p>
        </li>

        <li class="list__item list__price-row" *ngIf="showTotalPrice">
          <span class="appliance-details-card__price-text">Total </span>
          <span id="totalCost" class="appliance-details-card__price-amount"
            ><strong>{{ quote.totalCost | currency: 'GBP' }}</strong></span
          >
        </li>
      </ul>
    </div>
    <div class="appliance-details-card__appliance-detail-section">
      <div class="appliance-details-card__appliance-detail">
        <ul class="list">
          <li class="list__item list__item--icon-large-tick list__item--icon-generic-appliance">
            <span id="applianceBrandName" class="appliance-details-card__brand-name"
              ><strong>{{ applianceDetails.applianceBrandName }} </strong></span
            >
            <span id="applianceTypeName">{{ applianceDetails.applianceTypeName }}</span>
          </li>
          <li class="list__item list__item--icon-large-tick list__item--icon-repair">
            Engineer booked for
            <span id="repairDate" class="appliance-details-card__engineer-date"
              ><strong>{{ repairDate | dateWithOrdinal: dateFormat }}</strong></span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
