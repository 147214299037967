import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { RandcDataAccessQuoteModule } from '@common/data-access-quote';
import { RequestInterceptor } from '@common/util-base';
import { environment } from '@randc-spa/randc/util-environment';
import { META_REDUCERS } from './meta-reducers';
import { RandcDataAccessBookingDetailsModule } from '@common/data-access-booking-details';
import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service } from 'ng-recaptcha';
import { BuildConfigService } from '@common/util-base';
import { CommonDataAccessApplianceDetailsModule } from '@common/data-access-appliance-details';
import { ReCaptchaWithCleanupService } from '@common/util-base';
import { RandcDataAccessSharedModule } from '@common/data-access-shared';

const exportedModules = [
  RandcDataAccessSharedModule,
  CommonDataAccessApplianceDetailsModule,
  RandcDataAccessQuoteModule,
  RandcDataAccessBookingDetailsModule,
];
@NgModule({
  imports: [
    ...exportedModules,
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [...META_REDUCERS] : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    StoreDevtoolsModule.instrument({
      name: 'Repair & Care',
      maxAge: 25,
      logOnly: environment.production,
    }),
    SharedUiDynamicFormBuilderModule.forRoot({
      postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
      postcodeServiceFind: 'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
      postcodeServiceRetrieve: 'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
  ],
  providers: [
    // todo: global error handler disabled because forsee was triggering
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: (buildConfigService: BuildConfigService) => {
        return buildConfigService.config.recaptchaSiteKey;
      },
      deps: [BuildConfigService],
    },
    {
      provide: ReCaptchaV3Service,
      useClass: ReCaptchaWithCleanupService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  exports: [...exportedModules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonFeatureCoreModule {}
