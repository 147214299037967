import { BuildConfigService } from '../services';

export const cookieProInitializer = (document: Document, buildConfig: BuildConfigService) => {
  return () =>
    new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = buildConfig.config.cookieProSrc;
      script.setAttribute('data-domain-script', buildConfig.config.cookieProDomainScript);
      script.onerror = reject;
      script.onload = () => {
        resolve();
      };
      const head = document.head;
      head?.insertBefore(script, head.childNodes[0]);
    });
};
