import { Injectable } from '@angular/core';
import { BookingDetailsState } from './booking-details.reducer';
import { select, Store } from '@ngrx/store';
import * as selectors from './booking-details.selectors';
import { ApplianceDetails, BookRepairForm } from '@common/util-base';
import {
  updateApplianceDetails,
  updateBookingForm,
  updateBookingFormSuccess,
} from './booking-details.actions';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class BookingDetailsFacade {
  constructor(private store: Store<BookingDetailsState>, private actions$: Actions) {}

  model$ = this.store.pipe(select(selectors.getModel));
  access$ = this.store.pipe(select(selectors.getAccess));
  fault$ = this.store.pipe(select(selectors.getFault));
  appliancePurchase$ = this.store.pipe(select(selectors.getAppliancePurchase));
  applianceDetails$ = this.store.pipe(select(selectors.getApplianceDetails));

  updateBookingFormSuccess$ = this.actions$.pipe(ofType(updateBookingFormSuccess));

  saveDetails(form: BookRepairForm) {
    this.store.dispatch(updateBookingForm({ form }));
  }
  saveApplianceDetails(applianceDetails: ApplianceDetails) {
    this.store.dispatch(updateApplianceDetails({ applianceDetails }));
  }
}
