import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AoAppComponent } from './components/ao-app.component';
import { CommonFeatureCoreModule } from '@common/feature-core';
import { CommonFeatureAppModule } from '@common/feature-app';
import { AOFeatureShellRoutingModule } from './ao-feature-shell-routing.module';
import { ENVIRONMENT, FEATURE_FLAGS, FeatureFlags } from '@common/util-base';
import { environment } from '@ao/util-environment';
import { HeaderModule, SharedAoComponentsModule } from '@domgen/dgx-ui-whitelabel-components';

const featureFlags = {
  bookingDetailsBeforeCalendar: false,
  hideChatNowBanner: true,
} as FeatureFlags;

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    SharedAoComponentsModule,
    CommonFeatureCoreModule,
    CommonFeatureAppModule,
    AOFeatureShellRoutingModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: FEATURE_FLAGS,
      useValue: featureFlags,
    },
  ],
  declarations: [AoAppComponent],
  exports: [AoAppComponent, AOFeatureShellRoutingModule],
})
export class AoFeatureShellModule {}
