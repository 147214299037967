<div class="container">
  <h2 *ngIf="planTitle">{{ planTitle }}</h2>
  <p class="mt-1" *ngIf="planSubTitle">{{ planSubTitle }}</p>
  <div class="xs--stack lg-row mt-2 xs--middle">
    <div *ngFor="let planItem of planItems" class="xs-12 sm-8 lg-4 plan-item">
      <div class="lg--stack d-flex plan-item__header">
        <div class="plan-image-row d-flex">
          <div
            *ngIf="planItem.imageDesktop"
            class="plan-image"
            [ngStyle]="{
              'background-image': 'url(' + imageUrl + planItem.imageDesktop['@link'] + ')'
            }"
          ></div>
        </div>
        <h3>{{ planItem.title }}</h3>
      </div>
      <p>{{ planItem.description }}</p>
    </div>
  </div>
</div>
<div class="container sm--middle mt-3">
  <ui-button variant="primary" icon="arrow-right" class="xs-12" (click)="onBookARepairButtonClick()"
    >Book a repair
  </ui-button>
</div>
