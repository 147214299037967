import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { WrappedValidate, CmsFormField } from '@domgen/dgx-fe-business-models';
import { PlanDocumentsFormConfigService } from './plan-documents-form-config.service';
import { PlanDocumentsFormStateService } from './plan-documents-form-state.service';

@Component({
  selector: 'randc-dgx-uib-plan-documents',
  templateUrl: './plan-documents.component.html',
  styleUrls: ['./plan-documents.component.scss'],
  providers: [PlanDocumentsFormStateService, PlanDocumentsFormConfigService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDocumentsComponent {
  @Input() isHeating!: boolean | null;

  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.stateService.setCmsFormData(value);
    }
  }

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.stateService.validate();
    }
  }

  /**
   * The underlying form group
   */
  @Output() form = this.stateService.formGroup$;

  /**
   * The valid value of the form
   */
  @Output() valueChanges = this.stateService.valueChanges$;

  readonly vm$ = this.stateService.vm$;

  constructor(private readonly stateService: PlanDocumentsFormStateService) {}
}
