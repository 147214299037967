import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Basket } from '../models';
import { isBefore } from 'date-fns';
import { differenceInMinutes, addMinutes } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private _router: Router) {}

  /**
   * Calculate difference between server and client time (should be 0 if user is in the UK)
   * to accurately determine quote expiration time for user time zone.
   * Positive offset means the user is ahead (east).
   * Value of the offset will be used on the payment page to check whether a quote has expired.
   * Saves quote expiry time is sessionStorage to be checked when needed
   * e.g. before sending a request to create contract API
   */
  public saveSessionExpiryTime(basket: Basket) {
    let offset = 0;
    let localSessionExpiryTime = '';

    if (basket && basket.createdDate) {
      // if user and server are in the same time zone the offset will be close to 0
      offset = differenceInMinutes(new Date(), new Date(basket.createdDate));
      localSessionExpiryTime = addMinutes(new Date(basket.expiry), offset).toString();
    }

    window.sessionStorage && sessionStorage.setItem('sessionExpiryTime', localSessionExpiryTime);
  }

  // Returns true if session has expired
  public checkSessionExpiry() {
    let result = false;
    const timeNow = new Date();
    const sessionTime = sessionStorage.getItem('sessionExpiryTime');

    if (window.sessionStorage && sessionTime) {
      const deadline = new Date(sessionTime);

      result = isBefore(deadline, timeNow); // true if session has expired
    }

    if (result) {
      this._router.navigate(['/generic-error']);
    }

    return result;
  }
}
