import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, pluck, take } from 'rxjs/operators';
import { GtmData, GtmEvent } from '@common/util-base';
import { ApplianceBrandDetails, ApplianceTypeDetails } from '@common/data-access-appliance-details';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApplianceDetailsLandingFormService } from './services/appliance-details-landing-form.service';
import { YesNoDef } from '@domgen/dgx-fe-dynamic-form-builder';
import { CurrencyPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'randc-spa-appliance-details-landing-form',
  templateUrl: './appliance-details-landing-form.component.html',
  styleUrls: ['./appliance-details-landing-form.component.scss'],
  providers: [ApplianceDetailsLandingFormService, CurrencyPipe],
})
export class ApplianceDetailsLandingFormComponent implements OnInit {
  constructor(
    private landingFormService: ApplianceDetailsLandingFormService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.landingFormService.restoreValues();
    this.updateApplianceConditionLabelText();
  }

  @Input() responsiveLayout = true;

  @Input() set separateApplianceConditionFields(value: boolean) {
    this._separateApplianceConditionFields = value;
    this.landingFormService.separateApplianceConditionFields = value;
  }

  @Input() set disablePrefilledFields(value: boolean) {
    this.landingFormService.store.updateDisablePrefilledFields(value);
  }

  @Input() set brands(value: ApplianceBrandDetails[] | null) {
    this.landingFormService.store.updateBrands(value || []);
  }

  @Input() set appliances(value: ApplianceTypeDetails[] | null) {
    this.landingFormService.store.updateAppliances(value || []);
  }

  @Input() set brandsLoading(value: boolean | null) {
    this.landingFormService.store.updateBrandsLoading(!!value);
  }

  @Input() set appliancesLoading(value: boolean | null) {
    this.landingFormService.store.updateAppliancesLoading(!!value);
  }

  @Input() set pageAppliance(value: ApplianceTypeDetails | null | undefined) {
    this.landingFormService.store.updatePageAppliance(value || null);
  }

  @Input() set pageBrand(value: ApplianceBrandDetails | null | undefined) {
    this.landingFormService.store.updatePageBrand(value || null);
  }

  @Input() cmsData!: { subtitle?: string; applianceConditionLabelText: string };

  @Output() applianceValue$ = this.landingFormService.store.selectedAppliance$.pipe(pluck('code'));
  @Output() brandValue$ = this.landingFormService.store.selectedBrand$.pipe(pluck('code'));
  @Output() analytics: EventEmitter<GtmData> = new EventEmitter();
  @Output() formSubmitted = new EventEmitter<{
    appliance: string;
    brand: string;
    postcode: string;
  }>();

  triggerValidation = false;
  _separateApplianceConditionFields = false;
  fields = this.landingFormService.fieldDefs;
  formGroup = this.landingFormService.formGroup;
  showBrandLink$ = this.landingFormService.store.showBrandLink$;
  showApplianceLink$ = this.landingFormService.store.showApplianceLink$;
  showApplianceConditionStopMsg$ = this.landingFormService.store.showApplianceConditionStopMsg$;
  showAgeConditionStopMsg$ = this.landingFormService.store.showAgeConditionStopMsg$;
  showCostConditionStopMsg$ = this.landingFormService.store.showCostConditionStopMsg$;
  invalidApplianceCondition$ = this.landingFormService.store.invalidApplianceCondition$;
  maxPrice$ = this.landingFormService.store.maxPrice$;
  applianceConditionField$ = this.maxPrice$.pipe(
    map((price) => {
      const formatted = this.currencyPipe.transform(price || 1500, 'GBP', 'symbol', '1.0-0');
      return {
        ...this.fields.applianceCondition,
        label: {
          text: this.fields.applianceCondition.label?.text?.replace(
            '{maxPrice}',
            formatted || '£1,500'
          ),
        },
        tooltip: this.fields.applianceCondition.tooltip?.replace(
          '{maxPrice}',
          formatted || '£1,500'
        ),
      } as YesNoDef;
    })
  );

  onSubmit() {
    this.landingFormService.formatPostcode();
    this.triggerValidation = true;
    if (!this.landingFormService.validate()) {
      this.handleErrorAnalytics();
      return;
    }
    this.formSubmitted.emit({
      appliance: this.formGroup.value.appliance,
      brand: this.formGroup.value.brand,
      postcode: this.formGroup.value.postcode,
    });
  }

  private gaControlNames: Record<string, string> = {
    appliance: 'applianceType',
    brand: 'applianceBrand',
  };

  private handleErrorAnalytics() {
    const errors = this.landingFormService.getFormValidationErrorMessages();
    this.analytics.emit({
      event: GtmEvent.GENERIC,
      eventCategory: 'home',
      eventAction: 'error',
      eventLabel: errors.join(' | '),
    });
  }

  async handleFieldAnalytics(data: AnalyticsData) {
    if (data.error || !data.value) {
      return;
    }
    this.analytics.emit({
      event: GtmEvent.QUOTE,
      eventCategory: 'quote-search',
      eventAction: this.gaControlNames[data.controlName] || data.controlName,
      eventLabel: await this.calculateFieldValueForAnalytics(data),
    });
  }

  private async calculateFieldValueForAnalytics({ controlName, value }: AnalyticsData) {
    switch (controlName) {
      case 'postcode':
        return 'completed';
      case 'appliance': {
        const selectedAppliance = await this.landingFormService.store.selectedAppliance$
          .pipe(take(1))
          .toPromise();
        return selectedAppliance.name;
      }
      case 'brand': {
        const selectedBrand = await this.landingFormService.store.selectedBrand$
          .pipe(take(1))
          .toPromise();
        return selectedBrand.name;
      }
    }
    return value;
  }

  // FIX for release 4.8  When we refactor the codebase as part of the changes for the new catalogue API, we can implement a better approach.
  public updateApplianceConditionLabelText() {
    this.fields = {
      ...this.fields,
      applianceCondition: {
        ...this.fields.applianceCondition,
        label: {
          text: this.cmsData?.applianceConditionLabelText,
        },
      },
    };
  }

  // end of section
}
