<ui-accordion (toggle)="showAnswer(question, $event)" [title]="question">
  <div *ngIf="answer">
    <!-- for appliance page - FAQ to show the template ref (overlay link) -->
    <div *ngIf="useTemplateRef" [ngTemplateOutlet]="templateRef"></div>
    <!-- for others -->
    <div *ngIf="!useTemplateRef" [innerHtml]="answer | keepHtml"></div>
  </div>
  <!-- for Help - FAQ to show the links -->
  <div *ngIf="!answer && !useTemplateRef">
    <ng-content></ng-content>
  </div>
</ui-accordion>
