import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CmsTitleDescImageItem } from '@common/util-base';

@Component({
  selector: 'randc-appliance-protection-banner',
  templateUrl: './appliance-protection-banner.component.html',
  styleUrls: ['./appliance-protection-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplianceProtectionBannerComponent {
  @Input() title!: string;
  @Input() applianceProtectionItems!: CmsTitleDescImageItem[];
  @Input() imageUrl!: string;
}
