import { Component, Input } from '@angular/core';
import { CmsParagraphReferences, OneOffRepairsRow } from '@common/util-base';

@Component({
  selector: 'randc-one-off-repairs',
  templateUrl: './one-off-repairs.component.html',
  styleUrls: ['./one-off-repairs.component.scss'],
})
export class OneOffRepairsComponent {
  //This can be moved out to an input if needed.
  items: OneOffRepairsRow[] = [
    {
      heading: "'No fix, no fee' guarantee",
      subHeading: "If we can't fix it, you won't pay",
    },
    {
      heading: 'Monthly payments',
      subHeading: 'Simple monthly payments over 12 months',
    },
    {
      heading: 'Ongoing support & maintenance',
      subHeading: 'Ensure your appliance runs smoothly for longer',
    },
  ];

  @Input() brandParagraphContent: CmsParagraphReferences | null | undefined = null;
}
