<div
  class="sales brand-logo-banner"
  *ngIf="brandLogoBannerItems && brandLogoBannerItems.length >= 1"
>
  <a [href]="brandUrl">
    <div class="xs-stack md-row sm-row lg-row brand-logo-wrapper">
      <div class="md-8 xs-12 sm-8 lg-8">
        <h2 [innerHTML]="brandLogoBannerItems[0].title"></h2>
      </div>
      <div class="md-4 xs-12 sm-4 lg-4 brand-logo-image">
        <img [src]="imageUrl + brandLogoBannerItems[0].imageDesktop['@link']" />
      </div>
    </div>
  </a>
</div>
