import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaultArrayDetailsResolver } from '@common/data-access-quote';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@common/feature-dynamic-landing-page').then(
        (m) => m.RandcFeatureDynamicLandingPageModule
      ),
  },
  {
    path: 'appliance-details',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'book-appointment',
    loadChildren: () =>
      import('@common/feature-book-appointment').then((m) => m.RandcFeatureBookAppointmentModule),
  },
  {
    path: 'complete-order',
    loadChildren: () =>
      import('@common/feature-complete-order-page').then(
        (m) => m.RandcFeatureCompleteOrderPageModule
      ),
  },
  {
    path: 'order-confirmation',
    loadChildren: () =>
      import('@common/feature-order-confirmation-page').then(
        (m) => m.RandcFeatureOrderConfirmationPageModule
      ),
  },
  {
    path: 'call-centre',
    loadChildren: () =>
      import('@common/feature-call-centre-page').then((m) => m.RandcFeatureCallCentrePageModule),
  },
  {
    path: 'boilers',
    loadChildren: () =>
      import('@common/feature-boilers-page').then((m) => m.RandcFeatureBoilersPageModule),
  },
  {
    path: 'direct-debit-guarantee',
    loadChildren: () =>
      import('@common/feature-direct-debit-guarantee-page').then(
        (m) => m.RandcFeatureDirectDebitGuaranteePageModule
      ),
  },
  {
    path: 'get-quote',
    loadChildren: () =>
      import('@common/feature-quote-landing-page').then((m) => m.FeatureQuoteLandingPageModule),
  },
  {
    path: 'booking-details',
    loadChildren: () =>
      import('@common/feature-booking-details').then((m) => m.RandcFeatureBookingDetailsModule),
  },
  {
    path: 'engineer-not-found',
    loadChildren: () =>
      import('@common/feature-engineer-not-found-page').then(
        (m) => m.RandcFeatureEngineerNotFoundPageModule
      ),
  },
  {
    path: 'appliance-under-warranty',
    loadChildren: () =>
      import('@common/feature-appliance-under-warranty').then(
        (m) => m.RandcFeatureApplianceUnderWarrantyModule
      ),
  },
  {
    path: 'generic-error',
    loadChildren: () =>
      import('@common/feature-generic-error-page').then(
        (m) => m.RandcFeatureGenericErrorPageModule
      ),
  },
  {
    path: 'one-off-repair',
    loadChildren: () =>
      import('@common/feature-one-off-repair-page').then(
        (m) => m.RandcFeatureOneOffRepairPageModule
      ),
  },
  {
    path: 'error-404',
    loadChildren: () =>
      import('@common/feature-error-404-page').then((m) => m.RandcFeatureError404PageModule),
  },
  {
    path: '**',
    redirectTo: '/error-404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [FaultArrayDetailsResolver],
  exports: [RouterModule],
})
export class AOFeatureShellRoutingModule {}
