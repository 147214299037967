import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as QuoteSelectors from '../+state/quote/quote.selectors';
import { QuotePartialState } from '../+state/quote/quote.reducer';
import * as QuoteActions from '../+state/quote/quote.actions';
import { CalendarSlot, TimeSlotType } from '@common/util-base';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root',
})
export class CalendarFacade {
  constructor(private store: Store<QuotePartialState>) {}

  public calendarAvailability$ = this.store.select(QuoteSelectors.getCalendarAvailability);
  public scrollToken$ = this.store.select(QuoteSelectors.getScrollToken);
  public sessionId$ = this.store.select(QuoteSelectors.getSessionId);
  public repairDate$ = this.store
    .select(QuoteSelectors.getRepairDate)
    .pipe(map((d) => (d ? new Date(d) : null)));
  public repairTime$ = this.store.select(QuoteSelectors.getRepairTime);

  public getEngineerAvailabilityDates(applianceCode: string, brandCode: string, postcode: string) {
    return this.store.dispatch(
      QuoteActions.GetEngineerAvailabilityDates({
        payload: {
          applianceDetails: {
            applianceCode,
            brandCode,
            postcode,
          },
        },
      })
    );
  }

  ///todo: we have this information, can we remove arguments?
  public getEngineerMoreAvailabilityDates(sessionId: string, scrollToken: string) {
    return this.store.dispatch(
      QuoteActions.GetEngineerMoreAvailabilityDates({
        payload: {
          sessionId,
          scrollToken,
        },
      })
    );
  }

  public addAppointmentDate(date: string) {
    return this.store.dispatch(QuoteActions.AddAppointmentDate({ payload: date }));
  }

  public addAppointmentDateTime(selectedDate: string, selectedTimeSlot: CalendarSlot) {
    const selectedTime =
      selectedTimeSlot.slotType === TimeSlotType.ALLDAY
        ? '8am-6pm' // todo: is this value correct?
        : `${selectedTimeSlot.startTime}-${selectedTimeSlot.endTime}`;
    return this.store.dispatch(
      QuoteActions.AddAppointmentDateTime({
        payload: {
          selectedDate,
          selectedTime,
          selectedSlotId: selectedTimeSlot.slotId,
        },
      })
    );
  }
}
