import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { updateBookingForm, updateBookingFormSuccess } from './booking-details.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class BookingDetailsEffects {
  constructor(private actions$: Actions) {}
  saveDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBookingForm),
      map(() => updateBookingFormSuccess())
    )
  );
}
