import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CmsTitleDescItem, PopularItem } from '@common/util-base';

@Component({
  selector: 'randc-spa-our-partners',
  templateUrl: './our-partners.component.html',
  styleUrls: ['./our-partners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OurPartnersComponent {
  @Input() items: PopularItem[] = [];
  @Input() content: CmsTitleDescItem | null = null;
  @Input() advance = false;
  @Input() moreLink = '';
  @Input() title = 'We are the official partners of the brands you know and love';
}
