import { Injectable } from '@angular/core';
import {
  APIApplianceBrandDetails,
  APIApplianceTypeDetails,
  ApplianceBrandDetails,
  ApplianceTypeDetails,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
/**
 * Used to map API response results into common
 * types that we use throughout the application.
 */
export class ApplianceDetailsMapperService {
  /**
   * Map ApplianceBrandDetails
   * @param {object} item APIApplianceBrandDetails
   * @returns {object} ApplianceBrandDetails
   */
  public mapToApplianceBrandDetails(item: APIApplianceBrandDetails): ApplianceBrandDetails {
    return {
      code: item.brandCode,
      name: item.brandName,
      popular: item.popular,
    };
  }

  /**
   * Map ApplianceTypeDetails
   * @param {object} item APIApplianceTypeDetails
   * @returns {object} ApplianceTypeDetails
   */
  public mapToApplianceTypeDetails({
    applianceCode,
    applianceName,
    popular,
    priceBand,
  }: APIApplianceTypeDetails): ApplianceTypeDetails {
    return {
      code: applianceCode,
      name: applianceName,
      popular,
      priceBand,
      path: [{ code: '', name: '' }],
    };
  }
}
