import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AnalyticsService, GtmEvent } from '@common/util-base';
import { QuoteFacade } from '@common/data-access-quote';

declare global {
  interface Window {
    ScullyIO: any;
    rTapNotifyDOMChange?: (
      type: any
    ) => {
      type: any;
    };
    imichatwidget: any;
  }
}

@Component({
  selector: 'randc-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss'],
})
export class ErrorBannerComponent implements OnInit {
  @Input() classes: any;
  public errorMessage!: Observable<any>;

  constructor(private quoteFacade: QuoteFacade, private _analyticsService: AnalyticsService) {}

  ngOnInit() {
    if (window && window.rTapNotifyDOMChange && window?.ScullyIO !== 'running') {
      window.rTapNotifyDOMChange(document.documentElement);
    }
    this._triggerErrorGA();
  }

  private _triggerErrorGA(): void {
    this._analyticsService.push({
      event: GtmEvent.PAGEVIEW,
      pagename: 'page-not-available',
      genericpagename: 'error-page',
      category: 'error-page',
      error: 'Backend error',
    });
  }

  public triggerPhoneClickGA(phoneNumber: string) {
    this._analyticsService.trigger(GtmEvent.GENERIC, {
      eventCategory: 'phone-number-click',
      eventAction: 'error-banner',
      eventLabel: phoneNumber,
    });
  }

  ngOnDestroy() {
    // house keeping error object in store
    this.quoteFacade.clearErrorMessage();
  }
}
