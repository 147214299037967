import { Component } from '@angular/core';
import { fadeInOut } from '../animations/animations';

interface Links {
  home: string;
}

@Component({
  selector: 'randc-spa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInOut],
})
export class HeaderComponent {
  public links: Links = { home: 'https://www.domesticandgeneral.com/' };
}
