import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';

import {
  ReCaptchaV3Service,
  RECAPTCHA_BASE_URL,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_NONCE,
  RECAPTCHA_V3_SITE_KEY,
} from 'ng-recaptcha';
import { NgZone } from '@angular/core';
import { isScullyRunning } from '@scullyio/ng-lib';
import { WINDOW } from '../tokens';
import { DOCUMENT } from '@angular/common';

// taken from homepage repo - do not modify
// todo: we should move to shared repo

@Injectable({
  providedIn: 'root',
})
export class ReCaptchaWithCleanupService extends ReCaptchaV3Service {
  private doc: Document;
  constructor(
    zone: NgZone,
    @Inject(RECAPTCHA_V3_SITE_KEY) siteKey: string,
    @Inject(PLATFORM_ID) platformId: any,
    @Inject(WINDOW) win: Window & { grecaptcha?: unknown },
    @Inject(DOCUMENT) doc: Document,
    @Optional() @Inject(RECAPTCHA_BASE_URL) baseUrl?: string,
    @Optional() @Inject(RECAPTCHA_NONCE) nonce?: string,
    @Optional() @Inject(RECAPTCHA_LANGUAGE) language?: string
  ) {
    super(zone, siteKey, platformId, baseUrl, nonce, language);
    this.doc = doc;
    if (!isScullyRunning()) {
      return;
    }
    if (win.grecaptcha) {
      this.cleanup();
    } else {
      doc.querySelector('script[src*="recaptcha"]')?.addEventListener('load', () => this.cleanup());
    }
  }
  private cleanup() {
    Array.from(
      this.doc.querySelectorAll('script[src*="recaptcha"],.grecaptcha-badge')
    ).forEach((e) => e.remove());
  }
}
