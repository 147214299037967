import { Action, createReducer, on } from '@ngrx/store';

import * as ApplianceDetailsActions from './appliance-details.actions';
import { ApplianceDetailsState } from '../models';
import { RemoteDataState } from '@common/util-base';

export const APPLIANCE_FEATURE_KEY = 'appliance';

export interface ApplianceDetailsPartialState {
  readonly [APPLIANCE_FEATURE_KEY]: ApplianceDetailsState;
}

// Initial state
export const initialState: ApplianceDetailsState = {
  details: {
    allApplianceTypes: [],
    allApplianceBrands: [],
    selectedApplianceBrand: '',
    selectedApplianceType: '',
  },
  remoteDataState: {
    allApplianceTypes: RemoteDataState.NotFetched,
    allApplianceBrands: RemoteDataState.NotFetched,
    applianceBrands: RemoteDataState.NotFetched,
    applianceTypes: RemoteDataState.NotFetched,
  },
};

const resetRemoteStateIfNeeded = (state: RemoteDataState) => {
  if (state === RemoteDataState.OK) {
    return RemoteDataState.OK;
  }
  return RemoteDataState.NotFetched;
};

// Create Reducer
const applianceDetailsReducer = createReducer(
  initialState,
  on(ApplianceDetailsActions.Init, (state) => {
    return {
      ...state,
      remoteDataState: {
        allApplianceTypes: resetRemoteStateIfNeeded(state.remoteDataState.allApplianceTypes),
        allApplianceBrands: resetRemoteStateIfNeeded(state.remoteDataState.allApplianceBrands),
        applianceBrands: resetRemoteStateIfNeeded(state.remoteDataState.applianceBrands),
        applianceTypes: resetRemoteStateIfNeeded(state.remoteDataState.applianceTypes),
      },
    };
  }),
  on(ApplianceDetailsActions.GetAllApplianceTypesSuccess, (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      allApplianceTypes: payload,
    },
    remoteDataState: {
      ...state.remoteDataState,
      allApplianceTypes: RemoteDataState.OK,
    },
  })),
  on(ApplianceDetailsActions.GetAllApplianceBrandsSuccess, (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      allApplianceBrands: payload,
    },
    remoteDataState: {
      ...state.remoteDataState,
      allApplianceBrands: RemoteDataState.OK,
    },
  })),
  on(
    ApplianceDetailsActions.GetApplianceBrandsByTypeSuccess,
    (state, { payload, selectedApplianceType }) => ({
      ...state,
      details: {
        ...state.details,
        applianceBrands: payload,
        selectedApplianceType,
      },
      remoteDataState: {
        ...state.remoteDataState,
        applianceBrands: RemoteDataState.OK,
      },
    })
  ),
  on(
    ApplianceDetailsActions.GetApplianceTypesbyBrandSuccess,
    (state, { payload, selectedApplianceBrand }) => ({
      ...state,
      details: {
        ...state.details,
        applianceTypes: payload,
        selectedApplianceBrand,
      },
      remoteDataState: {
        ...state.remoteDataState,
        applianceTypes: RemoteDataState.OK,
      },
    })
  ),
  on(ApplianceDetailsActions.GetAllApplianceTypes, (state) => ({
    ...state,
    remoteDataState: {
      ...state.remoteDataState,
      allApplianceTypes: RemoteDataState.Loading,
    },
  })),
  on(ApplianceDetailsActions.GetAllApplianceBrands, (state) => ({
    ...state,
    remoteDataState: {
      ...state.remoteDataState,
      allApplianceBrands: RemoteDataState.Loading,
    },
  })),
  on(ApplianceDetailsActions.GetApplianceBrandsByType, (state) => ({
    ...state,
    details: {
      ...state.details,
      applianceBrands: [],
    },
    remoteDataState: {
      ...state.remoteDataState,
      applianceBrands: RemoteDataState.Loading,
    },
  })),
  on(ApplianceDetailsActions.GetApplianceTypesbyBrand, (state) => ({
    ...state,
    details: {
      ...state.details,
      applianceTypes: [],
    },
    remoteDataState: {
      ...state.remoteDataState,
      applianceTypes: RemoteDataState.Loading,
    },
  })),
  on(ApplianceDetailsActions.GetApplianceBrandsByTypeFail, (state) => ({
    ...state,
    remoteDataState: {
      ...state.remoteDataState,
      applianceBrands: RemoteDataState.Error,
    },
  })),
  on(ApplianceDetailsActions.GetApplianceTypesbyBrandFail, (state) => ({
    ...state,
    remoteDataState: {
      ...state.remoteDataState,
      applianceTypes: RemoteDataState.Error,
    },
  })),
  on(ApplianceDetailsActions.GetAllApplianceBrandsFail, (state, { payload }) => ({
    ...state,
    remoteDataState: {
      ...state.remoteDataState,
      allApplianceBrands: RemoteDataState.Error,
    },
  })),
  on(ApplianceDetailsActions.GetAllApplianceTypesFail, (state, { payload }) => ({
    ...state,
    remoteDataState: {
      ...state.remoteDataState,
      allApplianceTypes: RemoteDataState.Error,
    },
  }))
);

export function reducer(state: ApplianceDetailsState | undefined, action: Action) {
  return applianceDetailsReducer(state, action);
}
