import { Directive, HostListener, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { PcaService } from '../services';

// service

//todo: can de now delete this
@Directive({
  selector: '[randcAddressLookUp]',
})
export class LookupDirective implements OnInit {
  // declare reference to closeLookUp
  ref: any;

  @Output()
  addressList: EventEmitter<any> = new EventEmitter();

  constructor(private pcaService: PcaService, private el: ElementRef) {}

  ngOnInit(): void {
    // set and bind this to ref
    this.ref = this.closeLookUp.bind(this);
  }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    document.body.addEventListener('click', this.ref, false);

    if (input.value !== '') {
      this.pcaService.pcaFind(input.value).subscribe(
        (res) => {
          this.addressList.emit(res);
        },
        (error) => {
          // display error details and route to error page
          console.error('Error getting PCA service', error);
        }
      );
    } else {
      // clear out the addressList
      this.addressList.emit(null);
    }
  }

  /**
   * hide addressList ONLY when clicking outside of address-look-up
   */
  closeLookUp(event: any): any {
    const ignore = document.querySelector('.address-look-up');
    const target = event.target;
    if (ignore) {
      if (target === ignore || ignore.contains(target)) {
        return;
      }
    }
    document.body.removeEventListener('click', this.ref, false);
    this.addressList.emit(null);
  }
}
