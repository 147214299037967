import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'randc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() overlayOnTopOfExistingContent = false;
  @ViewChild('lottieElement') lottieElement: ElementRef | undefined;
}
