import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuoteDetails, QuoteState } from '../../model/quote-store.model';
import { QUOTE_FEATURE_KEY, QuotePartialState } from './quote.reducer';
import { rangeToAdd, TimeRange } from '@common/util-base';

// Lookup the 'Quote' feature state managed by NgRx
export const getQuoteState = createFeatureSelector<QuotePartialState, QuoteState>(
  QUOTE_FEATURE_KEY
);

export const getQuoteDetails = createSelector(getQuoteState, (state: QuoteState) => state.details);
export const getBasket = createSelector(getQuoteState, (state: QuoteState) => state.basket);
export const getSelectedQuote = createSelector(getBasket, (basket) => basket?.quotes[0] || null);

// get Quote Error
export const getQuoteError = createSelector(getQuoteState, (state: QuoteState) => state.error);

// get Error Status
export const getQuoteErrorStatus = createSelector(
  getQuoteState,
  (state: QuoteState) => state.error.errorStatus
);

//get siteVersion of dynamic landing page
export const getDynamicLandingPageDetails = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.dynamicLandingPageDetails
);

//get all Appliance Details
export const getApplianceDetails = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.applianceDetails
);

//get selected appliance brand name
export const getSelectedApplianceBrand = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details?.applianceDetails?.applianceBrandName
);

//get Postcode
export const getPostcode = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.postcode
);

// get outwardPostcode
export const getOutwardPostcode = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.outwardPostcode
);

//get Calendar Availability Details
export const getCalendarAvailability = createSelector(getQuoteDetails, (details: QuoteDetails) => {
  const calendarAvailability = details.calendarAvailability;

  const availabilityDates = calendarAvailability.availabilityDates.map((obj) => {
    const slots = obj.slots;

    const format: TimeRange[] = slots.map(
      (x) => ({ startTime: x.startTime, endTime: x.endTime } as TimeRange)
    );

    const validResults = rangeToAdd(format).map((slot) => slots[slot.i]);

    return {
      ...obj,
      slots: validResults,
    };
  });

  return { ...calendarAvailability, availabilityDates };
});

//get Scroll Token
export const getScrollToken = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.calendarAvailability.scrollToken
);

//get Session ID
export const getSessionId = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.sessionId
);

// get Booking Date
export const getRepairDate = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.repairDate
);

// get Booking Time
export const getRepairTime = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.repairTime
);

// get Slot ID
export const getSlotId = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.selectedSlotId
);

//get quote ID
export const getQuoteId = createSelector(getSelectedQuote, (quote) => quote?.quoteId);

// get quote totalCost
export const getQuotationTotalCost = createSelector(getSelectedQuote, (quote) => quote?.totalCost);

// get Fault Array details
export const getFaultArrayDetails = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.faultArrayDetails
);

//get Customer details
export const getCustomerDetails = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.customer
);

//get Contract Details
export const getContractDetails = createSelector(
  getQuoteDetails,
  (details: QuoteDetails) => details.contract
);

//get Payment Details
export const getPreferredPaymentDay = createSelector(
  getQuoteState,
  (quote) => quote?.details.preferredPaymentDay
);

export const getQuoteRemoteState = createSelector(
  getQuoteState,
  (state: QuoteState) => state.remoteState.quote
);

export const getFaultArrayRemoteState = createSelector(
  getQuoteState,
  (state: QuoteState) => state.remoteState.faultArray
);
export const getAvailabilityDatesRemoteState = createSelector(
  getQuoteState,
  (state: QuoteState) => state.remoteState.availabilityData
);

//TODO, implement following selector to identify whether covertype is 'One Off Repair' or 'Repair and Care'
export const isOneOffRepair = createSelector(getQuoteDetails, () => false);
