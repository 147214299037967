import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { GtmData, GtmEvent } from '@common/util-base';

@Component({
  selector: 'randc-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSupportComponent implements OnInit {
  @Input() title = 'Help is just a quick phone call away';
  @Input() text1 = 'Does your ';
  @Input() text2 =
    ' need fixing? Give us a call to choose from a one-off repair or an initial repair with a maintenance and support plan to protect it for longer.';
  @Input() brandOrApplianceName: string | null = null;
  @Input()
  text3 = `We’re available between 8am - 8pm from Monday to Friday and 9am - 6pm on Saturday and Sunday.
    Calls cost no more than 01 or 02 numbers from landlines or mobiles and will count as part of any
    inclusive minutes in your mobile package.`;
  @Output() handleAnalytics: EventEmitter<GtmData> = new EventEmitter();
  @Output() handleWindowTapNotify: EventEmitter<HTMLElement> = new EventEmitter();

  ngOnInit(): void {
    this.handleWindowTapNotify.emit(document.documentElement);
  }

  public triggerPhoneClickGA(phoneNumber: string) {
    this.handleAnalytics.emit({
      event: GtmEvent.GENERIC,
      eventCategory: 'phone-number-click',
      eventAction: 'contact-us',
      eventLabel: phoneNumber,
    });
  }
}
