<div class="sales form-group">
  <div class="accept">
    <div class="documents">
      For your own benefit and protection, please read the
      <a
        target="_blank"
        class="link"
        href="assets/files/DG_Maitenance_and_Support_TCs.pdf"
        download="DG_Maitenance_and_Support_TCs.pdf"
        >Repair & Care Plan Terms and Conditions</a
      >
      , the
      <a
        href="assets/files/DG_Accidental_Damage_TCs.pdf"
        class="link"
        target="_blank"
        download="DG_Accidental_Damage_TCs.pdf"
        >Repair & Care Accidental Damage Insurance Policy Terms and Conditions
      </a>
      and
      <a target="_blank" class="link" [href]="privacyPolicyLink">Privacy Policy</a>
      carefully before purchase. By switching this button you confirm that you have read the
      <a href="assets/files/DG_IPID.pdf" class="link" target="_blank" download="DG_IPID.pdf"
        >Insurance Product Information Document</a
      >
      and that the accidental damage insurance policy meets your demands and needs.
    </div>

    <div class="toggle">
      <label
        class="switch"
        [ngClass]="{
          'toggle--readonly': isReadonly,
          'toggle--checked': isChecked,
          'toggle--disabled': field?.disabled
        }"
      >
        <input
          type="checkbox"
          [checked]="isChecked"
          [disabled]="isReadonly || field?.disabled || false"
          (click)="onClick()"
        />
        <span class="slider round" [class.active]="isChecked"></span>
      </label>

      <div *ngIf="field?.label?.text" [class]="field?.label?.classes || 'toggle__text'">
        {{ field?.label?.text }}
      </div>
    </div>
  </div>

  <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
</div>
