<div class="container brands-container">
  <div class="lg-row md-stack">
    <div>
      <div class="title typog-body-large-bold" *ngIf="!advance">Our Partners</div>
      <h2 class="heading mb-2 px-2">
        {{ title }}
      </h2>
      <div class="mb-3 px-2" [innerHtml]="content?.description" *ngIf="content?.description"></div>
      <h3 class="mb-1" [innerHtml]="content?.title" *ngIf="content?.title"></h3>
      <div class="partners-wrapper">
        <a class="partner" *ngFor="let item of items" [routerLink]="item?.routerPath || '/'">
          <img alt="{{ item.name }}" src="{{ item.iconURL }}" />
        </a>
      </div>
      <a class="link moreLink" target="_blank" [href]="moreLink" *ngIf="moreLink"
        >See some of our other partners</a
      >
    </div>
  </div>
</div>
