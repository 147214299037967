import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfig, BuildConfigService, CmsDataResolverConfig, CmsPage } from '@common/util-base';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CMS_DATA } from '../mock-data';

type CmsDataWithResults = { results: Array<unknown> };

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CmsDataService {
  public cmsContentRoot!: string;

  constructor(private httpClient: HttpClient, private buildConfigService: BuildConfigService) {}

  public get config(): BuildConfig {
    return this.buildConfigService.config;
  }

  getCmsData({
    url: cmsDataUrl,
    localFile,
    id: cmsDataId,
    mapper: cmsDataMapper,
  }: CmsDataResolverConfig): Observable<unknown> {
    const absoluteCmsUrl = this.buildConfigService.config.localCms
      ? `local-data/cms/${localFile}.json`
      : `${this.buildConfigService.config.cmsContentRoot}/${cmsDataUrl}`;

    return this.httpClient.get(absoluteCmsUrl).pipe(
      catchError((error) => {
        console.warn(error.message);
        // Fall back to mock data if CMS API is not available
        return of(this.getCMSData(cmsDataId));
      }),
      map((cmsData: unknown | CmsDataWithResults) => {
        let data = cmsData;

        if (
          (cmsData as CmsDataWithResults).results &&
          (cmsData as CmsDataWithResults).results.length === 0
        ) {
          // Fall back to mock data if CMS API return is empty
          data = this.getCMSData(cmsDataId);
        }

        return cmsDataMapper ? cmsDataMapper(data as never) : data;
      })
    );
  }

  getCMSData(cmsDataId: string) {
    switch (cmsDataId) {
      case CmsPage.CheckoutPayment:
      case CmsPage.DirectDebitGuarantee:
      case CmsPage.Landing:
        return CMS_DATA[cmsDataId];
      default:
        return '';
    }
  }
}
