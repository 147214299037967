<div
  *ngIf="group"
  [formGroup]="group"
  class="form-input"
  [ngClass]="{
    'is-focused': isFocused,
    'is-invalid':
      group.controls[item.control] &&
      !group.controls[item.control].valid &&
      !isFocused &&
      group.controls[item.control]?.touched,
    'is-valid':
      group.controls[item.control] &&
      group.controls[item.control]?.valid &&
      group.controls[item.control]?.touched
  }"
>
  <randc-label *ngIf="label" [label]="label" [class]="label.classes" [forElement]="labelFor"
    >{{ label.text }}
  </randc-label>
  <div *ngIf="tooltip">
    <input type="checkbox" id="tooltipFakeCheckbox" class="tooltip" />
    <div class="tooltip tooltip_questionMark"></div>
    <p [class]="tooltip.classes">{{ tooltip.text }}</p>
  </div>
  <randc-hint *ngIf="hint" [hint]="hint"></randc-hint>
  <div class="form-control">
    <input
      id="randcInput"
      #randcInput
      [class]="item.classes"
      [formControlName]="item.control"
      [placeholder]="item.placeholder"
      [id]="item.control"
      [name]="item.control"
      [maxlength]="item.maxLength"
      type="text"
      (blur)="showValidation = true; isFocused = false; onBlur()"
      (focus)="onFocus()"
      [attr.disabled]="item.disabled ? '' : null"
      [mask]="mask"
      [(randcInputMask)]="randcInput.value"
    />
    <i
      class="icon icon-tick-circle"
      *ngIf="group && group.controls[item.control] && group.controls[item.control].value"
    ></i>
    <i class="icon icon-error-circle"></i>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <randc-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="item.control"
    >
    </randc-validation-error-formcontrol>
  </ng-container>
</div>
