import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { RemoteDataState } from '@common/util-base';
import { QuoteFacade } from './quote.facade';

@Injectable({ providedIn: 'root' })
export class FaultArrayDetailsResolver implements Resolve<any> {
  constructor(private quoteFacade: QuoteFacade, private router: Router) {}

  resolve() {
    return this.quoteFacade.faultArrayRemoteState$.pipe(
      filter((state) => this.filterSuccessOrError(state)),
      tap((itemsLoadedState) => this.handleError(itemsLoadedState)),
      take(1)
    );
  }

  private filterSuccessOrError(state: RemoteDataState) {
    if (state === RemoteDataState.OK || state === RemoteDataState.Error) {
      return true;
    }
    return false;
  }

  private handleError(itemsLoadedState: RemoteDataState) {
    if (itemsLoadedState === RemoteDataState.Error) {
      this.router.navigate(['/generic-error']);
    }
  }
}
