import { Component, HostListener, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import { UserIdleTimeoutComponent } from '@common/ui';
import { NavigationEnd, Router } from '@angular/router';
import { filter, pluck } from 'rxjs/operators';
import { BuildConfigService, CanonicalService, filterNullUndefined } from '@common/util-base';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';

declare global {
  interface Window {
    rTapNotifyDOMChange?: (
      type: any
    ) => {
      type: any;
    };
    imichatwidget: any;
  }
}

@Component({
  selector: 'common-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userActivity!: ReturnType<typeof setTimeout>;
  userInactive: Subject<string> = new Subject();
  userIdleTimeOut = 15 * 60 * 1000; //15 minutes
  private matDialogRef: MatDialogRef<any> | undefined;
  public progressSteps = [
    {
      label: 'Book',
      routes: ['/book-appointment', '/appliance-details', '/booking-details', '/product-selection'],
    },
    { label: 'Review', routes: ['/get-quote'] },
    { label: 'Checkout and pay', routes: ['/complete-order'] },
  ];
  public currentRoute$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    pluck('url'),
    filterNullUndefined()
  );

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _canonicalService: CanonicalService,
    private _buildConfigService: BuildConfigService
  ) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.openUserTimeoutDialog();
    });
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const { host } = this._buildConfigService.config;
      this._canonicalService.updateCanonicalUrl(host as string);
    });
  }

  onActivate(event: any) {
    window.scroll(0, 0); // scroll to top on every navigation.  RC-628 - Issue 10
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.userIdleTimeOut);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  openUserTimeoutDialog() {
    if (this.matDialogRef?.getState() !== MatDialogState.OPEN) {
      this.matDialogRef = this.dialog.open(UserIdleTimeoutComponent, {
        width: '500px',
        disableClose: true,
      });
    }
  }
}
