import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApplianceDetailsState, ApplianceDetails } from '../models';
import { APPLIANCE_FEATURE_KEY, ApplianceDetailsPartialState } from './appliance-details.reducer';

// Lookup the 'ApplianceDetails' feature state managed by NgRx
export const getApplianceDetailsState = createFeatureSelector<
  ApplianceDetailsPartialState,
  ApplianceDetailsState
>(APPLIANCE_FEATURE_KEY);

export const getApplianceBrandDetails = createSelector(
  getApplianceDetailsState,
  (state: ApplianceDetailsState) => state.details
);

//get All Appliance types (Washing Machine, Dishwasher, etc)
export const getAllApplianceTypes = createSelector(
  getApplianceBrandDetails,
  (details: ApplianceDetails) => details.allApplianceTypes
);

//get All Appliance brands (Hoover, Bosch, etc)
export const getAllApplianceBrands = createSelector(
  getApplianceBrandDetails,
  (details: ApplianceDetails) => details.allApplianceBrands
);

//get All Appliance brands for specific Appliance Types (Bosch, Miele, etc--> Dishwasher)
export const getApplianceBrandsByType = createSelector(
  getApplianceBrandDetails,
  (details: ApplianceDetails) => details.applianceBrands
);

//get All Appliance types for specific Appliance brand (Dishwasher, Washing Machine --> Bosch)
export const getApplianceTypesByBrand = createSelector(
  getApplianceBrandDetails,
  (details: ApplianceDetails) => details.applianceTypes
);

//get all popular brands
export const getPopularBrands = createSelector(
  getApplianceBrandDetails,
  (details: ApplianceDetails) => {
    const additionalPopularBrands = ['CAND', 'SMEG', 'ELUX'];
    return details.allApplianceBrands.filter(
      (res: any) => res.popular === true || additionalPopularBrands.includes(res.code)
    );
  }
);

//get all popular appliances
export const getPopularAppliances = createSelector(
  getApplianceBrandDetails,
  (details: ApplianceDetails) => {
    return details.allApplianceTypes.filter((res: any) => res.popular === true);
  }
);

//remoteData state

export const getAllApplianceTypesRemoteDataState = createSelector(
  getApplianceDetailsState,
  (state) => state.remoteDataState.allApplianceTypes
);
export const getAllApplianceBrandsRemoteDataState = createSelector(
  getApplianceDetailsState,
  (state) => state.remoteDataState.allApplianceBrands
);
export const getApplianceTypesRemoteDataState = createSelector(
  getApplianceDetailsState,
  (state) => state.remoteDataState.applianceTypes
);
export const getApplianceBrandsRemoteDataState = createSelector(
  getApplianceDetailsState,
  (state) => state.remoteDataState.applianceBrands
);

export const getSelectedApplianceBrand = createSelector(
  getApplianceDetailsState,
  (state) => state.details.selectedApplianceBrand
);

export const getSelectedApplianceType = createSelector(
  getApplianceDetailsState,
  (state) => state.details.selectedApplianceType
);
