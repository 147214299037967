<div class="time-picker" *ngIf="stateService.vm$ | async as vm">
  <div class="row">
    <div class="time-picker bg-white">
      <div
        *ngIf="vm.timeSlotType === enumTimeSlotType.ALLDAY; else selectATimeSlot"
        class="time-picker__all-day"
      >
        <div>Time slots are 8am to 8pm</div>
        <div>We’ll contact you the day before your visit to remind you</div>
      </div>
      <ng-template #selectATimeSlot>
        <div class="specific-time-picker">
          <dgx-dfb-form [fieldset]="vm.fieldDefs" [form]="vm.formGroup" [validate]="false">
          </dgx-dfb-form>
        </div>
      </ng-template>
    </div>
  </div>
</div>
