import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ApiHelper, APIModelSearchResponse, BuildConfigService } from '@common/util-base';
import { CalendarFacade } from './calendar.facade';

@Injectable({
  providedIn: 'root',
})
export class ModelSearchService {
  constructor(
    private _http: HttpClient,
    private buildConfigService: BuildConfigService,
    private apiHelper: ApiHelper,
    private calendarFacade: CalendarFacade
  ) {}

  private modelSearchRoot = this.buildConfigService.config.modelSearchRoot;

  private _handleError(err: HttpErrorResponse) {
    if (err.error.status && err.error.message) {
      return throwError(err.error);
    } else {
      return throwError({ status: err.status, message: err.message });
    }
  }

  /**
   * Get model numbers from Model Search API
   */
  public getModelNumbers(
    searchTerm: string
  ): Observable<{ modelId: string; modelLabel: string }[]> {
    return this.calendarFacade.sessionId$.pipe(
      switchMap((sessionId: string) => this.makeRequest(sessionId, searchTerm))
    );
  }

  private makeRequest(sessionId: string, searchTerm: string) {
    return this._http
      .post<APIModelSearchResponse>(
        `${this.modelSearchRoot}/v1/search-model`,
        {
          sessionId,
          searchTerm,
        },
        {
          headers: this.apiHelper.generateHeaders({
            requestSource: true,
            wlClient: true,
          }),
        }
      )
      .pipe(
        map((response: APIModelSearchResponse) => response.result.models),
        catchError(this._handleError)
      );
  }
}
