import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  HostListener,
  AfterViewChecked,
  Output,
  EventEmitter,
} from '@angular/core';
import { Carousel } from '@common/util-base';

@Component({
  selector: 'randc-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewChecked {
  @ViewChild('carousel') carousel!: ElementRef;
  @Input() public carouselHeader: string | null = null;
  @Input() public showName = false;
  @Input() public carouselLogoHeight = 46;
  @Input() public carouselLogoWidth = 175;
  @Input() public brandName: string | null = null;
  @Output() analytics: EventEmitter<Carousel> = new EventEmitter();

  _items: any;
  get items(): any {
    return this._items;
  }
  @Input() set items(value: any) {
    this._items = value;
  }

  public offset = 0;
  private _scrollStep = 250; // how many pixels to scroll the carousel by when an arrow is clicked
  private _maxOffset!: number; // to prevent from scrolling past last item
  private _carouselWidth!: number; // can change if screen is resized
  private _resizeTimeout: any | null | number;

  @HostListener('window:resize')
  onWindowResize() {
    // listen for resize and set new _scrollStep
    // debounce resize, wait for resize to finish before doing re-calculation, delay of 500
    if (this._resizeTimeout) {
      clearTimeout(this._resizeTimeout);
    }
    this._resizeTimeout = setTimeout(
      (() => {
        this._updateSizes();
      }).bind(this),
      500
    );
  }

  ngAfterViewChecked(): void {
    // let logos finish rendering to the DOM before accessing them
    setTimeout(() => {
      this._updateSizes();
    }, 0);
  }

  // get inital values of sizes and positions
  private _updateSizes() {
    this._updateCarouselWidth();
    this._updateScrollStep();
    this._updateMaxOffset();
  }

  private _updateCarouselWidth() {
    if (this.carousel && this.carousel.nativeElement) {
      this._carouselWidth = this.carousel.nativeElement.offsetWidth;
    }
  }

  private _updateScrollStep() {
    this._scrollStep = this._carouselWidth - (this._carouselWidth % this.carouselLogoWidth);
  }

  private _updateMaxOffset() {
    // maximum value of horizonal offset (translateX) after which scrolling stops working
    this._maxOffset = this.items.length * this.carouselLogoWidth - this._carouselWidth;
  }

  // left arrow click handler
  public prev() {
    if (this.offset < 0) {
      // allow scrolling right only if there are brands hidden on the left
      this.offset += this._scrollStep;
    }

    if (this.offset > 0) {
      // limit scrolling the first logo too far from the left age
      this.offset = 0;
    }
    this.analytics.emit({ side: 'left', carouselType: this.showName ? 'appliance' : 'brand' });
  }

  // right arrow click handler
  public next() {
    if (Math.abs(this.offset) < Math.abs(this._maxOffset))
      // when right arrow is clicked offset gets reduced
      this.offset -= this._scrollStep;

    if (this.offset > 0) {
      // limit scrolling the first logo too far from the left age
      this.offset = 0;
    }

    // maximum number of logos that are not visible
    const maxNumOfLogosOffScreen =
      this.items.length - Math.floor(this._carouselWidth / this.carouselLogoWidth);

    // make sure the right most logo doesn't stray too far from the right arrow
    if (Math.abs(this.offset) > maxNumOfLogosOffScreen * this.carouselLogoWidth) {
      this.offset = -1 * maxNumOfLogosOffScreen * this.carouselLogoWidth;
    }
    this.analytics.emit({ side: 'right', carouselType: this.brandName ? 'appliance' : 'brand' });
  }

  // initiate scrolling animation by re-calculating transform: translateX()
  public getTransform() {
    return `translateX(${this.offset}px)`;
  }

  public getRedirectPageURL(name: string) {
    name = name.replace(/\s/g, '-');
    if (this.showName) {
      if (this.brandName) {
        return `/brands/${this.brandName}/${encodeURIComponent(name).toLowerCase()}-repairs`;
      }
      return `/appliances/${encodeURIComponent(name).toLowerCase()}-repairs`;
    }
    return `/brands/${encodeURIComponent(name).toLowerCase()}`;
  }

  public getCarouselHeight() {
    return this.carouselLogoHeight + 'px';
  }
}
