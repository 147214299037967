import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ControlType, DynamicFormbuilderService } from '@domgen/dgx-fe-dynamic-form-builder';
import { ComponentStore } from '@ngrx/component-store';
import {
  CalendarSlot,
  CalendarSelectedTimeSlot,
  TimeSlotType,
  filterNullUndefined,
} from '@common/util-base';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface TimePickerComponentState {
  timeSlots: CalendarSlot[];
  timeSlotType: TimeSlotType | null;
  formGroup: FormGroup | null;
  fieldDefs: any[];
}

export const INITIAL_STATE: TimePickerComponentState = {
  timeSlots: [],
  timeSlotType: null,
  formGroup: null,
  fieldDefs: [
    {
      controlName: 'timeslot',
      controlType: ControlType.SELECT,
      validators: [Validators.required],
      label: { text: 'What time works for you?' },
      initialValue: '' as never,
      placeholder: 'Choose time',
      options: [],
    },
  ],
};

@Injectable()
export class TimePickerComponentStore extends ComponentStore<TimePickerComponentState> {
  constructor(private _dynamicFormbuilderService: DynamicFormbuilderService) {
    super(INITIAL_STATE);
  }

  private _resetFormGroup(formGroup: FormGroup | null): void {
    if (formGroup) {
      formGroup?.setValue({ timeslot: null });
      formGroup.markAsUntouched();
    }
  }

  // Updaters
  readonly createFormGroup = this.updater((state) => ({
    ...state,
    formGroup: this._dynamicFormbuilderService.generateFormGroup(state.fieldDefs),
  }));

  readonly updateState = this.updater((state, timeSlots: CalendarSlot[]) => {
    this._resetFormGroup(state.formGroup);

    return {
      ...state,
      timeSlots,
      timeSlotType: timeSlots?.every((slot) => slot.slotType === TimeSlotType.ALLDAY)
        ? TimeSlotType.ALLDAY
        : TimeSlotType.SPECIFIC,
      fieldDefs: [
        {
          ...state.fieldDefs[0],
          options: timeSlots?.map((slot) => {
            return {
              label: `${slot.startTime} - ${slot.endTime}`,
              value: JSON.stringify(slot),
            };
          }),
        },
      ],
    };
  });

  //  Selects
  readonly formGroup$ = this.select((state: TimePickerComponentState) => state.formGroup);
  readonly fieldDefs$ = this.select((state: TimePickerComponentState) => state.fieldDefs);
  readonly timeSlotType$ = this.select((state: TimePickerComponentState) => state.timeSlotType);
  readonly timeSlots$ = this.select((state: TimePickerComponentState) => state.timeSlots);

  // View Model
  readonly vm$ = this.select(
    this.formGroup$.pipe(filterNullUndefined()),
    this.fieldDefs$,
    this.timeSlotType$,
    this.timeSlots$,
    (formGroup, fieldDefs, timeSlotType, timeSlots) => ({
      formGroup,
      fieldDefs,
      timeSlotType,
      timeSlots,
    })
  );

  // Events
  timeSlotChanges$ = this.vm$.pipe(
    switchMap(({ timeSlots, timeSlotType, formGroup }) =>
      iif(
        () => timeSlotType === TimeSlotType.ALLDAY,
        of({
          timeSlot: timeSlots[0],
          isValid: true,
        }),
        formGroup?.valueChanges.pipe(
          map((controls) => ({
            timeSlot: JSON.parse(controls.timeslot),
            isValid: formGroup.valid,
          }))
        )
      )
    )
  ) as Observable<CalendarSelectedTimeSlot>;
}
