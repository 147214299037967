import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldDef } from '@domgen/dgx-fe-dynamic-form-builder';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'randc-customer-details-form',
  templateUrl: './customer-details-form.component.html',
  styleUrls: ['./customer-details-form.component.scss'],
})
export class CustomerDetailsFormComponent {
  @Input() formGroup!: FormGroup;
  @Input() fieldDef!: FieldDef[];
  @Output() continuePayment: EventEmitter<any> = new EventEmitter();
  @Output() analyticsEvent: EventEmitter<AnalyticsData> = new EventEmitter();

  handleAnalytics(value: AnalyticsData) {
    if (value && value.controlName !== 'mode') {
      this.analyticsEvent.emit(value);
    }
  }

  handleValidFormSubmission() {
    this.continuePayment.emit('details');
  }
}
