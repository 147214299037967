<div class="what-is spacing-min-lg">
  <div class="container">
    <div class="xs-row">
      <div class="what-is__col xs-12 lg-6 mb-3 lg-mb-0 order-2">
        <div class="what-is__image-container">
          <ui-picture
            [fill]="true"
            src="assets/images/what_is_repair_1.267dec23464a6081545fb4819f4a3d59.jpg"
            [lazy]="true"
          ></ui-picture>
        </div>
        <div class="what-is__image-container">
          <ui-picture
            [fill]="true"
            src="assets/images/what_is_repair_2.8b423d650d66086d73f72427e9268706.jpg"
            [lazy]="true"
          ></ui-picture>
        </div>
      </div>

      <div class="what-is__col xs-12 lg-6">
        <div class="what-is__col-item mb-3" *ngFor="let item of items">
          <div class="what-is__icon-wrap">
            <ui-graphic iconSet="rnc-icon" iconName="{{ item.icon }}"></ui-graphic>
          </div>
          <div class="mt-2 sm-mt-0 sm-ml-2">
            <h3 class="mb-1">{{ item.title }}</h3>
            <div [innerHtml]="item.description"></div>
          </div>
        </div>
        <ui-button variant="primary" (click)="bookARepairClick.emit()">{{ buttonText }}</ui-button>
      </div>
    </div>
  </div>
</div>
