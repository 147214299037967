import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BuildConfigService, CmsFaq, GtmData } from '@common/util-base';

@Component({
  selector: 'randc-landing-page-faqs',
  templateUrl: './landing-page-faqs.component.html',
  styleUrls: ['./landing-page-faqs.component.scss'],
})
export class LandingPageFaqsComponent {
  // buildConfig needed for faq link
  constructor(public buildConfig: BuildConfigService) {}
  @Input() faqDetails: CmsFaq[] = [];
  @Output() analytics = new EventEmitter<GtmData>();
  public handleAnalytics(data: GtmData): void {
    this.analytics.emit(data);
  }
}
