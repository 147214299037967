<ui-image-container
  src="./assets/images/stats-image.3e359947c19d30adf29e4287aed1bd8c.jpg"
  aspectRatio="4:3"
>
  <div class="container">
    <h2 class="pt-3 mb-2 text--center">{{ title }}</h2>
    <div class="stats-wrapper xs-12 mb-3">
      <div class="stats-row">
        <ng-container
          *ngTemplateOutlet="cardRow; context: { cardItems: firstRowItems }"
        ></ng-container>
      </div>
      <div class="row__separator"></div>
      <div class="stats-row">
        <ng-container
          *ngTemplateOutlet="cardRow; context: { cardItems: secondRowItems }"
        ></ng-container>
      </div>
    </div>
  </div>
</ui-image-container>

<ng-template #cardRow let-cardItems="cardItems">
  <ng-container *ngFor="let item of cardItems; let first = first">
    <div class="cell__separator" *ngIf="!first"></div>
    <div class="stats-row__card p-2">
      <div class="stats-row__circle">
        <h2 class="typog-d2">
          <span *ngIf="!item.unitValue"> {{ item.unitText }}</span>
          <span *ngIf="item.unitValue"> {{ item.unitValue | number: '1.0-2' }} </span>
        </h2>
        <p *ngIf="item.unitValue" class="typog-caption-regular">{{ item.unitText }}</p>
      </div>
      <p class="mt-1 text--center">
        {{ item.description }}
      </p>
    </div>
  </ng-container>
</ng-template>
