import { Component, Input } from '@angular/core';

@Component({
  selector: 'randc-fieldset',
  templateUrl: './fieldset.component.html',
})
export class FieldsetComponent {
  @Input() classes: any;
  @Input() validate: any;
  @Input() group: any;
  @Input() data?: Array<any>;
}
