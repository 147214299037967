<div class="sales">
  <!-- Heading -->
  <div class="xs-row">
    <div class="xs-6--none pb-2 pl-1 pt-2 pr-1">
      <h4 class="margin--none">Why Repair & Care?</h4>
      <p>Our plan offers you more than a one-off repair</p>
    </div>
    <div class="xs-3--none flex align--center justify--center p-1 blue-bg">
      <h5 class="margin--none text--center">Repair & Care plan</h5>
    </div>
    <div class="xs-3--none text--center grey-bg flex align--center justify--center p-1">
      <h5 class="margin--none p1">One-off repair</h5>
    </div>
  </div>

  <!-- Content Rows -->
  <div class="xs-row" *ngFor="let rowItem of items">
    <div class="xs-6--none pb-1 pt-1 pl-1 pr-1">
      <h5 class="margin--none pb-1">{{ rowItem.heading }}</h5>
      <p>{{ rowItem.subHeading }}</p>
    </div>
    <div class="xs-3--none text--center flex align--center justify--center">
      <div class="icon icon-tick primary--success"></div>
    </div>
    <div class="xs-3--none text--center flex align--center justify--center">
      <div class="icon icon-close"></div>
    </div>
  </div>
  <p class="pt-1 pb-1 side-padding-sm" *ngIf="brandParagraphContent">
    {{
      brandParagraphContent.paragraphContent
        | slice: 3:brandParagraphContent.paragraphContent.length - 5
    }}<span class="cta">
      <!-- Generated by Optimizely -->
    </span>
  </p>
</div>
