<div [attr.validate]="validate" [attr.data]="data" [attr.group]="group">
  <!--JUI FORM builder component -->
  <randc-hint *ngIf="data.hint" [hint]="data.hint"></randc-hint>
  <!--  Auto complete -->
  <randc-input-autocomplete
    *ngIf="data.autocomplete"
    [results]="results"
    [item]="data.autocomplete"
    [showValidation]="validate"
    [validators]="data.autocomplete.validators"
    [group]="group"
    [hint]="data.autocomplete.hint"
    [label]="data.autocomplete.label"
    [labelFor]="data.autocomplete.control"
  >
  </randc-input-autocomplete>

  <!-- Input -->
  <randc-inputs
    *ngIf="data.input && data.input.control !== 'landline'"
    [item]="data.input"
    [showValidation]="validate"
    [validators]="data.input.validators"
    [group]="group"
    [hint]="data.input.hint"
    [label]="data.input.label"
    [labelFor]="data.input.control"
    [tooltip]="data.input.tooltip"
  ></randc-inputs>

  <!-- Landline input -->
  <div
    *ngIf="data.input && data.input.control === 'landline'"
    class="mt-1 row--spacer-bottom-medium"
  >
    <div>
      <a (click)="showLandline = !showLandline" class="link link-underline">Enter landline</a>
    </div>

    <div class="mt-2">
      <randc-inputs
        *ngIf="showLandline"
        [item]="data.input"
        [showValidation]="validate"
        [validators]="data.input.validators"
        [group]="group"
        [hint]="data.input.hint"
        [label]="data.input.label"
        [labelFor]="data.input.control"
        [tooltip]="data.input.tooltip"
      >
      </randc-inputs>
    </div>
  </div>

  <!-- Select -->
  <randc-select
    *ngIf="data.select"
    [item]="data.select"
    [showValidation]="validate"
    [validators]="data.select.validators"
    [group]="group"
    [hint]="data.select.hint"
    [label]="data.select.label"
    [labelFor]="data.select.control"
  ></randc-select>

  <!-- Text area -->
  <randc-textarea
    *ngIf="data.textarea"
    [classes]="'dg-textarea'"
    [showValidation]="validate"
    [validationError]="data.textarea.validationError"
    [validators]="data.textarea.validators"
    [group]="group"
    [control]="data.textarea.control"
    [placeholder]="data.textarea.placeholder"
    [label]="data.textarea.label"
    [labelFor]="data.textarea.control"
    [chrLimit]="data.textarea.chrLimit"
    [rows]="5"
  >
  </randc-textarea>

  <!-- Button -->
  <ng-container *ngIf="data.button">
    <randc-button
      *ngFor="let btn of data.button"
      class="btn.classes"
      [classes]="btn.classes"
      [type]="btn.type"
      [disabled]="btn.disabled"
      [stretch]="btn.stretch"
      [submitted]="btn.submitted"
      [control]="btn.control"
      [text]="btn.text"
    >
    </randc-button>
  </ng-container>
  <!--JUI FORM builder component -->
</div>
