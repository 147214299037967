import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import * as quoteActions from './quote.actions';
import * as quoteSelectors from './quote.selectors';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  APICheckOutResponse,
  Basket,
  CalendarAvailabilityData,
  CalendarAvailabilityResponse,
  CalendarAvailabilityService,
  CreateContractService,
  FaultArrayResponse,
  FaultService,
  FeatureFlagsService,
  IndicativeQuoteRequest,
  SessionService,
} from '@common/util-base';
import { Router } from '@angular/router';
import { QuotePartialState } from './quote.reducer';
import { Init } from './quote.actions';

@Injectable({ providedIn: 'root' })
export class QuoteEffects implements OnInitEffects {
  constructor(
    private _action$: Actions,
    private _calendarService: CalendarAvailabilityService,
    private _faultService: FaultService,
    private _createContractService: CreateContractService,
    private _router: Router,
    private _sessionService: SessionService,
    private featureFlagsService: FeatureFlagsService,
    private _store: Store<QuotePartialState>
  ) {}
  ngrxOnInitEffects(): Action {
    return Init();
  }

  triggerEngineerAvailabilityDates$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.SAVE_LANDING_FORM_VALUES),
      map(({ payload }) => {
        const { applianceTypeCode, applianceBrandCode, postcode } = payload;

        return quoteActions.GetEngineerAvailabilityDates({
          payload: {
            applianceDetails: {
              applianceCode: applianceTypeCode,
              brandCode: applianceBrandCode,
              postcode,
            },
          },
        });
      })
    )
  );

  getEngineerAvailability$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.GET_ENGINEER_AVAILABILITY_DATES),
      switchMap((action: { payload: { applianceDetails: any } }) => {
        return this._calendarService.getEngineerAvailability(action.payload.applianceDetails).pipe(
          map((response: CalendarAvailabilityResponse) =>
            quoteActions.GetEngineerAvailabilityDatesSuccess({ payload: response })
          ),
          catchError((err: any) => {
            this._router.navigate(['/generic-error']);
            return of(quoteActions.GetEngineerAvailabilityDatesFail());
          })
        );
      })
    )
  );

  getEngineerMoreAvailability$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.GET_ENGINEER_MORE_AVAILABILITY_DATES),
      switchMap((action: any) => {
        return this._calendarService
          .getEngineerMoreAvailability(action.payload.sessionId, action.payload.scrollToken)
          .pipe(
            map((response: CalendarAvailabilityResponse) =>
              quoteActions.GetEngineerMoreAvailabilityDatesSuccess({
                payload: {
                  sessionId: action.payload.sessionId,
                  ...response,
                },
              })
            ),
            catchError((err: any) => {
              this._router.navigate(['/generic-error']);
              return of(quoteActions.GetEngineerMoreAvailabilityDatesFail());
            })
          );
      })
    )
  );

  triggerGetFault$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.GET_ENGINEER_AVAILABILITY_DATES_SUCCESS),
      map(({ payload }: { payload: CalendarAvailabilityResponse }) => {
        const { sessionId } = payload;

        return quoteActions.GetFault({ payload: { sessionId: sessionId as string } });
      })
    )
  );

  autoScroll$ = createEffect(() =>
    this._action$.pipe(
      ofType(
        quoteActions.GET_ENGINEER_AVAILABILITY_DATES_SUCCESS,
        quoteActions.GET_ENGINEER_MORE_AVAILABILITY_DATES_SUCCESS
      ),
      switchMap(({ payload }: { payload: CalendarAvailabilityResponse }) => {
        const { sessionId, scrollToken, availabilityDates } = payload;
        if (availabilityDates.length) {
          return EMPTY;
        }
        if (scrollToken) {
          return of(
            quoteActions.GetEngineerMoreAvailabilityDates({
              payload: {
                sessionId: sessionId as string,
                scrollToken: scrollToken as string,
              },
            })
          );
        }
        this._router.navigate(['engineer-not-found']);
        return EMPTY;
      })
    )
  );

  getQuote$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.GET_QUOTE),
      switchMap((action: any) => {
        return this._createContractService.getQuote(action.payload.quotePayload).pipe(
          map((response: Basket) => quoteActions.GetQuoteSuccess({ payload: response })),
          catchError((err: any) => of(quoteActions.GetQuoteFail(err)))
        );
      })
    )
  );

  getQuoteSuccess$: Observable<Action> = createEffect(
    () =>
      this._action$.pipe(
        ofType(quoteActions.GET_QUOTE_SUCCESS),
        tap(({ payload: quote }: { payload: Basket; type: string }) => {
          this._sessionService.saveSessionExpiryTime(quote);
        })
      ),
    { dispatch: false }
  );

  private triggerIndicativeQuote$: Observable<unknown> = createEffect(
    () =>
      this._action$.pipe(
        ofType(quoteActions.GET_ENGINEER_AVAILABILITY_DATES_SUCCESS),
        map(
          ({
            payload: { sessionId, availabilityDates },
          }: {
            payload: CalendarAvailabilityResponse;
          }) => {
            const firstAvailableDay = availabilityDates.find((day) => day.slots.length > 0);
            return {
              sessionId,
              firstAvailableDay,
            };
          }
        ),
        filter(({ sessionId, firstAvailableDay }) => !!sessionId && !!firstAvailableDay),
        map((v) => v as { sessionId: string; firstAvailableDay: CalendarAvailabilityData }),
        withLatestFrom(this._store.pipe(select(quoteSelectors.getPostcode))),
        tap(([action, postcode]) => {
          const { sessionId, firstAvailableDay } = action;
          const { slotId } = firstAvailableDay.slots[0];
          this._store.dispatch(
            quoteActions.GetIndicativeQuote({
              payload: {
                currency: 'GBP',
                postcode,
                sessionId,
                slotId,
              },
            })
          );
        })
      ),
    { dispatch: false }
  );

  getIndicativeQuote$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.GET_INDICATIVE_QUOTE),
      switchMap((action: { payload: IndicativeQuoteRequest }) => {
        const now = new Date();
        const bookingDetailsBeforeCalendar = this.featureFlagsService.isFlagEnabled(
          'bookingDetailsBeforeCalendar'
        );
        if (!bookingDetailsBeforeCalendar) {
          return this._createContractService
            .getQuote({
              itemType: 'QUOTERC',
              ...action.payload,
              manufacturerGuaranteeMonths: 12,
              purchaseMonth: 12,
              purchasePrice: 100,
              purchaseYear: now.getFullYear() - 2,
              warranty: 12,
            })
            .pipe(
              map((response: Basket) =>
                quoteActions.GetIndicativeQuoteSuccess({ payload: response })
              ),
              catchError((err: any) => of(quoteActions.GetIndicativeQuoteFail(err)))
            );
        } else {
          return EMPTY;
        }
      })
    )
  );

  getFault$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.GET_FAULT),
      switchMap((action: any) => {
        return this._faultService.getFault(action.payload.sessionId).pipe(
          map((response: FaultArrayResponse) =>
            quoteActions.GetFaultSuccess({ payload: response })
          ),
          catchError((err: any) => {
            this._router.navigate(['/generic-error']);
            return of(quoteActions.GetFaultFail(err));
          })
        );
      })
    )
  );

  createContract$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.CREATE_CONTRACT),
      switchMap((action: any) => {
        return this._createContractService
          .createContract(action.payload.createContractPayload)
          .pipe(
            map((response: APICheckOutResponse) =>
              quoteActions.CreateContractSuccess({ payload: response })
            ),
            catchError((err: any) => of(quoteActions.CreateContractFail(err)))
          );
      })
    )
  );

  clearErrorMessage$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.CLEAR_ERROR_MESSAGE),
      map(() => quoteActions.ClearErrorMessageSuccess())
    )
  );

  clearQuoteState$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.CLEAR_QUOTE_STATE),
      map(() => quoteActions.ClearQuoteStateSuccess())
    )
  );
  invalidateQuote$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(
        quoteActions.ADD_APPOINTMENT_DATE,
        quoteActions.ADD_APPOINTMENT_DATE_TIME,
        quoteActions.SAVE_LANDING_FORM_VALUES
      ),
      map(() => quoteActions.InvalidateQuote())
    )
  );

  invalidateFaultArray$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(
        quoteActions.SAVE_LANDING_FORM_VALUES,
        quoteActions.INVALIDATE_AVAILABILITY_DATES,
        quoteActions.GET_ENGINEER_AVAILABILITY_DATES
      ),
      map(() => quoteActions.InvalidateFaultArray())
    )
  );

  invalidateAvailability$: Observable<Action> = createEffect(() =>
    this._action$.pipe(
      ofType(quoteActions.SAVE_LANDING_FORM_VALUES),
      map(() => quoteActions.InvalidateAvailabilityDates())
    )
  );
}
