import {
  AccessDetails,
  CalendarAvailabilityResponse,
  CustomerDetails,
  FaultArrayCategory,
  FaultDetails,
  ModelDetails,
  ModelSearchResponse,
  PaymentByCreditCard,
  PaymentByDirectDebit,
  PaymentByDirectDebitToken,
} from './common.types';

export interface APIApplianceTypeResponse {
  status?: string;
  result: APIApplianceTypeDetails[];
}

export interface APIApplianceTypeDetails {
  applianceCode: string;
  applianceName: string;
}

export interface APIApplianceBrandResponse {
  status?: string;
  result: APIApplianceBrandDetails[];
}

export interface APIApplianceBrandDetails {
  brandCode: string;
  brandName: string;
  popular: boolean;
}

export interface APIQuoteRequest {
  itemType: string;
  currency: string;
  sessionId: string;
  postcode: string;
  slotId: string;
  purchaseMonth: number;
  purchaseYear: number;
  purchasePrice: number;
  manufacturerGuaranteeMonths: number;
  warranty: number;
}

export interface APIQuoteResponse {
  status: string;
  result: {
    basket: {
      basketId: string;
      items: [
        {
          data: {
            quotes: APIQuotation[];
            created: string;
            expiry: string;
          };
          itemId: string;
          itemType: string;
        }
      ];
    };
  };
}

export interface APIQuotation {
  quoteId: string;
  isBundle: boolean;
  companyCode?: string;
  schemeCode?: string;
  excessAmount: number;
  contractTypeCode: string;
  productType: string;
  breakdownStartDate: string;
  periodOfCover: number;
  waitDays: number;
  paymentTerm: string;
  coverType: string;
  totalCost: number;
  paymentOptions: APIPaymentOptions[];
}

export interface APIPaymentOptions {
  paymentType: string;
  preferredPayment: boolean;
  paymentProfile: string;
  numPayments: number;
  paymentFrequency: number;
  firstPayment: number;
  subsequentPayment: number;
  fee: number;
}

export interface APICheckOutResponse {
  status: string;
  result: {
    completedItems: [
      {
        confirmation: {
          planNumber: string;
          renewalDate: string;
          breakdownStartDate: string;
        };
      }
    ];
    basket: {
      basketId: string;
      created: string;
      expiry: string;
      items: [];
      requestAction: string;
      requestSource: string;
      status: string;
      updated: string;
    };
  };
}

export interface APICreateContractRequest {
  basketId: string;
  items: APICheckoutItems[];
  repair: APICheckoutRepair;
  customer: CustomerDetails;
  payment: PaymentByDirectDebit | PaymentByDirectDebitToken | PaymentByCreditCard;
}

export interface APICheckoutItems {
  itemType: string;
  itemId: string;
  quoteId: string;
  coverType: string;
}

export interface APICheckoutRepair {
  model: ModelDetails;
  fault: FaultDetails;
  access: AccessDetails;
}

export interface APIFaultArrayResponse {
  status: string;
  result: {
    faultCategories: FaultArrayCategory[];
    modelNumber: string;
    modelNumberRequired: boolean;
  };
}

export enum HttpContentType {
  JSON = 'application/json',
}

export interface HttpHeaderOptions {
  'Content-Type': HttpContentType;
  'request-source': string;
  'request-action': string;
  'x-recaptcha-token': string;
}

export interface APICalendarAvailabilityResponse {
  status?: string;
  result: CalendarAvailabilityResponse;
}

export interface APIModelSearchResponse {
  status: string;
  result: ModelSearchResponse;
}
