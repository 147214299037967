export const DIRECT_DEBIT_GUARANTEE = {
  results: [
    {
      '@name': 'direct-debit-guarantee-page',
      '@path': '/direct-debit-guarantee-page',
      '@id': '713c97af-0e26-4b7c-aecc-7d68295137e1',
      '@nodeType': 'randc-payment-page',
      paragraphReferences: [
        {
          '@name': 'boiler-maintenance',
          '@path': '/repair-and-care/direct-debit-guarantee-page/boiler-maintenance',
          '@id': '84398971-62c0-45c3-8a8f-2ed70ee50524',
          '@nodeType': 'dgx-paragraph',
          title: 'Boiler Maintenance and support plans:',
          paragraphContent:
            '<ul>\n\t<li>This Guarantee is offered by all banks and buildingsocieties that accept instructions to pay Direct Debits.</li>\n\t<li>If there are any changes to the amount, date or frequency of your Direct Debit, Domestic &amp; General Services limited will notify you 10 working days in advance of your accountbeing debited or as otherwise agreed.If you request Domestic &amp; General Services limited to collect a payment, confirmation of the amount and date will be given to you at thetime of the request.</li>\n\t<li>If an error is made in the payment of your Direct Debit, by Domestic &amp; General Services Limited or your bank or building society you are entitled to a full and immediate refund ofthe amount paid from your bank or building society.</li>\n\t<li>if you receive a refund you are not entitled to, you must pay it back when Domestic &amp; General Services Limited asks you to.</li>\n\t<li>You can cancel a Direct Debit at any time by simply contactingyour bank or building society. Written confirmation may be required. Please also notify us.</li>\n</ul>\n',
          jcrName: 'boiler-maintenance',
          '@nodes': [],
        },
        {
          '@name': 'protection-policies',
          '@path': '/repair-and-care/direct-debit-guarantee-page/protection-policies',
          '@id': '3395f94f-eaf8-4c00-96af-ed254564fcdb',
          '@nodeType': 'dgx-paragraph',
          title: 'Protection policies:',
          paragraphContent:
            '<ul>\n\t<li>This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.</li>\n\t<li>If there are any changes to the amount, date or frequency of your Direct Debit, Domestic &amp; General Insurance PLC will notify you 14 working days in advance of your account beingdebited or as otherwise agreed. If you request Domestic &amp; General Insurance PLC to collect a payment, confirmation of the amount and date will be given to you at the time of therequest.</li>\n\t<li>If an error is made in the payment of your Direct Debit, by Domestic &amp; General Insurance PLC or your bank or building society you are entitled to a full and immediaterefund of the amount paid from your bank or building society.</li>\n\t<li>If you receive a refund you are not entitled to, you must pay itback when Domestic &amp; General Insurance PLC asks you to.</li>\n\t<li>You can cancel a Direct Debit at any time by simply contactingyour bank or building society. Written confirmation may be required. Please also notify us.</li>\n</ul>\n',
          jcrName: 'protection-policies',
          '@nodes': [],
        },
      ],
      jcrName: 'direct-debit-guarantee-page',
      formReferences: [],
      '@nodes': [],
    },
  ],
};
