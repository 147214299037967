import { createAction, props } from '@ngrx/store';
import { ApplianceDetails, BookRepairForm } from '@common/util-base';

export const bookingDetailsKey = '[BookingDetails]';
export const updateBookingForm = createAction(
  `${bookingDetailsKey} Update Booking Form`,
  props<{ form: BookRepairForm }>()
);

export const updateBookingFormSuccess = createAction(
  `${bookingDetailsKey} Update Booking Form Success`
);

export const updateBookingFormFailed = createAction(
  `${bookingDetailsKey} Update Booking Form Failed`
);

export const updateApplianceDetails = createAction(
  `${bookingDetailsKey} Update Appliance Details`,
  props<{ applianceDetails: ApplianceDetails }>()
);
