import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { filterNullUndefined } from '@common/util-base';
import { ApplianceBrandDetails, ApplianceTypeDetails } from '@common/data-access-appliance-details';
import { OptionItem } from '@domgen/dgx-fe-dynamic-form-builder';
import { map } from 'rxjs/internal/operators/map';
import { distinctUntilChanged, pluck } from 'rxjs/operators';

export interface ApplianceDetailsLandingFormValues {
  appliance?: string;
  brand?: string;
  postcode?: string;
  applianceCondition?: boolean;
}

export interface ApplianceDetailsLandingFormState {
  pageAppliance: ApplianceTypeDetails | null;
  pageBrand: ApplianceBrandDetails | null;
  appliances: ApplianceTypeDetails[] | null;
  brands: ApplianceBrandDetails[] | null;
  appliancesLoading: boolean;
  brandsLoading: boolean;
  formValues: ApplianceDetailsLandingFormValues;
  disablePrefilledFields: boolean;
}

const initialState: ApplianceDetailsLandingFormState = {
  pageAppliance: null,
  pageBrand: null,
  appliances: null,
  brands: null,
  appliancesLoading: false,
  brandsLoading: false,
  formValues: {},
  disablePrefilledFields: false,
};

@Injectable({
  providedIn: 'root',
})
export class ApplianceDetailsLandingFormStore extends ComponentStore<ApplianceDetailsLandingFormState> {
  constructor() {
    super(initialState);
  }

  public readonly pageAppliance$: Observable<ApplianceTypeDetails | null> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.pageAppliance
  );
  public readonly updatePageAppliance = this.updater(
    (state: ApplianceDetailsLandingFormState, pageAppliance: ApplianceTypeDetails | null) => ({
      ...state,
      pageAppliance,
    })
  );

  public readonly pageBrand$: Observable<ApplianceBrandDetails | null> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.pageBrand
  );
  public readonly updatePageBrand = this.updater(
    (state: ApplianceDetailsLandingFormState, pageBrand: ApplianceBrandDetails | null) => ({
      ...state,
      pageBrand,
    })
  );

  public readonly appliances$: Observable<ApplianceTypeDetails[]> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.appliances
  ).pipe(filterNullUndefined<ApplianceTypeDetails[]>());
  public readonly updateAppliances = this.updater(
    (state: ApplianceDetailsLandingFormState, appliances: ApplianceTypeDetails[]) => ({
      ...state,
      appliances,
    })
  );

  public readonly brands$: Observable<ApplianceBrandDetails[]> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.brands
  ).pipe(filterNullUndefined<ApplianceBrandDetails[]>());
  public readonly updateBrands = this.updater(
    (state: ApplianceDetailsLandingFormState, brands: ApplianceBrandDetails[]) => ({
      ...state,
      brands,
    })
  );

  public readonly updateDisablePrefilledFields = this.updater(
    (state: ApplianceDetailsLandingFormState, disablePrefilledFields: boolean) => ({
      ...state,
      disablePrefilledFields,
    })
  );

  public readonly appliancesLoading$: Observable<boolean> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.appliancesLoading
  );
  public readonly updateAppliancesLoading = this.updater(
    (state: ApplianceDetailsLandingFormState, appliancesLoading: boolean) => ({
      ...state,
      appliancesLoading,
    })
  );

  public readonly brandsLoading$: Observable<boolean> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.brandsLoading
  );
  public readonly updateBrandsLoading = this.updater(
    (state: ApplianceDetailsLandingFormState, brandsLoading: boolean) => ({
      ...state,
      brandsLoading,
    })
  );

  public readonly disableBrand$: Observable<boolean> = this.select(
    (state: ApplianceDetailsLandingFormState) =>
      state.brandsLoading || (state.disablePrefilledFields && !!state.pageBrand)
  );

  public readonly showBrandLink$: Observable<boolean> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.disablePrefilledFields && !!state.pageBrand
  );

  public readonly disableAppliance$: Observable<boolean> = this.select(
    (state: ApplianceDetailsLandingFormState) =>
      state.appliancesLoading || (state.disablePrefilledFields && !!state.pageAppliance)
  );

  public readonly showApplianceLink$: Observable<boolean> = this.select(
    (state: ApplianceDetailsLandingFormState) =>
      state.disablePrefilledFields && !!state.pageAppliance
  );

  public readonly formValues$: Observable<ApplianceDetailsLandingFormValues> = this.select(
    (state: ApplianceDetailsLandingFormState) => state.formValues
  );
  public readonly updateFormValues = this.updater(
    (state: ApplianceDetailsLandingFormState, formValues: ApplianceDetailsLandingFormValues) => ({
      ...state,
      formValues,
    })
  );

  public applianceSelectOptions$ = this.appliances$.pipe(map(this.mapToSelectOption));
  public brandSelectOptions$ = this.brands$.pipe(map(this.mapToSelectOption));

  public selectedBrand$: Observable<ApplianceBrandDetails> = combineLatest([
    this.formValues$.pipe(pluck('brand'), distinctUntilChanged()),
    this.brands$,
  ]).pipe(
    map(([selectedBrand, brands]) => brands.find((brand) => brand.code === selectedBrand)),
    filterNullUndefined<ApplianceBrandDetails>()
  );
  private selectedApplianceOrNull$: Observable<ApplianceTypeDetails | null> = combineLatest([
    this.formValues$.pipe(pluck('appliance'), distinctUntilChanged()),
    this.appliances$,
  ]).pipe(
    map(
      ([selectedAppliance, appliances]) =>
        appliances.find((appliance) => appliance.code === selectedAppliance) || null
    )
  );

  public selectedAppliance$ = this.selectedApplianceOrNull$.pipe(
    filterNullUndefined<ApplianceTypeDetails>()
  );

  public maxPrice$ = this.selectedApplianceOrNull$.pipe(
    map((appliance) => {
      if (!appliance) {
        return 1500;
      }
      return appliance.priceBand.map(({ value }) => value).reverse()[0];
    })
  );

  private applianceConditionValue$ = this.formValues$.pipe(
    pluck('applianceCondition'),
    distinctUntilChanged()
  );

  showApplianceConditionStopMsg$ = this.applianceConditionValue$.pipe(
    map((v) => v === false),
    distinctUntilChanged()
  );
  private ageConditionValue$ = this.formValues$.pipe(pluck('ageCondition'), distinctUntilChanged());

  showAgeConditionStopMsg$ = this.ageConditionValue$.pipe(
    map((v) => v === false),
    distinctUntilChanged()
  );
  private costConditionValue$ = this.formValues$.pipe(
    pluck('costCondition'),
    distinctUntilChanged()
  );

  showCostConditionStopMsg$ = this.costConditionValue$.pipe(
    map((v) => v === false),
    distinctUntilChanged()
  );

  public invalidApplianceCondition$ = combineLatest([
    this.applianceConditionValue$,
    this.ageConditionValue$,
    this.costConditionValue$,
  ]).pipe(
    map(([applianceConditionValue, ageConditionValue, costConditionValue]) => {
      if (
        applianceConditionValue === false ||
        ageConditionValue === false ||
        costConditionValue === false
      ) {
        return true;
      } else {
        return false;
      }
    })
  );

  // readonly getBrandsByApplianceCode = this.effect((applianceCode$: Observable<string>) =>
  //   applianceCode$.pipe(
  //     switchMap(
  //       (applianceCode) => {
  //         this.someService.someMethod(applianceCode).pipe(
  //           tapResponse(
  //             (brands) => this.updateBrands(brands as any),
  //             (error) => error
  //           )
  //         )
  //       }
  //     )
  //   )
  // );

  private mapToSelectOption(
    appliances: (ApplianceTypeDetails | ApplianceBrandDetails)[]
  ): OptionItem[] {
    return appliances.map(({ code, name }) => {
      return { value: code, label: name };
    });
  }
}
